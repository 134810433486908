import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';

export default function useUserGroups() {
  const userGroups = useAppSelector(selectUserGroups);

  const hasUserGroups = (userGroups: string[], strict = true) => {
    if (strict) {
      return userGroups.every((group) => userGroups.includes(group));
    }
    return userGroups.some((group) => userGroups.includes(group));
  };

  const hasUserGroup = (group: string) => userGroups.includes(group);

  return { hasUserGroup, hasUserGroups };
}
