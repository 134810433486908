import React from 'react';
import WarnerBrosLogo from '../../assets/warner-bros-logo.svg?react';
import HboLogo from '../../assets/hbo-logo.svg?react';
import DisneyLogo from '../../assets/disney-logo.svg?react';
import TurnerLogo from '../../assets/turner-logo.svg?react';
import UniversalLogo from '../../assets/universal-logo.svg?react';
import AdultSwimLogo from '../../assets/adult-swim-logo.svg?react';
import CartoonNetworkLogo from '../../assets/cartoon-network-logo.svg?react';

interface StudioLogoProps {
  studio: string;
  height?: string;
}

export default function StudioLogo(props: StudioLogoProps) {
  const height = props.height || '5rem';
  switch (props.studio) {
    case 'WARNER_BROS':
      return <WarnerBrosLogo height={height} />;
    case 'HBO':
      return <HboLogo height={height} />;
    case 'DISNEY':
      return <DisneyLogo height={height} />;
    case 'TURNER':
      return <TurnerLogo height={'3rem'} />;
    case 'UNIVERSAL':
      return <UniversalLogo height={height} />;
    case 'ADULT_SWIM':
      return <AdultSwimLogo height={height} />;
    case 'CARTOON_NETWORK':
      return <CartoonNetworkLogo height={height} />;
    default:
      return <>{props.studio}</>;
  }
}
