import clsx from 'clsx';
import { ChangeEventHandler, forwardRef, Ref, useCallback, useEffect, useState } from 'react';

import CheckIcon from '../../assets/icons/check.svg?react';
import ChevronDownIcon from '../../assets/icons/chevron-down.svg?react';
import SearchIcon from '../../assets/icons/search.svg?react';
import CloseIcon from '../../assets/icons/close.svg?react';
import { OptionPlain } from '../../common/types';
/*
How to use it:

const languages = [
  { value: 'sv', label: 'Swedish' },
  { value: 'en', label: 'English' }
];

<form onSubmit={handleSubmit(handleSignIn)}>
  <Dropdown {...register('language')} options={languages} />
</form>

or

<form onSubmit={handleSubmit(handleSignIn)}>
  <Dropdown {...register('studio')} options={getOptions(StudioLabels)} />
</form>

*/

export const Dropdown = forwardRef(
  (
    props: {
      name: string;
      options: OptionPlain[];
      onChange?: ChangeEventHandler;
      onBlur?: ChangeEventHandler;
      value?: string | string[];
      placeholder?: string;
      className?: string;
      isMultiple?: boolean;
      isSearchable?: boolean;
      disabled?: boolean;
    },
    forwardedRef: Ref<HTMLInputElement>
  ) => {
    const [componentId] = useState<number>(Math.floor(Date.now() * Math.random()));
    const [open, setOpen] = useState(true);
    const [directionUpwards, setDirectionUpwards] = useState(false);
    const [checked, setChecked] = useState<{ [key: string]: boolean }>({});
    const [searchText, setSearchText] = useState('');

    const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      updateCheckedState();
      if (props.onChange) props.onChange(event);
    };

    const keyboardHandler = useCallback((e: KeyboardEvent) => {
      switch (e.key) {
        case 'Esc':
        case 'Escape':
          e.preventDefault();
          setOpen(false);
      }
    }, []);

    const documentOnClick = (event: MouseEvent) => {
      if (
        !event.composedPath().find((element) => {
          const e = element as HTMLInputElement;
          return e.id === `component-${componentId}`;
        })
      ) {
        event.preventDefault();
        setOpen(false);
        document.removeEventListener('click', documentOnClick);
        document.removeEventListener('keydown', keyboardHandler);
      }
    };

    const toogleOpen = () => {
      if (open) {
        setOpen(false);
        document.removeEventListener('click', documentOnClick);
        document.removeEventListener('keydown', keyboardHandler);
      } else {
        setOpen(true);
        document.addEventListener('click', documentOnClick);
        document.addEventListener('keydown', keyboardHandler);
      }
    };

    const toggleChecked = () => {
      if (!props.isMultiple) setOpen(false);
    };

    const setRadioButtonsCheckedState = () => {
      const value = props.value;
      value &&
        (Array.isArray(value) ? value : [value]).map((v) => {
          const option = document.getElementById(optionId(v)) as HTMLInputElement;
          if (option) option.checked = true;
        });
    };

    const updateCheckedState = () => {
      const targetNode = document.getElementsByName(props.name);
      const c = { ...checked }; // make a copy
      targetNode.forEach((node) => {
        const n = node as HTMLInputElement;
        c[n.id] = n.checked;
      });
      setChecked(c);
    };

    const optionId = (value: string) => `OPTION-${componentId}-${value}`;

    const buttonKeyboardHandler = useCallback((e: KeyboardEvent) => {
      switch (e.key) {
        case 'Up':
        case 'ArrowUp':
        case 'Down':
        case 'ArrowDown':
        case ' ': // Space
        case 'Enter':
          e.preventDefault();
          setOpen(true);
      }
    }, []);

    const handleButtonFocused = () => {
      document.addEventListener('keydown', buttonKeyboardHandler);
    };

    const handleButtonBlur = () => {
      document.removeEventListener('keydown', buttonKeyboardHandler);
    };

    useEffect(() => {
      // start to determine dropdown direction
      const selectDropdown = document.getElementById(`select-dropdown-${componentId}`);
      if (selectDropdown && selectDropdown?.getBoundingClientRect().bottom > window.innerHeight) {
        setDirectionUpwards(true);
      }
      setOpen(false);

      // initialize with props.value if it is set
      setRadioButtonsCheckedState();

      // get default value from the <input...> directly
      updateCheckedState();
    }, []);

    useEffect(() => {
      setRadioButtonsCheckedState();
      updateCheckedState();
    }, [props.value]);

    return (
      <div id={`component-${componentId}`} className={clsx('relative', props.className)}>
        <button
          type="button"
          role="combobox"
          aria-labelledby="select button"
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-controls={`select-dropdown-${componentId}`}
          onClick={toogleOpen}
          onFocus={handleButtonFocused}
          onBlur={handleButtonBlur}
          className={
            'focus:shadow-select focus:border-primary-300 truncate items-center shadow-xs text-gray-900 data-[placeholder]:text-gray-500 text-md text-left justify-stretch outline-none bg-white border border-gray-300 px-3.5 py-2.5 rounded-lg w-full'
          }>
          <div className="flex">
            <div className="flex gap-1 grow">
              {props.options
                .filter((o) => checked[optionId(o.value)] === true)
                .map((o) => (
                  <div
                    key={o.value}
                    className={clsx(
                      props.isMultiple && 'flex border border-gray-300 px-2 rounded-md'
                    )}>
                    <span>{o.label}</span>
                    {props.isMultiple && <CloseIcon className="stroke-gray-400 w-4 h-6 ml-1" />}
                  </div>
                ))}
              {Object.values(checked).every((item) => item === false) &&
                (props.placeholder || `Please select ${props.name}...`)}
            </div>
            <div className="flex-none">
              <ChevronDownIcon className={clsx('w-6 h-6 stroke-gray-500', open && 'rotate-180')} />
            </div>
          </div>
        </button>
        <ul
          id={`select-dropdown-${componentId}`}
          role="listbox"
          className={clsx(
            'absolute bg-white border border-gray-300 rounded-lg z-50 overflow-y-auto max-h-80 shadow-select w-full',
            !open && 'hidden',
            directionUpwards && 'bottom-0 -translate-y-12',
            !directionUpwards && 'top-0 translate-y-12'
          )}>
          {props.isSearchable && (
            <li>
              <search className="flex">
                <SearchIcon className="stroke-gray-400 h-10 mx-3" />
                <input
                  type="search"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  className="w-full h-8 border border-gray-300 rounded-md my-1 mr-1 p-2"
                />
              </search>
            </li>
          )}
          {(searchText
            ? props.options.filter((o) => o.label.toUpperCase().includes(searchText.toUpperCase()))
            : props.options
          ).map((o) => (
            <li
              key={o.value}
              role="option"
              className="flex flex-row items-center divide-y divide-gray-100">
              <input
                ref={forwardedRef}
                type={props.isMultiple === true ? 'checkbox' : 'radio'}
                id={optionId(o.value)}
                name={props.name}
                value={o.value}
                onChange={onChange}
                onBlur={props.onBlur}
                onClick={toggleChecked}
                className={'absolute left-0 opacity-0'}
              />
              <label
                htmlFor={optionId(o.value)}
                className="flex flex-row justify-between w-full hover:bg-gray-50 py-1.5 px-3.5">
                {o.label}
                {checked[optionId(o.value)] && <CheckIcon className={'w-5 stroke-primary'} />}
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  }
);
Dropdown.displayName = 'Dropdown';
