import React from 'react';
import ReactSelect, { Props } from 'react-select';
import Tailwind from '../../../tailwind.config';

type SelectProps = Props;

const Select: React.FC<SelectProps & { readOnly?: boolean; isInvalid?: boolean }> = (props) => {
  if (props.readOnly) {
    const option = (props.value as { label: string; value: string }) || '';
    return <span className="font-medium text-gray-900">{option.label}</span>;
  }
  return (
    <ReactSelect
      className={props.className}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: '48px',
          borderRadius: '0.5rem',
          boxShadow: 'none',
          '&:hover': {
            borderColor: Tailwind.theme.extend.colors['primary-300'],
            boxShadow: `0px 0px 1px ${Tailwind.theme.extend.colors['primary-300']}`
          },
          '&:focus': {
            borderColor: Tailwind.theme.extend.colors['primary-300'],
            boxShadow: `0px 0px 1px ${Tailwind.theme.extend.colors['primary-300']}`
          },
          '&:active': {
            borderColor: Tailwind.theme.extend.colors['primary-300'],
            boxShadow: `0px 0px 1px ${Tailwind.theme.extend.colors['primary-300']}`
          },
          borderColor: state.isFocused
            ? Tailwind.theme.extend.colors['primary-300']
            : props.isInvalid
              ? Tailwind.theme.extend.colors['danger']
              : Tailwind.theme.extend.colors['gray-300']
        }),
        placeholder: (baseStyles) => ({
          ...baseStyles,
          color: Tailwind.theme.extend.colors['gray-400']
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          color: Tailwind.theme.extend.colors['gray-400']
        }),
        multiValue: (baseStyles) => ({
          ...baseStyles,
          backgroundColor: Tailwind.theme.extend.colors['gray-50'],
          color: Tailwind.theme.extend.colors['gray-700']
        }),
        multiValueRemove: (baseStyles) => ({
          ...baseStyles,
          '&:hover': {
            backgroundColor: Tailwind.theme.extend.colors['danger-200'],
            color: Tailwind.theme.extend.colors['danger']
          }
        }),
        indicatorSeparator: () => ({
          display: 'none'
        }),
        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          paddingLeft: '0px'
        }),
        option: (baseStyles, state) => {
          return {
            ...baseStyles,
            backgroundColor: '#fff',
            color: state.isSelected
              ? Tailwind.theme.extend.colors['primary']
              : state.isDisabled
                ? Tailwind.theme.extend.colors['gray-400']
                : Tailwind.theme.extend.colors['gray'],
            textDecoration: state.isDisabled ? 'line-through' : '',
            cursor: state.isDisabled ? 'not-allowed' : 'default',
            '&:hover': {
              backgroundColor: Tailwind.theme.extend.colors['gray-50']
            },
            '&:active': {
              backgroundColor: Tailwind.theme.extend.colors['gray-50']
            },
            '&:focus': {
              backgroundColor: Tailwind.theme.extend.colors['gray-50']
            }
          };
        }
      }}
      {...props}
    />
  );
};

export default Select;
