import {
  IngestionProcess,
  IngestionStart,
  AnalyzeFilesResponse,
  IngestionStartResponse,
  IngestionProcessesResponse,
  TitleCandidate
} from './types';
import { commonApi } from '../common/commonApi';
import { EidrSearchResult } from '../eidr/types';

export const ingestionApi = commonApi.injectEndpoints({
  endpoints(builder) {
    return {
      fetchProcesses: builder.query<
        IngestionProcessesResponse,
        {
          q: string;
          key_id: string;
          key_at: string;
        }
      >({
        query: (params) => {
          const searchParams = new URLSearchParams(params);
          return `/ingestion/processes?${searchParams.toString()}`;
        },
        providesTags: (result) =>
          result && result.Items
            ? result.Items.map(({ id }) => ({ type: 'IngestionProcess', id }))
            : ['IngestionProcess']
      }),
      fetchProcessDetails: builder.query<IngestionProcess, string>({
        query: (id: string) => `/ingestion/processes/${id}`,
        providesTags: (_result, _error, id) => [{ type: 'IngestionProcess', id }]
      }),

      postAnalyzeFiles: builder.mutation<AnalyzeFilesResponse, string[]>({
        query: (post) => ({
          url: '/ingestion/analyze-paths',
          method: 'POST',
          body: post
        }),
        transformResponse: (response: AnalyzeFilesResponse) => {
          return response;
        }
      }),

      fetchTitleMetadata: builder.query<TitleCandidate[], { mpm?: string; eidr?: string }>({
        query: (params) => {
          const searchParams = new URLSearchParams(params);
          return {
            url: `/ingestion/title-metadata?${searchParams.toString()}`
          };
        },
        transformResponse: (response: TitleCandidate[]) => {
          return response;
        }
      }),
      fetchEidrSearch: builder.query<
        EidrSearchResult,
        { search: string; pageNumber: number; pageSize: number; reftype: string }
      >({
        query: (params) => {
          const searchParams = new URLSearchParams({
            search: params.search,
            pageNumber: params.pageNumber.toString(),
            pageSize: params.pageSize.toString(),
            reftype: params.reftype.toString()
          });
          return {
            url: `/ingestion/eidr?${searchParams.toString()}`
          };
        }
      }),
      fetchEidrChildren: builder.query<EidrSearchResult, { eidrId: string }>({
        query: (params) => {
          return {
            url: `/ingestion/eidr/children?eidrId=${params.eidrId}`
          };
        }
      }),
      postStartIngestion: builder.mutation<IngestionStartResponse, IngestionStart>({
        query: (body) => ({
          url: '/ingestion/start',
          method: 'POST',
          body: body
        })
      })
    };
  }
});

export const {
  useFetchProcessesQuery,
  useLazyFetchProcessesQuery,
  useFetchProcessDetailsQuery,
  usePostAnalyzeFilesMutation,
  useLazyFetchTitleMetadataQuery,
  usePostStartIngestionMutation,
  useLazyFetchEidrSearchQuery,
  useLazyFetchEidrChildrenQuery
} = ingestionApi;
