import { commonApi } from '../common/commonApi';
import {
  TitleCard,
  TitleCardResponse,
  BaseTitleCard,
  PushSubscriptionResponse,
  UploadUrlResponse
} from './types';

export const titleCardApi = commonApi.injectEndpoints({
  endpoints(builder) {
    return {
      fetchTitleCards: builder.query<TitleCardResponse, boolean>({
        query: (checked) => `/supplements?exclude_expired=${checked}`,
        providesTags: (result) =>
          result && result.Items
            ? result.Items.map(({ key }) => ({ type: 'TitleCard', key }))
            : ['TitleCard']
      }),
      fetchUploadUrl: builder.query<UploadUrlResponse, { filename: string }>({
        query: (data) => `/supplements/get-upload-url?filename=${data.filename}`
      }),
      postTitleCard: builder.mutation<TitleCardResponse, BaseTitleCard>({
        query: (details) => ({ url: `/supplements`, method: 'POST', body: details })
      }),
      patchTitleCard: builder.mutation<TitleCardResponse, Partial<TitleCard>>({
        query: (details) => ({ url: `/supplements/${details.key}`, method: 'PATCH', body: details })
      }),
      deleteTitleCard: builder.mutation<TitleCardResponse, string>({
        query: (supplement_key) => ({ url: `/supplements/${supplement_key}`, method: 'DELETE' })
      }),

      // TODO: The following two endpoints should not really be here:
      postWebPushSubscribe: builder.mutation<PushSubscriptionResponse, PushSubscription>({
        query: (subscription) => ({
          url: `/notifications/webpush/subscribe`,
          method: 'POST',
          body: subscription
        })
      }),
      postWebPushUnsubscribe: builder.mutation<PushSubscriptionResponse, PushSubscription>({
        query: (subscription) => ({
          url: `/notifications/webpush/unsubscribe`,
          method: 'POST',
          body: subscription
        })
      })
    };
  }
});

export const {
  useFetchTitleCardsQuery,
  useLazyFetchTitleCardsQuery,
  useLazyFetchUploadUrlQuery,
  usePostTitleCardMutation,
  usePatchTitleCardMutation,
  useDeleteTitleCardMutation,
  usePostWebPushSubscribeMutation,
  usePostWebPushUnsubscribeMutation
} = titleCardApi;
