import { createSlice } from '@reduxjs/toolkit';
import { System, Title } from '../catalog/types';
import { TitleCard } from '../titleCard/types';
import { OrderGroup } from './types';
import deepmerge from 'deepmerge';

interface TitleInput {
  filenames: { [key: string]: string };
  assetSystems: {
    [key: string]: {
      editVersion: string;
      systems: System[];
      audioLanguages: string[];
      subtitleLanguages: string[];
      includeVideo?: boolean;
      titleCard?: string;
      assetsType: string;
      systemLabel: string;
      destination?: string;
      requireHd?: boolean;
      require16x9?: boolean;
    };
  };
}

interface Assets {
  [key: string]: TitleInput;
}

const initialState: {
  currentSection?: string;
  orderAssetMode?: string;
  titles: Title[];
  titleCards: TitleCard[];
  orderGroups: OrderGroup[];
  systems: System[];
  selectedTitles: Title[];
  assets?: Assets;
  selectedRows: Set<number>;
  selectedAsset?: TitleInput;
  selectedSystem?: string;
} = {
  currentSection: undefined,
  orderAssetMode: undefined,
  titles: [],
  titleCards: [],
  orderGroups: [],
  systems: [],
  selectedTitles: [],
  assets: undefined,
  selectedRows: new Set(),
  selectedAsset: undefined,
  selectedSystem: undefined
};

export const orderSlice = createSlice({
  name: 'orderSlice',
  initialState,
  reducers: {
    setCurrentSection: (state, action) => {
      state.currentSection = action.payload;
    },
    setOrderAssetMode: (state, action) => {
      state.orderAssetMode = action.payload;
    },
    setTitles: (state, action) => {
      state.titles = action.payload;
    },
    setTitleCards: (state, action) => {
      state.titleCards = action.payload;
    },
    setOrderGroups: (state, action) => {
      state.orderGroups = action.payload;
    },
    setSystems: (state, action) => {
      state.systems = action.payload;
    },
    setSelectedTitles: (state, action) => {
      state.selectedTitles = action.payload;
    },
    resetAssets: (state) => {
      state.assets = {};
    },
    appendAssets: (state, action) => {
      if (state.orderAssetMode === 'add') {
        if (state.assets) {
          state.assets = deepmerge(state.assets, action.payload, {
            arrayMerge: (destinationArray, sourceArray) => [
              ...new Set([...destinationArray, ...sourceArray])
            ]
          });
        } else {
          state.assets = action.payload;
        }
      } else {
        const keys = Object.keys(action.payload);
        for (const i in keys) {
          const title = action.payload[keys[i]];
          if (state.assets && state.assets[keys[i]] && state.selectedSystem) {
            const assetSystem = title.assetSystems[state.selectedSystem];
            if (assetSystem.editVersion) {
              state.assets[keys[i]].assetSystems[state.selectedSystem].editVersion =
                assetSystem.editVersion;
            }
            if (assetSystem.audioLanguages) {
              state.assets[keys[i]].assetSystems[state.selectedSystem].audioLanguages =
                assetSystem.audioLanguages;
            }
            if (assetSystem.subtitleLanguages) {
              state.assets[keys[i]].assetSystems[state.selectedSystem].subtitleLanguages =
                assetSystem.subtitleLanguages;
            }
            if (typeof assetSystem.includeVideo !== 'undefined') {
              state.assets[keys[i]].assetSystems[state.selectedSystem].includeVideo =
                assetSystem.includeVideo;
            }
            if (typeof assetSystem.requireHd !== 'undefined') {
              state.assets[keys[i]].assetSystems[state.selectedSystem].requireHd =
                assetSystem.requireHd;
            }
            if (typeof assetSystem.require16x9 !== 'undefined') {
              state.assets[keys[i]].assetSystems[state.selectedSystem].require16x9 =
                assetSystem.require16x9;
            }
            if (typeof assetSystem.titleCard !== 'undefined') {
              state.assets[keys[i]].assetSystems[state.selectedSystem].titleCard =
                assetSystem.titleCard;
            }
            if (typeof assetSystem.destination !== 'undefined') {
              state.assets[keys[i]].assetSystems[state.selectedSystem].destination =
                assetSystem.destination;
            }
            state.assets[keys[i]].filenames = {
              ...state.assets[keys[i]].filenames,
              ...title.filenames
            };
          }
        }
      }
    },
    deleteAsset: (state, action) => {
      const { item, id } = action.payload;
      if (state.assets) {
        delete state.assets[item._id].filenames[id];
        delete state.assets[item._id].assetSystems[id];
      }
    },
    setSelectedRows: (state, action) => {
      state.selectedRows = action.payload;
    },
    setSelectedAsset: (state, action) => {
      state.selectedAsset = action.payload;
    },
    setSelectedSystem: (state, action) => {
      state.selectedSystem = action.payload;
    }
  }
});

export const {
  setCurrentSection,
  setOrderAssetMode,
  setTitles,
  setTitleCards,
  setOrderGroups,
  setSystems,
  setSelectedTitles,
  appendAssets,
  deleteAsset,
  setSelectedRows,
  setSelectedAsset,
  setSelectedSystem,
  resetAssets
} = orderSlice.actions;

export default orderSlice;
