import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import Alert from '../ui/Alert';

type ApiErrorType = FetchBaseQueryError | SerializedError | undefined;

const getApiErrorMessage = (error: ApiErrorType) => {
  if (error === undefined) return;
  if ('message' in error) return error.message;
  if ('status' in error) {
    switch (error.status) {
      case 'FETCH_ERROR':
        return 'Could not fetch any data';
      case 'PARSING_ERROR':
        return 'Could not parse the data';
      case 'CUSTOM_ERROR':
        return 'Something went wrong when fetching the data';
      default:
        return 'An unrecognized error occurred';
    }
  }
};

interface ApiErrorAlertProps {
  error: ApiErrorType;
}

export default function ApiErrorAlert(props: ApiErrorAlertProps) {
  return (
    <Alert rounded variant="danger" className="justify-start">
      {getApiErrorMessage(props.error)}
    </Alert>
  );
}
