import React, { useState, useEffect } from 'react';
import Button from './Button';
import ArrowLeftIcon from '../../assets/icons/arrow-left.svg?react';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import clsx from 'clsx';

/*
  Pagination Component
  How to use it:

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items to display per page
  const totalItems = 70; // Total number of items in your dataset

  // Simulate fetching data for the current page
  const itemsToShow = Array.from(
    { length: itemsPerPage },
    (_, index) => (currentPage - 1) * itemsPerPage + index + 1
  );

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="App p-8">
      {itemsToShow.map((item) => (
        <div key={item} className="border-b border-gray-100 p-2">item #{item}</div>
      ))}
      <Pagination
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
        onPageChange={handlePageChange}
        className="mt-4"
      />
    </div>
  );

*/

export interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  currentPage?: number;
  className?: string;
  openEndedMode?: boolean;
  labelDisplayedRows?: string;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({
  totalItems = 100,
  itemsPerPage = 25,

  onPageChange,
  openEndedMode,
  labelDisplayedRows,
  ...props
}) => {
  const [currentPage, setCurrentPage] = useState(props.currentPage || 1);
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    setCurrentPage(props.currentPage || 1);
  }, [props.currentPage]);

  const handlePageChange = (page: number) => {
    if ((page >= 1 && page <= totalPages) || openEndedMode) {
      setCurrentPage(page);
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 3;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <Button
            key={i}
            rounded
            size="sm"
            className={`bg-white border-0 shadow-none focus:shadow-none active:shadow-none ${
              currentPage === i ? 'font-bold bg-gray-50' : ''
            }`}
            onClick={() => handlePageChange(i)}>
            {i}
          </Button>
        );
      }
    } else {
      const middlePage = Math.floor(maxVisiblePages / 2);
      let startPage = currentPage - middlePage;
      let endPage = currentPage + middlePage;

      if (startPage < 1) {
        startPage = 1;
        endPage = maxVisiblePages;
      }

      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - maxVisiblePages + 1;
      }

      if (startPage > 1) {
        pageNumbers.push(
          <Button
            className="bg-white border-0 shadow-none focus:shadow-none active:shadow-none"
            rounded
            size="sm"
            key={1}
            onClick={() => handlePageChange(1)}>
            1
          </Button>
        );
        if (startPage > 2) {
          pageNumbers.push(<span key="separator1">...</span>);
        }
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <Button
            key={i}
            rounded
            size="sm"
            className={`bg-white border-0 shadow-none focus:shadow-none active:shadow-none ${
              currentPage === i ? 'font-bold bg-gray-50' : ''
            }`}
            onClick={() => handlePageChange(i)}>
            {i}
          </Button>
        );
      }

      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          pageNumbers.push(<span key="separator2">...</span>);
        }
        pageNumbers.push(
          <Button
            className="bg-white border-0 shadow-none focus:shadow-none active:shadow-none"
            key={totalPages}
            rounded
            size="sm"
            onClick={() => handlePageChange(totalPages)}>
            {totalPages}
          </Button>
        );
      }
    }

    return pageNumbers;
  };

  return (
    <div
      className={`flex flex-row gap-2 w-full justify-between text-sm text-gray-700 items-center ${props.className}`}>
      <Button
        rounded
        size="sm"
        className={clsx('bg-white')}
        onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}>
        <ArrowLeftIcon className="stroke-gray-700" />
        Previous
      </Button>
      {openEndedMode && labelDisplayedRows}
      {!openEndedMode && (
        <div className="flex flex-row gap-2 justify-center">{renderPageNumbers()}</div>
      )}
      <Button
        rounded
        size="sm"
        className={clsx('bg-white')}
        onClick={() => currentPage !== totalPages && handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}>
        Next <ArrowRightIcon className="stroke-gray-700" />
      </Button>
    </div>
  );
};

export default Pagination;
