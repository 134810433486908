import React, { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';
import { Checkbox } from './Checkbox';
import Pagination, { PaginationProps } from './Pagination';
import Result from './Result';

/*
  Table Component

  How to use it:

  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());

  const data: any = [
    { id: 1, name: 'John Doe', age: 28 },
    { id: 2, name: 'Jane Smith', age: 32 }
    // Add more data items here
  ];

  const columns = [
    {
      key: 'id',
      title: 'ID',
      allowSorting: true
    },
    {
      key: 'name',
      title: 'Name',
      allowSorting: true,
      render: (item: any) => <a href="#">{item.name}</a>,
      renderTitle: () => <b className="text-primary">Name</b>
    },
    {
      key: 'age',
      title: 'Age',
      allowSorting: false
    }
  ];

  return (
    <div className="App p-8">
      <Table
        data={data}
        columns={columns}
        multipleSelection={true}
        selectedRows={selectedRows}
        pagination={{
          totalItems: data.length,
          itemsPerPage: 1,
          onPageChange: (page) => page
        }}
      />
    </div>
  );
*/

interface ColumnProps {
  key: string;
  title: string;
  render?: (item: any) => ReactNode;
  renderTitle?: () => ReactNode;
  allowSorting?: boolean;
  className?: string;
}

interface TableProps {
  data: any[];
  columns: ColumnProps[];
  pagination: PaginationProps;
  paginationPosition?: 'top' | 'bottom' | 'both';
  selectedRows?: Set<number>;
  onSelectChange?: (selected: Set<number>) => void;
  multipleSelection?: boolean;
  rowId?: string;
  sortedColumn?: string;
  sortDirection?: string;
  setSortDirection?: (value: string) => void;
  setSortedColumn?: (value: string) => void;
  disabledRows?: string[];
  noDataLabel?: string;
  noDataDescription?: string;
  noDataIcon?: ReactNode;
  size?: 'xs' | 'sm' | 'md' | 'lg';
}

const Table: React.FC<TableProps> = ({
  data,
  columns,
  onSelectChange,
  multipleSelection,
  pagination,
  paginationPosition = 'bottom',
  setSortDirection,
  setSortedColumn,
  sortedColumn,
  sortDirection,
  disabledRows,
  size = 'md',
  ...props
}) => {
  const [rowID] = useState<string>(props.rowId || 'id');
  const [selectedRows, setSelectedRows] = useState<Set<number>>(props.selectedRows || new Set());
  const enabledRowIDs = data
    .filter((item) => disabledRows?.indexOf(item[rowID]) === -1)
    .map((item) => item[rowID]);

  const sizeMap = {
    xs: 'px-2 py-1',
    sm: 'px-3 py-2',
    md: 'px-4 py-3',
    lg: 'px-5 py-4'
  };

  useEffect(() => {
    if (selectedRows) {
      onSelectChange && onSelectChange(selectedRows);
    }
  }, [selectedRows]);

  const handleSort = (column: string) => {
    if (column === sortedColumn) {
      setSortDirection && setSortDirection(sortDirection === 'ASC' ? 'DESC' : 'ASC');
    } else {
      setSortedColumn && setSortedColumn(column);
      setSortDirection && setSortDirection('ASC');
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.size === enabledRowIDs.length + (disabledRows?.length || 0)) {
      // If all enabled rows are already selected, deselect all enabled rows
      setSelectedRows(
        new Set(
          Array.from(selectedRows).filter((id) => disabledRows?.indexOf(id.toString()) !== -1)
        )
      );
    } else {
      // If not all enabled rows are selected, select all enabled rows
      setSelectedRows(
        new Set([
          ...Array.from(selectedRows).filter((id) => disabledRows?.indexOf(id.toString()) !== -1),
          ...enabledRowIDs
        ])
      );
    }
  };

  const handleSelectRow = (index: number) => {
    if (selectedRows.has(index)) {
      const newSelectedRows = new Set(selectedRows);
      newSelectedRows.delete(index);
      setSelectedRows(newSelectedRows);
    } else {
      setSelectedRows(new Set(selectedRows).add(index));
    }
  };

  const renderTitle = (column: ColumnProps): ReactNode => {
    if (column.renderTitle) {
      return column.renderTitle();
    }
    return column.title || '';
  };

  const render = (column: ColumnProps, item: any) => {
    if (column.render) {
      return column.render(item);
    }
    return item[column.key] || '';
  };

  const renderPagination = () => {
    if (pagination.totalItems / pagination.itemsPerPage > 1 || pagination.openEndedMode) {
      return (
        <div className="flex flex-row justify-center">
          <Pagination className="px-4 pb-6 pt-2" {...pagination} />
        </div>
      );
    }
    return null;
  };

  if (data.length === 0) {
    return (
      <Result
        title={props.noDataLabel}
        description={props.noDataDescription}
        icon={props.noDataIcon}
      />
    );
  }

  return (
    <div className="flex flex-col gap-4">
      {paginationPosition &&
        ['top', 'both'].indexOf(paginationPosition) !== -1 &&
        renderPagination()}
      <table className="min-w-full divide-y divide-gray-200 border-y border-gray-200 text-sm">
        <thead>
          <tr>
            {multipleSelection && (
              <th
                scope="col"
                className="pl-5 pr-0 py-2 text-left bg-gray-50 text-xs font-medium text-gray-500 uppercase w-8">
                <Checkbox
                  value={selectedRows.size === enabledRowIDs.length + (disabledRows?.length || 0)}
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {columns.map((column, index) => (
              <th
                onClick={() => column.allowSorting && handleSort(column.key)}
                key={`col-${column.key}-${index}`}
                scope="col"
                className={clsx(
                  `${sizeMap[size]} bg-gray-50 text-left text-xs font-medium text-gray-500 cursor-pointer`,
                  { [column.className as string]: true }
                )}>
                {renderTitle(column)}
                {column.allowSorting && sortedColumn === column.key && (
                  <span className="ml-1">{sortDirection === 'ASC' ? '↑' : '↓'}</span>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {data.map((item) => (
            <tr
              key={item[rowID]}
              className={clsx({ 'bg-gray-25': disabledRows?.indexOf(item[rowID]) !== -1 })}>
              {multipleSelection && (
                <td className="pl-5 pr-0 py-3 whitespace-nowrap ">
                  <Checkbox
                    value={selectedRows.has(item[rowID])}
                    onChange={() => handleSelectRow(item[rowID])}
                    disabled={disabledRows?.indexOf(item[rowID]) !== -1}
                  />
                </td>
              )}
              {columns.map((column, index) => (
                <td
                  key={`col-${column.key}-${index}-${item[rowID]}`}
                  className={clsx(sizeMap[size], {
                    [column.className as string]: true
                  })}>
                  {render(column, item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {paginationPosition &&
        ['bottom', 'both'].indexOf(paginationPosition) !== -1 &&
        renderPagination()}
    </div>
  );
};

export default Table;
