import React, { RefObject, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Skeleton from '../../components/ui/Skeleton';
import { Tag } from '../../components/ui/Tag';
import { RootState } from '../../app/store';
import { Organization, OrganizationAirline } from '../../features/organizations/types';
import SearchIcon from '../../assets/icons/search.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import PlusIcon from '../../assets/icons/plus.svg?react';
import DownloadIcon from '../../assets/icons/download.svg?react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import OrgsFilters from '../../components/orgs-filters';
import { downloadFile } from '../../common/utils';
import { toCsvString } from '../../common/csv';
import { formatDateTimeForPath } from '../../common/dates';
import toast from 'react-hot-toast';

export default function OrganizationsPage({
  loading,
  page,
  setPage
}: {
  loading: { users: boolean; organizations: boolean };
  page: number;
  setPage: (value: number) => void;
  getData: () => void;
}) {
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const { results, meta } = useSelector((state: RootState) => state.organizations);
  const [search, setSearch] = useState<string>();
  const queryRef = useRef<HTMLInputElement>();
  const columns = [
    {
      key: 'name',
      title: 'Name',
      className: 'w-80',
      render: (item: Organization) => (
        <h4 className="text-sm text-gray-900 font-medium">{item.name}</h4>
      )
    },
    {
      key: 'order_types',
      title: 'Order Types',
      render: (item: Organization) => (
        <div className="flex flex-row gap-1">
          {item.orderTypes.map((orderType: string) => (
            <Tag key={orderType} size="xs" variant="warning">
              {orderType}
            </Tag>
          ))}
        </div>
      )
    },
    {
      key: 'catalog_sources',
      title: 'Catalog Sources',
      render: (item: Organization) => (
        <div className="flex flex-row gap-1">
          {item.sourceCatalogs.map((catalog: string) => (
            <Tag key={catalog} size="xs" variant="warning">
              {catalog}
            </Tag>
          ))}
        </div>
      )
    },
    {
      key: 'airlines',
      title: 'Airlines',
      render: (item: Organization) => (
        <div className="flex flex-wrap gap-1">
          {item.airlines.map((airline: OrganizationAirline) => (
            <Tag key={airline.iataCode} size="xs" variant="info" className="">
              {airline.name} ({airline.iataCode})
            </Tag>
          ))}
        </div>
      )
    },
    {
      key: 'id',
      title: '',
      render: (item: Organization) => (
        <div className="flex flex-row">
          <Link
            to={`/settings/organizations/${item.orgId}`}
            className="px-3 py-2 hover:bg-gray-50 rounded-lg transition-all">
            <EditIcon className="w-6 h-6 stroke-gray" />
          </Link>
        </div>
      )
    }
  ];

  const getResults = () => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      return results.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseSearch) ||
          item.orgId.toLowerCase().includes(lowerCaseSearch)
      );
    } else {
      return results;
    }
  };

  const onDownloadCsv = () => {
    const orgs = results.map((org) => ({
      Name: org.name,
      'Order Types': org.orderTypes.join(', '),
      'Catalog Sources': org.sourceCatalogs.join(', '),
      Clients: org.airlines.map((airline) => `${airline.name} (${airline.iataCode})`).join(', ')
    }));
    downloadFile(toCsvString(orgs), `organizations-export-${formatDateTimeForPath()}.csv`);
    toast.success('Organizations CSV downloaded successfully');
  };

  return (
    <div className="m-4 mt-0 border border-gray-200 rounded">
      <Helmet>
        <title>Above Media | Settings | Organizations</title>
      </Helmet>
      <div className="flex flex-row items-center">
        <div className="p-4 flex flex-col gap-2 grow">
          <div className="flex flex-row gap-2 items-center">
            <h3 className="text-gray-900 text-lg font-semibold">Organizations</h3>
            <Tag size="xs" variant="primary" className="bg-white text-primary-700">
              {meta?.total} Organizations
            </Tag>
          </div>
          <p className="text-xs text-gray">Manage organizations and their data here.</p>
        </div>
        <div className="p-4 flex flex-row gap-4">
          <Button rounded variant="defaultOutlined" onClick={onDownloadCsv}>
            <DownloadIcon className="stroke-gray" />
            Download CSV
          </Button>
          <Button
            rounded
            variant="primary"
            href="/settings/organizations/create"
            isRouterLink={false}>
            <PlusIcon className="stroke-white" />
            Add a new organization
          </Button>
        </div>
      </div>
      <div className="relative">
        {loading.organizations && (
          <>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
            <Skeleton />
          </>
        )}
        {!loading.organizations && (
          <div className="p-4">
            <OrgsFilters
              onSearch={(query) => setSearch(query)}
              search=""
              queryRef={queryRef as RefObject<HTMLInputElement>}
            />
          </div>
        )}
        {!loading.organizations && (
          <Table
            data={getResults() || []}
            columns={columns}
            multipleSelection={false}
            rowId="_id"
            selectedRows={selectedRows}
            onSelectChange={(keys) => setSelectedRows(keys)}
            disabledRows={[]}
            pagination={{
              totalItems: meta?.total || 0,
              itemsPerPage: meta?.pageSize || 30,
              currentPage: page,
              onPageChange: (page) => setPage(page)
            }}
            noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
            noDataLabel="No organizations were found!"
            noDataDescription="Your search did not match any organizations. Please try again later."
          />
        )}
      </div>
    </div>
  );
}
