import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '../../components/ui/Button';
import PlusIcon from '../../assets/icons/plus.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import Modal from '../../components/ui/Modal';
import FormItem from '../../components/ui/FormItem';
import { SubmitHandler, useForm } from 'react-hook-form';
import { setSelected, addEncodeSystem } from '../../features/organizations/organizationsSlice';
import { ALL_SYSTEMS } from '../../common/target_systems';
import { Dropdown } from '../ui/Dropdown';
import { IfeSystem, PoDestination } from '../../features/organizations/types';

interface EncodeSystemFormProps {
  ifeSystem: string;
  ifeFormat: string;
  vtkTemplate: string;
  poDestination: string;
}

export default function EncodeSystemFormModal({
  editMode,
  modalOpen,
  setEditMode,
  setModalOpen
}: {
  editMode: boolean;
  modalOpen: boolean;
  setEditMode: (value: boolean) => void;
  setModalOpen: (value: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [ifeSystem, setIfeSystem] = useState<IfeSystem | undefined>(undefined);
  const [vtkTemplate, setVtkTemplate] = useState<string>('');
  const [poDestinations, setPoDestinations] = useState<PoDestination[]>([]);

  const { register, handleSubmit, formState, reset, setValue } = useForm<EncodeSystemFormProps>({
    defaultValues: {
      ifeSystem: '',
      ifeFormat: '',
      vtkTemplate: '',
      poDestination: ''
    }
  });

  const resetForm = () => {
    reset({
      ifeSystem: '',
      ifeFormat: '',
      vtkTemplate: '',
      poDestination: ''
    });
    setEditMode(false);
  };

  const onFormSubmit: SubmitHandler<EncodeSystemFormProps> = (values) => {
    console.log(values);
    const system = ALL_SYSTEMS.find((s) => s.item_id === values.ifeSystem);
    if (!system) return;
    const format = system.formats.find((f) => f.item_id === values.ifeFormat);
    if (!format) return;
    const poDestination = format.po_destinations.find(
      (p) => p.destination_id === values.poDestination
    );
    if (!poDestination) return;
    dispatch(
      addEncodeSystem({
        ifeSystem: system.item_id,
        ifeFormat: format.item_id,
        vtkTemplate: values.vtkTemplate,
        poDestination: poDestination.destination_id
      })
    );
    resetForm();
    setModalOpen(false);
  };

  return (
    <Modal
      title={editMode ? 'Update IFE system' : 'Add a new IFE system'}
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={() => dispatch(setSelected(undefined))}>
      <form className="divide-y divide-gray-100">
        <FormItem label="IFE system">
          <Dropdown
            {...register('ifeSystem', { required: true })}
            options={ALL_SYSTEMS.map((s) => ({ value: s.item_id, label: s.item_text }))}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const selectedIfeSystem = ALL_SYSTEMS.find((i) => i.item_id === event.target.value);
              setIfeSystem(selectedIfeSystem as IfeSystem);
            }}
          />
        </FormItem>
        {ifeSystem?.formats && (
          <FormItem label="IFE format">
            <Dropdown
              {...register('ifeFormat', { required: true })}
              options={ifeSystem.formats.map((s) => ({ value: s.item_id, label: s.item_text }))}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                console.log(event.target.value);
                const ifeFormat = ifeSystem.formats.find((f) => f.item_id === event.target.value);
                if (ifeFormat) {
                  setPoDestinations(ifeFormat.po_destinations);
                  setVtkTemplate(ifeFormat.vtk_template);
                  setValue('vtkTemplate', ifeFormat.vtk_template);
                }
              }}
            />
          </FormItem>
        )}
        {vtkTemplate && (
          <FormItem label="VTK template">
            <input {...register('vtkTemplate', { required: true })} hidden />
            <div>{vtkTemplate}</div>
          </FormItem>
        )}
        <FormItem label="PO destination">
          <Dropdown
            {...register('poDestination', { required: true })}
            options={poDestinations.map((s) => ({
              value: s.destination_id,
              label: s.display_name
            }))}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              console.log(event.target.value);
              const poDestination = poDestinations.find(
                (f) => f.destination_id === event.target.value
              );
              console.log(poDestination);
            }}
          />
        </FormItem>
      </form>
      <div className="flex flex-row-reverse p-5 gap-2">
        <Button
          variant="primary"
          rounded
          type="button"
          onClick={() => handleSubmit(onFormSubmit)()}
          disabled={!formState.isValid}>
          {editMode ? (
            <>
              <SaveIcon className="w-5 h-5 stroke-white" /> Save
            </>
          ) : (
            <>
              <PlusIcon className="w-5 h-5 stroke-white" /> Add
            </>
          )}
        </Button>
        <Button variant="defaultOutlined" rounded onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
