import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormItem from '../ui/FormItem';
import Input from '../ui/Input';
import { SubmitHandler, useForm, Controller } from 'react-hook-form';
import {
  addAirline,
  deleteEncodeSystem,
  updateAirline
} from '../../features/organizations/organizationsSlice';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import { EncodeSystem, OrganizationAirline } from '../../features/organizations/types';
import PlusIcon from '../../assets/icons/plus.svg?react';
import Button from '../ui/Button';
import { pick } from 'lodash';
import EncodeSystemFormModal from './EncodeSystemFormModal';
import { RootState } from '../../app/store';
import Table from '../ui/Table';
import { territories } from '../../common/territories';
import Select from '../ui/Select';

export default function OrganizationAirlineForm({
  createMode,
  setAirlineModal
}: {
  createMode: boolean;
  setAirlineModal: (value: boolean) => void;
}) {
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const { encodeSystems, selectedAirline } = useSelector((state: RootState) => state.organizations);

  const columns = [
    {
      key: 'ifeFormat',
      title: 'Format'
    },
    {
      key: 'vtkTemplate',
      title: 'VTK Template'
    },
    {
      key: 'poDestination',
      title: 'PO'
    },
    {
      key: 'actions',
      title: '',
      render: (item: EncodeSystem) => (
        <div className="flex flex-row">
          <Button link onClick={() => dispatch(deleteEncodeSystem(item.ifeSystem))}>
            <DeleteIcon className="w-6 h-6 stroke-danger" />
          </Button>
        </div>
      )
    }
  ];

  const dispatch = useDispatch();
  const { register, handleSubmit, formState, reset, control } = useForm<OrganizationAirline>({
    defaultValues: {
      name: '',
      territory: '',
      iataCode: '',
      encodeSystems: []
    }
  });

  const resetForm = () => {
    reset({
      name: '',
      territory: '',
      iataCode: '',
      encodeSystems: []
    });
  };

  const onFormSubmit: SubmitHandler<OrganizationAirline> = (values) => {
    if (!selectedAirline) {
      values.encodeSystems = encodeSystems || [];
      dispatch(addAirline(pick(values, ['name', 'territory', 'iataCode', 'encodeSystems'])));
      resetForm();
      setAirlineModal(false);
    } else {
      values.encodeSystems = encodeSystems || [];
      dispatch(updateAirline(pick(values, ['name', 'territory', 'iataCode', 'encodeSystems'])));
      resetForm();
      setAirlineModal(false);
    }
  };

  useEffect(() => {
    if (selectedAirline) {
      reset({
        name: selectedAirline.name,
        iataCode: selectedAirline.iataCode,
        territory: selectedAirline.territory,
        encodeSystems: selectedAirline.encodeSystems
      });
    }
  }, [selectedAirline]);

  return (
    <form className="divide-y divide-gray-100">
      <FormItem help="Fill out airline name" label="Airline Name">
        <Input
          rounded
          className="grow"
          placeholder="Enter airline name"
          error={formState.errors.name?.message}
          {...register('name')}
        />
      </FormItem>
      <FormItem help="Spaces are not allowed" label="IATA Code">
        <Input
          rounded
          className="grow"
          placeholder="Enter airline IATA code"
          error={formState.errors.iataCode?.message}
          {...register('iataCode')}
        />
      </FormItem>
      <FormItem help="Select airline territory" label="Territory">
        <Controller
          name={'territory'}
          control={control}
          render={({ field }) => (
            <Select
              className="w-full"
              placeholder="Select territory"
              options={territories}
              value={territories.filter((option) => field.value === option.value)}
              onChange={(option: any) => {
                option && option.value ? field.onChange(option.value) : field.onChange('');
              }}
            />
          )}
        />
      </FormItem>
      {encodeSystems && encodeSystems.length > 0 && (
        <div className="p-2 ">
          <div className="bg-white rounded-lg p-2">
            <Table
              data={encodeSystems || []}
              columns={columns}
              size="xs"
              multipleSelection={false}
              rowId="_id"
              disabledRows={[]}
              pagination={{
                totalItems: encodeSystems?.length || 0,
                itemsPerPage: 30,
                currentPage: 1,
                onPageChange: (page) => console.log(page)
              }}
            />
          </div>
        </div>
      )}

      <div className="p-4 flex flex-row-reverse">
        <Button
          variant="primaryOutlined"
          size="xs"
          rounded
          type="button"
          onClick={() => {
            setModalOpen(true);
          }}
          disabled={!formState.isValid}>
          <PlusIcon className="w-5 h-5 stroke-primary" /> Add encode system
        </Button>
      </div>
      <div className="p-4 flex flex-row-reverse">
        {!selectedAirline && (
          <Button
            variant="primary"
            block
            rounded
            type="button"
            onClick={() => handleSubmit(onFormSubmit)()}
            disabled={!formState.isValid}>
            <PlusIcon className="w-5 h-5 stroke-white" /> Add airline
          </Button>
        )}
        {selectedAirline && (
          <Button
            variant="primary"
            block
            rounded
            type="button"
            onClick={() => handleSubmit(onFormSubmit)()}
            disabled={!formState.isValid}>
            <SaveIcon className="w-5 h-5 stroke-white" /> Update airline
          </Button>
        )}
      </div>
      {modalOpen && (
        <EncodeSystemFormModal
          editMode={editMode}
          modalOpen={modalOpen}
          setEditMode={setEditMode}
          setModalOpen={setModalOpen}
        />
      )}
    </form>
  );
}
