import { createSlice } from '@reduxjs/toolkit';
import { User } from './types';
import { Meta } from '../../common/types';

const initialState: { results: User[]; meta: Meta; selected: User | undefined } = {
  meta: {
    page: 1,
    pageSize: 30,
    total: 0
  },
  results: [],
  selected: undefined
};

export const usersSlice = createSlice({
  name: 'usersSlice',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      return {
        ...action.payload
      };
    },
    removeUser: (state, action) => {
      return {
        ...state,
        meta: {
          ...state.meta,
          total: state.meta?.total - 1
        },
        results: state.results.filter((item: User) => item.username !== action.payload)
      };
    },
    setSelected: (state, action) => {
      return {
        ...state,
        selected: action.payload
      };
    }
  }
});

export const { setUsers, removeUser, setSelected } = usersSlice.actions;

export default usersSlice;
