import React, { RefObject, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/ui/Button';
import Table from '../../components/ui/Table';
import Skeleton from '../../components/ui/Skeleton';
import { Tag } from '../../components/ui/Tag';
import { RootState } from '../../app/store';
import { User } from '../../features/users/types';
import { Organization } from '../../features/organizations/types';
import SearchIcon from '../../assets/icons/search.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import PlusIcon from '../../assets/icons/plus.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import DownloadIcon from '../../assets/icons/download.svg?react';
import Modal from '../../components/ui/Modal';
import { setSelected } from '../../features/users/usersSlice';
import FormItem from '../../components/ui/FormItem';
import Input from '../../components/ui/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ToggleGroup, ToggleGroupItem } from '../../components/ui/ToggleGroup';
import { addUser, updateUser } from '../../features/users/usersApi';
import Select from '../../components/ui/Select';
import { OptionPlain } from '../../common/types';
import { Helmet } from 'react-helmet-async';
import { cognito_groups } from '../../common/cognito_groups';
import { useUserContext } from '../../common/providers/UserProvider';
import UsersFilters from '../../components/users-filters';
import { downloadFile } from '../../common/utils';
import { toCsvString } from '../../common/csv';
import toast from 'react-hot-toast';
import { formatDateTimeForPath } from '../../common/dates';

interface EditFormProps {
  name: string;
  enabled: boolean;
  username: string;
  email: string;
  organizations: string[];
  groups: string[];
}

export default function UsersPage({
  loading,
  page,
  setPage,
  getData
}: {
  loading: { users: boolean; organizations: boolean };
  page: number;
  setPage: (value: number) => void;
  getData: () => void;
}) {
  const dispatch = useDispatch();
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const { results, meta, selected } = useSelector((state: RootState) => state.users);
  const { results: organizations } = useSelector((state: RootState) => state.organizations);
  const [modalOpen, setModalOpen] = useState(false);
  const [enabled, setEnabled] = useState(selected?.enabled || false);
  const [editMode, setEditMode] = useState(false);
  const { getUserOrgsData } = useUserContext();
  const [search, setSearch] = useState<string>();
  const queryRef = useRef<HTMLInputElement>();

  const orgOptions: OptionPlain[] = organizations.map((org: Organization) => ({
    value: org.orgId,
    label: org.name
  }));

  const orgOptionsMap: Record<string, string> = orgOptions.reduce(
    (acc: Record<string, string>, obj: OptionPlain) => {
      acc[obj.value] = obj.label;
      return acc;
    },
    {}
  );

  const { register, handleSubmit, formState, reset, control, setValue } = useForm<EditFormProps>({
    defaultValues: {
      name: '',
      enabled: false,
      username: '',
      email: '',
      organizations: [],
      groups: []
    }
  });

  useEffect(() => {
    const selectedEnabled = selected?.enabled || false;
    setEnabled(selectedEnabled);
    setValue('enabled', selectedEnabled);
  }, [selected]);

  const handleEdit = (item: User) => {
    dispatch(setSelected(item));
    reset(item);
    setModalOpen(true);
    setEditMode(true);
  };

  const resetForm = () => {
    dispatch(setSelected({}));
    reset({
      name: '',
      enabled: false,
      username: '',
      email: '',
      organizations: [],
      groups: []
    });
    setEditMode(false);
  };

  const handleAdd = () => {
    resetForm();
    setModalOpen(true);
  };

  const OnEditSubmit: SubmitHandler<EditFormProps> = (values) => {
    values.enabled = enabled as boolean;

    if (!editMode) {
      addUser(values, () => {
        resetForm();
        setModalOpen(false);
        getData();
      });
    } else {
      values.username = selected?.username || '';
      updateUser(values, () => {
        resetForm();
        setModalOpen(false);
        getData();
        getUserOrgsData();
      });
    }
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      className: 'w-80',
      render: (item: User) => (
        <div className="flex flex-row gap-2 items-center">
          <img
            src={`https://gravatar.com/avatar/${item.emailHash}?d=robohash`}
            className="h-12 w-12 ml-2 rounded-full"
          />
          <div className="text-sm text-gray-900 flex flex-col">
            <h4 className="font-medium">{item.name}</h4>
            <h5 className="text-gray">@{item.username}</h5>
          </div>
        </div>
      )
    },
    {
      key: 'enabled',
      title: 'Enabled/Disabled',
      render: (item: User) => (
        <Tag
          size="xs"
          variant="modern"
          className="rounded text-left justify-start inline-block drop-shadow-sm">
          <div className="flex flex-row gap-1 items-center">
            {item.enabled && <span className="h-2 w-2 rounded-full bg-success" />}
            {!item.enabled && <span className="h-2 w-2 rounded-full bg-danger" />}
            {item.enabled ? 'Enabled' : 'Disabled'}
          </div>
        </Tag>
      )
    },
    {
      key: 'email',
      title: 'Email Address',
      render: (item: User) => <span className="text-sm text-gray">{item.email}</span>
    },
    {
      key: 'organizations',
      title: 'Organizations',
      render: (item: User) => (
        <div className="flex flex-wrap gap-1">
          {item.organizations
            .filter((org: string) => org && org !== '')
            .map((org: string) => (
              <Tag size="xs" key={org} variant="warning">
                {orgOptionsMap[org] || org}
              </Tag>
            ))}
        </div>
      )
    },
    {
      key: 'groups',
      title: 'Groups',
      render: (item: User) => (
        <div className="flex flex-wrap gap-1">
          {item.groups &&
            item.groups
              .filter((group: string) => group && group !== '')
              .map((group: string) => (
                <Tag size="xs" key={group} variant="warning">
                  {group}
                </Tag>
              ))}
        </div>
      )
    },
    {
      key: 'id',
      title: '',
      render: (item: User) => (
        <div className="flex flex-row">
          <Button link onClick={() => handleEdit(item)}>
            <EditIcon className="w-6 h-6 stroke-gray" />
          </Button>
        </div>
      )
    }
  ];

  const getResults = () => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      return results.filter(
        (item) =>
          item.name.toLowerCase().includes(lowerCaseSearch) ||
          item.email.toLowerCase().includes(lowerCaseSearch) ||
          item.username.toLowerCase().includes(lowerCaseSearch)
      );
    } else {
      return results;
    }
  };

  const onDownloadCsv = () => {
    const users = results.map((user) => ({
      Name: user.name,
      Enabled: user.enabled ? 'Enabled' : 'Disabled',
      Email: user.email,
      Organizations: user.organizations.join(', '),
      Groups: user.groups.join(', ')
    }));
    downloadFile(toCsvString(users), `users-export-${formatDateTimeForPath()}.csv`);
    toast.success('Users CSV downloaded successfully');
  };

  return (
    <div className="m-4 mt-0 border border-gray-200 rounded">
      <Helmet>
        <title>Above Media | Settings | Users</title>
      </Helmet>
      <div className="flex flex-row items-center">
        <div className="p-4 flex flex-col gap-2 grow">
          <div className="flex flex-row gap-2 items-center">
            <h3 className="text-gray-900 text-lg font-semibold">Users</h3>
            <Tag size="xs" variant="primary" className="bg-white text-primary-700">
              {meta?.total} users
            </Tag>
          </div>
          <p className="text-xs text-gray">Manage users and their data here.</p>
        </div>
        <div className="p-4 flex flex-row gap-4">
          <Button rounded variant="defaultOutlined" onClick={onDownloadCsv}>
            <DownloadIcon className="stroke-gray" />
            Download CSV
          </Button>
          <Button rounded variant="primary" onClick={() => handleAdd()}>
            <PlusIcon className="stroke-white" />
            Add a new user
          </Button>
        </div>
      </div>
      <div className="relative">
        {loading.users && (
          <>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
            <Skeleton />
          </>
        )}
        {!loading.users && (
          <div className="p-4">
            <UsersFilters
              onSearch={(query) => setSearch(query)}
              search=""
              queryRef={queryRef as RefObject<HTMLInputElement>}
            />
          </div>
        )}
        {!loading.users && (
          <Table
            data={getResults() || []}
            columns={columns}
            multipleSelection={false}
            rowId="_id"
            selectedRows={selectedRows}
            onSelectChange={(keys) => setSelectedRows(keys)}
            disabledRows={[]}
            pagination={{
              totalItems: meta?.total || 0,
              itemsPerPage: meta?.pageSize || 30,
              currentPage: page,
              onPageChange: (page) => setPage(page)
            }}
            noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
            noDataLabel="No users were found!"
            noDataDescription="Your search did not match any users. Please try again later."
          />
        )}
        <Modal
          title={editMode ? 'Update User Information' : 'Add a new user'}
          visible={modalOpen}
          setVisible={setModalOpen}
          onClose={() => dispatch(setSelected(undefined))}>
          <form onSubmit={handleSubmit(OnEditSubmit)} className="divide-y divide-gray-100">
            <FormItem help="Fill out user first name and last name" label="Fullname">
              <Input
                rounded
                className="grow"
                defaultValue={selected?.name}
                placeholder="Enter your fullname!"
                error={formState.errors.name?.message}
                {...register('name')}
              />
            </FormItem>
            <FormItem help="Spaces are not allowed" label="Username">
              <Input
                rounded
                className="grow"
                defaultValue={selected?.username}
                placeholder="Enter your username"
                disabled={editMode}
                error={formState.errors.username?.message}
                {...register('username')}
              />
            </FormItem>
            <FormItem label="Email">
              <Input
                rounded
                className="grow"
                defaultValue={selected?.email}
                placeholder="Enter your email address"
                error={formState.errors.email?.message}
                {...register('email')}
              />
            </FormItem>
            <FormItem label="Account Status" help="Enable user login">
              <ToggleGroup
                onValueChange={(value) => setEnabled(value === 'true')}
                defaultValue={selected?.enabled ? 'true' : 'false'}
                type="single">
                <ToggleGroupItem value="true">
                  <span className=" flex flex-row justify-center items-center gap-2">
                    <span className="h-2 w-2 rounded-full bg-success"></span> Enabled
                  </span>
                </ToggleGroupItem>
                <ToggleGroupItem value="false">
                  <span className=" flex flex-row justify-center items-center gap-2">
                    <span className="h-2 w-2 rounded-full bg-danger"></span> Disabled
                  </span>
                </ToggleGroupItem>
              </ToggleGroup>
            </FormItem>
            <FormItem label="Organizations">
              <Controller
                name="organizations"
                control={control}
                render={({ field }) => (
                  <Select
                    className="w-full"
                    options={orgOptions}
                    isMulti={true}
                    placeholder="Assign user to organizations"
                    onChange={(options: any) =>
                      field.onChange(options.map((option: { value: string }) => option.value))
                    }
                    defaultValue={orgOptions.filter((option) =>
                      field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                    )}
                  />
                )}
              />
            </FormItem>
            <FormItem
              label="Groups"
              help="Note: Users need to log in again for groups changes to come into effect">
              <Controller
                name="groups"
                control={control}
                render={({ field }) => (
                  <Select
                    className="w-full"
                    options={cognito_groups}
                    isMulti={true}
                    placeholder="Assign user to groups"
                    onChange={(options: any) =>
                      field.onChange(options.map((option: { value: string }) => option.value))
                    }
                    defaultValue={cognito_groups.filter((option) =>
                      field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                    )}
                  />
                )}
              />
            </FormItem>
            <div className="flex flex-row-reverse p-5 gap-2">
              <Button variant="primary" rounded type="submit" disabled={!formState.isValid}>
                {editMode ? (
                  <>
                    <SaveIcon className="w-5 h-5 stroke-white" /> Save
                  </>
                ) : (
                  <>
                    <PlusIcon className="w-5 h-5 stroke-white" /> Add
                  </>
                )}
              </Button>
              <Button variant="defaultOutlined" rounded onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
}
