import { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import Logo from '../../assets/above-logo.svg?react';
import { User } from '../../features/users/types';
import { getUser, getUserOrganizations } from '../../features/users/usersApi';
import { Organization } from '../../features/organizations/types';
import { flatten, keyBy } from 'lodash';
import { SourceLabels } from '../types';
import { store } from '../../app/store';
import { fetchUserGroups } from '../../features/userGroups/userGroupsSlice';

type UserOrgsHashMap = Record<string, Organization>;
interface UserContext {
  user: User | undefined;
  userOrgs: Organization[];
  sourceCatalogs: string[];
  sourcesOptions: { label: string; value: string }[];
  userOrgsMap: UserOrgsHashMap;
  getUserOrgsData: () => void;
}

const UserContext = createContext<UserContext | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [userOrgs, setUserOrgs] = useState<Organization[] | undefined>();
  const [userOrgsMap, setUserOrgsMap] = useState<UserOrgsHashMap>({});
  const [sourceCatalogs, setSourceCatalogs] = useState<string[]>([]);
  const [sourcesOptions, setSourcesOptions] = useState<{ label: string; value: string }[]>([]);
  const getUserOrgsData = () => {
    getUserOrganizations((userOrganizations) => {
      setUserOrgs(userOrganizations);
      setUserOrgsMap(keyBy(userOrganizations, 'orgId'));
      const sources: string[][] = userOrganizations.map(
        (organization: Organization) => organization.sourceCatalogs
      );
      // remove duplicates
      const sourcesArrayCleaned = Array.from(new Set(flatten(sources)));
      // remove disney for now
      const sourcesArrayFiltered = sourcesArrayCleaned.filter((item: string) => item !== 'DISNEY');
      setSourcesOptions(
        sourcesArrayFiltered.map((item: string) => ({
          value: item,
          label: SourceLabels[item]
        }))
      );
      setSourceCatalogs(sourcesArrayFiltered);
    });
  };
  useEffect(() => {
    fetchAuthSession().then((session) => {
      if (!session.tokens?.idToken?.payload) {
        signInWithRedirect();
      }
    });
    store.dispatch(fetchUserGroups());

    Promise.all([
      getUser((user) => {
        setUser(user);
      }),
      getUserOrgsData()
    ]).then(() => setLoaded(true));
  }, []);

  if (!userOrgs) {
    return null;
  }

  if (!loaded) {
    return (
      <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center justify-center">
        <Logo className="w-40" />
        <span className="text-gray font-thin">Loading...</span>
      </div>
    );
  }

  return (
    <UserContext.Provider
      value={{ user, userOrgs, sourceCatalogs, sourcesOptions, userOrgsMap, getUserOrgsData }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within an UserProvider');
  }
  return context;
};
