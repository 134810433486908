import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import Button from '../../components/ui/Button';
import { Title as OrdersTitle } from '../../features/orders/types';
import { Title as CatalogTitle } from '../../features/catalog/types';

interface Title {
  name: string;
  isEpisode: boolean;
  seriesName?: string;
  seasonNumber?: string;
  episodeNumber?: string;
}

export const TitleLabel = (props: {
  catalogTitle?: CatalogTitle;
  orderTitle?: OrdersTitle;
  seriesOnClick?: () => void;
  seasonOnClick?: () => void;
}) => {
  const title: Title = {
    name: props.catalogTitle?.name || props.orderTitle?.name || '',
    isEpisode: props.catalogTitle?.type === 'EPISODE' || props.orderTitle?.titleType === 'EPISODE',
    seriesName: props.catalogTitle?.series?.name || props.orderTitle?.seriesName || undefined,
    seasonNumber:
      props.orderTitle?.seasonNumber || String(props.catalogTitle?.seasonNumber) || undefined,
    episodeNumber:
      props.orderTitle?.episodeNumber || String(props.catalogTitle?.episodeNumber) || undefined
  };

  return (
    <div className="flex flex-col gap-1">
      <div className="flex flex-row items-center gap-1 text-gray">
        {title.isEpisode &&
          title.seriesName &&
          (props.seriesOnClick ? (
            <Button
              variant="titleLabelArrow"
              onClick={props.seriesOnClick}
              link
              className="underline text-primary p-0 justify-center items-center">
              {title.seriesName} <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </Button>
          ) : (
            <>
              <span className="text-grey p-0 justify-center items-center">{title.seriesName} </span>
              <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </>
          ))}

        {title.isEpisode &&
          title.seasonNumber &&
          (props.seasonOnClick ? (
            <Button
              variant="titleLabelArrow"
              onClick={props.seasonOnClick}
              link
              className="underline text-primary p-0 justify-center items-center">
              S{title.seasonNumber} <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </Button>
          ) : (
            <>
              <span className="p-0 justify-center items-center">S{title.seasonNumber} </span>
              <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
            </>
          ))}

        <span>
          <span className="font-bold">{title.name}</span>
          {title.isEpisode && title.episodeNumber && (
            <span className="text-gray-500"> (E{title.episodeNumber})</span>
          )}
        </span>
      </div>
    </div>
  );
};
