import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import * as PopoverPrimitive from '@radix-ui/react-popover';

/*
Reference:
This component accept the following props:
https://www.radix-ui.com/primitives/docs/components/popover

How to use it:
  <Popover>
    <PopoverTrigger>Popover trigger</PopoverTrigger>
    <PopoverContent>Popover content</PopoverContent>
  </Popover>
*/

export const Popover = PopoverPrimitive.Root;
export const PopoverTrigger = PopoverPrimitive.Trigger;

export const PopoverContent = ({
  children,
  ...props
}: {
  children: ReactNode;
  className?: string;
}) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      className={twMerge([
        'bg-white p-4 rounded-lg drop-shadow text-sm z-10 border-2 border-black text-gray-700 transition-all',
        props.className
      ])}
      sideOffset={5}
      {...props}>
      {children}
      <PopoverPrimitive.Arrow className={'fill-black drop-shadow-sm relative z-0 drop-shadow-xs'} />
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
);

export const PopoverDropdownContent = ({
  children,
  ...props
}: {
  children: ReactNode;
  className?: string;
  align?: 'center' | 'start' | 'end';
}) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      className={twMerge([
        'bg-white rounded-lg drop-shadow text-sm z-10 border-white text-gray-700 transition-all overflow-hidden',
        props.className
      ])}
      alignOffset={10}
      sideOffset={0}
      {...props}>
      {children}
    </PopoverPrimitive.Content>
  </PopoverPrimitive.Portal>
);
