import { generateClient } from 'aws-amplify/api';
import { type GraphQLResult } from '@aws-amplify/api';
import * as queries from '../../graphql/queries/users';
import * as mutations from '../../graphql/mutations/users';
import {
  User,
  GetCurrentUserResponse,
  GetUserOrganizationsResponse,
  GetUsersResponse
} from './types';
import { Organization } from '../organizations/types';
import { pickBy } from 'lodash';
import { Meta } from '../../common/types';
import toast from 'react-hot-toast';
import { print } from 'graphql';

const client = generateClient();

export const getUser = async (cb?: (result: User) => void): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.getUser),
    variables: {}
  })) as GraphQLResult<GetCurrentUserResponse>;
  if (result.data) {
    cb && cb(result.data.getCurrentUser);
  } else {
    cb && cb({} as User);
  }
};

export const getUsers = async (
  variables: {
    page?: number;
    pageSize?: number;
  },
  cb?: (result: { results: User[]; meta: Meta | null } | undefined) => void
): Promise<void> => {
  const params = pickBy(variables, (v) => v !== undefined);
  const result = (await client.graphql({
    query: print(queries.getUsers),
    variables: params
  })) as GraphQLResult<GetUsersResponse>;
  cb && cb(result.data?.listUsers);
};

export const deleteUser = async (username: string, cb?: () => void): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.deleteUser),
      variables: { username }
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error('You cannot delete the user, please check the logs or call the administrator', {
      duration: 5000
    });
  }
};

export const addUser = async (
  params: {
    username: string;
    name: string;
    email: string;
    enabled: boolean;
    organizations: string[];
  },
  cb?: () => void
): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.addUser),
      variables: params
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error('You cannot add the user, please check the logs or call the administrator', {
      duration: 5000
    });
  }
};

export const updateUser = async (
  params: {
    name: string;
    email: string;
    enabled: boolean;
    username: string;
    organizations: string[];
    groups: string[];
  },
  cb?: () => void
): Promise<void> => {
  try {
    await client.graphql({
      query: print(mutations.updateUser),
      variables: params
    });
    cb && cb();
  } catch (error) {
    console.error(error);
    toast.error('You cannot update the user, please check the logs or call the administrator', {
      duration: 5000
    });
  }
};

export const getUserOrganizations = async (
  cb?: (result: Organization[]) => void
): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.listUserOrganizations),
    variables: {}
  })) as GraphQLResult<GetUserOrganizationsResponse>;
  if (result.data) {
    cb && cb(result.data.listUserOrganizations);
  } else {
    cb && cb([]);
  }
};
