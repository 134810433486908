import React from 'react';
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form';

import {
  useLazyFetchUploadUrlQuery,
  usePostTitleCardMutation
} from '../../features/titleCard/titleCardApi';
import TitleCardFields from '../titleCard/TitleCardFields';
import Modal from '../ui/Modal';
import FormItem from '../ui/FormItem';
import Input from '../ui/Input';
import Button from '../ui/Button';
import CancelIcon from '../../assets/icons/x-circle.svg?react';
import UploadIcon from '../../assets/icons/upload.svg?react';
import { TitleCardWithFile } from '../../features/titleCard/types';

export default function UploadDialog(props: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onClose: () => void;
}) {
  const [fetchUploadUrl] = useLazyFetchUploadUrlQuery();
  const [postTitleCard] = usePostTitleCardMutation();

  const methods = useForm<TitleCardWithFile>();
  const onSubmit: SubmitHandler<TitleCardWithFile> = (values) => {
    if (!values.file) return;
    uploadFile(values);
  };
  const uploadFile = (values: TitleCardWithFile) => {
    fetchUploadUrl({ filename: values.file.name }).then((s3PutUrl) => {
      const s3Url = s3PutUrl.data?.s3Url;
      const supplement_key = s3PutUrl.data?.supplement_key;
      if (s3Url && supplement_key) {
        fetch(s3Url, {
          method: 'PUT',
          headers: {
            'Content-Type': values.file.type
          },
          body: values.file
        }).then((response) => {
          const url = new URL(response.url);
          postTitleCard({
            key: supplement_key,
            s3Url: `${url.origin}${url.pathname}`,
            title: values.title || '',
            description: values.description || '',
            studio: values.studio || '',
            expires: values.expires || ''
          });
          props.onClose();
        });
      }
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event?.currentTarget?.files) {
      const file = event.currentTarget.files[0];
      methods.setValue('file', file);
      const fileTitle = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
      methods.setValue('title', fileTitle);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal
          title="Upload title card"
          visible={props.open}
          setVisible={props.setOpen}
          onClose={props.onClose}
          footer={
            <div className="flex flex-row gap-2 justify-end items-end p-4 border-t border-gray-200">
              <Button rounded variant="primaryOutlined" onClick={props.onClose}>
                <CancelIcon className="stroke-primary w-5 h-5" /> Cancel
              </Button>
              <Button rounded variant="primary" type="submit">
                <UploadIcon className="stroke-white w-5 h-5" /> Upload
              </Button>
            </div>
          }>
          <div className="flex flex-col divide-y divide-gray-100">
            <FormItem label="Please select title card to upload">
              <Input
                autoFocus
                rounded
                type="file"
                {...(methods.register('file'), { onChange: handleFileChange })}
              />
            </FormItem>
            <TitleCardFields />
          </div>
        </Modal>
      </form>
    </FormProvider>
  );
}
