import React from 'react';
import { ContentPortalFile } from '../../features/ingestion/types';
import SnowflakeIcon from '../../assets/icons/snowflake.svg?react';
import ArrowCircleRightIcon from '../../assets/icons/arrow-circle-right.svg?react';
import Tag from '../ui/Tag';

interface ChipStatus {
  color: keyof typeof ChipColor;
  quantity: number;
  label: string;
}

enum ChipColor {
  default = 'default',
  primary = 'primary',
  secondary = 'secondary',
  error = 'error',
  info = 'info',
  success = 'success',
  warning = 'warning'
}

enum ColorMap {
  default = '#00496A',
  primary = '#00496A',
  secondary = '#475467',
  error = '#B42318',
  info = '#344054',
  success = '#067647',
  warning = '#B54708'
}

function getFileStatusData(status: string): Omit<ChipStatus, 'quantity'> {
  const statusMap: Record<string, Omit<ChipStatus, 'quantity'>> = {
    NOT_STARTED: { label: 'Not started', color: ChipColor.warning },
    IN_PROGRESS: { label: 'In progress', color: ChipColor.info },
    SUCCESS: { label: 'Success', color: ChipColor.success },
    FAILED: { label: 'Failed', color: ChipColor.error }
  };
  return statusMap[status] ?? { label: status, color: ChipColor.info };
}

export function RestoreStatusTag(props: { file: ContentPortalFile }) {
  const statusData = getFileStatusData(props.file.restoreStatus || '');
  return (
    <Tag size="sm" variant={statusData.color}>
      <SnowflakeIcon stroke={ColorMap[statusData.color]} className={`w-4 h-4`} />
      {statusData.label}
    </Tag>
  );
}
export function MoveStatusTag(props: { file: ContentPortalFile }) {
  const statusData = getFileStatusData(props.file.moveStatus || '');
  return (
    <Tag size="sm" variant={statusData.color}>
      <ArrowCircleRightIcon stroke={ColorMap[statusData.color]} className={`w-4 h-4`} />
      {statusData.label}
    </Tag>
  );
}
