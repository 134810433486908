import { ReactNode, RefObject } from 'react';
import Button from '../ui/Button';
import Input from '../ui/Input';
import SearchIcon from '../../assets/icons/search.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import Select from '../ui/Select';
import { useUserContext } from '../../common/providers/UserProvider';
import { Source } from '../../common/types';

const FilterItem = ({
  title,
  count,
  prefixNode,
  suffixNode,
  onClick,
  disabled = false
}: {
  title: string;
  count?: number;
  prefixNode?: ReactNode;
  suffixNode?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
}) => {
  return (
    <Button rounded className="bg-white" onClick={onClick} disabled={disabled}>
      <div className="flex flex-row gap-2 items-center">
        {prefixNode}
        <span className="font-semibold text-gray-700">{title}</span>
        {typeof count !== 'undefined' && count !== 0 && (
          <span className="text-gray-400">({count})</span>
        )}
        {suffixNode}
      </div>
    </Button>
  );
};

export default function CatalogFilters({
  onSearch,
  onFilter,
  search,
  onSource,
  queryRef,
  ...props
}: {
  onSearch: (query: string) => void;
  onFilter: (value: string) => void;
  onSource: (value: string) => void;
  search?: string;
  filter?: string;
  source?: Source;
  queryRef: RefObject<HTMLInputElement>;
}) {
  const { meta } = useSelector((state: RootState) => state.catalog);
  const { sourcesOptions } = useUserContext();

  const handleSearch = () => {
    if (queryRef && queryRef.current) {
      onSearch && onSearch(queryRef.current.value);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const handleFilter = (value: string) => {
    onFilter && onFilter(value);
  };

  const handleSource = (value: string) => {
    onSource && onSource(value);
  };

  return (
    <div>
      <div className="container mx-auto flex flex-col items-stretch gap-2 border border-primary-15 bg-primary-10 p-3 rounded-lg">
        <div className="flex flex-row gap-2">
          <Select
            className="w-1/6"
            options={sourcesOptions}
            value={sourcesOptions.find((option) => props.source === option.value)}
            onChange={(option: any) => handleSource(option.value)}
          />
          <Input
            rounded
            className="grow"
            defaultValue={search}
            prefixNode={<SearchIcon className="stroke-gray-400" />}
            placeholder="Search by name or EIDR"
            ref={queryRef}
            onKeyDown={handleKeyDown}
          />
          <Button rounded variant="primary" onClick={() => handleSearch()}>
            Search
          </Button>
        </div>
        <div className="flex flex-row gap-4">
          <div className="grow flex flex-row gap-2">
            <FilterItem
              title="All titles"
              count={meta?.all}
              suffixNode={!props.filter && <CheckIcon className="w-5 h-5 stroke-primary" />}
              onClick={() => handleFilter('')}
            />
            <FilterItem
              title="Movies"
              count={meta?.movies}
              // disabled={meta?.movies === 0}
              onClick={() => (props.filter === 'MOVIE' ? handleFilter('') : handleFilter('MOVIE'))}
              suffixNode={
                props.filter === 'MOVIE' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
            />
            <FilterItem
              title="Series"
              count={meta?.series}
              // disabled={meta?.series === 0}
              onClick={() =>
                props.filter === 'SERIES' ? handleFilter('') : handleFilter('SERIES')
              }
              suffixNode={
                props.filter === 'SERIES' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
            />
            <FilterItem
              title="Episodes"
              count={meta?.episodes}
              // disabled={meta?.episodes === 0}
              onClick={() =>
                props.filter === 'EPISODE' ? handleFilter('') : handleFilter('EPISODE')
              }
              suffixNode={
                props.filter === 'EPISODE' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
            />
            <FilterItem
              title="Shorts"
              count={meta?.shorts}
              onClick={() => (props.filter === 'SHORT' ? handleFilter('') : handleFilter('SHORT'))}
              suffixNode={
                props.filter === 'SHORT' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
              // disabled={meta?.shorts === 0}
            />
            <FilterItem
              title="Documentaries"
              count={meta?.documentaries}
              onClick={() =>
                props.filter === 'DOCUMENTARY' ? handleFilter('') : handleFilter('DOCUMENTARY')
              }
              suffixNode={
                props.filter === 'DOCUMENTARY' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
              // disabled={meta?.documentaries === 0}
            />
            <FilterItem
              title="Specials"
              count={meta?.specials}
              onClick={() =>
                props.filter === 'SPECIAL' ? handleFilter('') : handleFilter('SPECIAL')
              }
              suffixNode={
                props.filter === 'SPECIAL' && <CheckIcon className="w-5 h-5 stroke-primary" />
              }
              // disabled={meta?.specials === 0}
            />
          </div>
          <div className="more-filters">
            {/* <FilterItem title="More Filters" prefixNode={<FilterIcon />} /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
