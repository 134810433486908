import { Amplify } from 'aws-amplify';
import { fetchAuthSession, getCurrentUser, signOut } from 'aws-amplify/auth';
import { VITE_APPSYNC_API_URL } from './constants';

const authHost = import.meta.env.VITE_AUTH_HOST;
const userPoolId = import.meta.env.VITE_USER_POOL_ID;
const region = import.meta.env.VITE_REGION;
const clientId = import.meta.env.VITE_USER_POOL_CLIENT_ID;
const currentURL = window.location;
const loginRedirectUrl = `${currentURL.protocol}//${currentURL.host}/auth/signed-in-redirected`;
const logoutRedirectUrl = `${currentURL.protocol}//${currentURL.host}/auth/signed-out-redirected`;
const oauthScope = import.meta.env.VITE_OAUTH_SCOPE;

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: VITE_APPSYNC_API_URL,
      region: region,
      defaultAuthMode: 'userPool'
    }
  },
  Auth: {
    Cognito: {
      userPoolId: userPoolId,
      userPoolClientId: clientId,
      loginWith: {
        oauth: {
          domain: authHost,
          scopes: ['openid', oauthScope],
          redirectSignIn: [loginRedirectUrl],
          redirectSignOut: [logoutRedirectUrl],
          responseType: 'code'
        }
      }
    }
  }
});

export const getAccessToken = async () => {
  const session = await fetchAuthSession();
  return session.tokens?.accessToken.toString() || '';
};

export const getRefreshToken = async () => {
  const clientId = import.meta.env.VITE_USER_POOL_CLIENT_ID;
  const { username } = await getCurrentUser();
  const refreshToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`
  );
  return refreshToken || '';
};

// sign out without invalidating the refresh token
export const lightSignOut = async () => {
  const { username } = await getCurrentUser();
  localStorage.removeItem(`CognitoIdentityServiceProvider.${clientId}.${username}.refreshToken`);
  signOut();
};
