import { useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { Checkbox } from '../../components/ui/Checkbox';
import Logo from '../../assets/above-logo.svg?react';
import { Link } from 'react-router-dom';
import { signIn, rememberDevice, forgetDevice } from 'aws-amplify/auth';
import { useNavigate } from 'react-router-dom';
import { setCookieValue } from '../../common/cookies';
import Alert from '../../components/ui/Alert';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';

async function login({
  username,
  password,
  rememberMe
}: {
  username: string;
  password: string;
  rememberMe: boolean;
}) {
  try {
    const user = await signIn({ username, password });

    if (rememberMe) {
      await loginRememberDevice();
    } else {
      await loginForgetDevice();
    }

    return { user };
  } catch (error) {
    if (error instanceof Error) {
      return { error };
    }
  }
  return { error: Error('An undefined error occurred') };
}

async function loginRememberDevice() {
  try {
    const result = await rememberDevice();
    console.log(result);
  } catch (error) {
    console.log('Error remembering device', error);
  }
}

async function loginForgetDevice() {
  try {
    await forgetDevice();
  } catch (error) {
    console.log('Error forgetting device', error);
  }
}

export default function LoginPage() {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  interface FormProps {
    username: string;
    password: string;
    remember: boolean;
  }
  const { register, handleSubmit, formState, control } = useForm<FormProps>({
    defaultValues: {
      username: '',
      password: '',
      remember: true
    }
  });

  const handleSignIn: SubmitHandler<FormProps> = (values) => {
    setSubmitting(true);
    setError(undefined);
    login({ ...values, rememberMe: true })
      .then((res) => {
        if (res.user) {
          navigate('/');
        } else {
          if (res.error) setError(res.error.message);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };
  return (
    <form onSubmit={handleSubmit(handleSignIn)}>
      <div className="bg-primary-5 h-screen w-screen flex items-center justify-center relative bg-login-bg bg-center bg-no-repeat bg-cover">
        <div className="flex flex-col gap-3 w-5/6 md:w-128">
          <div className="bg-white rounded-lg border border-primary-15 shadow-btn-primary p-12 px-16 flex flex-col gap-4">
            <div className="flex flex-col gap-4 items-center">
              <Logo className="w-52" />
              <h2 className="font-semibold text-3xl text-center text-gray-900 leading-tight">
                One-stop-shop for your media &quot;up in the air&quot;
              </h2>
              <p className="text-gray"> Please enter your credentials.</p>
            </div>
            {error && (
              <Alert rounded variant={'danger'}>
                {error}
              </Alert>
            )}
            <div className="flex flex-col gap-6">
              <Input
                rounded
                label="Username"
                placeholder="Enter your username"
                type="text"
                error={formState.errors.username?.message}
                {...register('username', {
                  required: { value: true, message: 'Username field is required' }
                })}
              />
              <Input
                rounded
                label="Password"
                placeholder="Enter your password"
                type="password"
                error={formState.errors.password?.message}
                {...register('password', {
                  required: { value: true, message: 'Password field is required' }
                })}
              />
              <div className="flex-row flex gap-4 items-center">
                <div className="grow">
                  <Controller
                    control={control}
                    name="remember"
                    render={({ field }) => {
                      return (
                        <Checkbox {...field}>
                          <div className="text-sm font-medium text-gray-700">
                            Remember for 30 days
                          </div>
                        </Checkbox>
                      );
                    }}
                  />
                </div>

                <Link to="/auth/forgot-password">
                  <div className="text-sm text-primary-700 font-semibold">Forgot password?</div>
                </Link>
              </div>
              <Button disabled={submitting} rounded variant={'primary'} type="submit">
                Sign in
              </Button>
            </div>
            <div className="flex flex-row gap-4 text-sm font-light">
              <p className="flex-none text-primary-700">Above © 2023</p>
              <div className="flex flex-row-reverse gap-4 grow">
                <Link to="/terms-and-conditions" className="text-primary hover:underline">
                  Terms & Conditions
                </Link>
                <a href="#" className="text-primary hover:underline">
                  Privacy Policy
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}
