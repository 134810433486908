import { createSlice } from '@reduxjs/toolkit';
import { EncodeSystem, Organization, OrganizationAirline } from './types';

import { Meta } from '../../common/types';

const initialState: {
  results: Organization[];
  meta: Meta;
  selected: Organization | undefined;
  airlines: OrganizationAirline[];
  encodeSystems: EncodeSystem[];
  selectedAirline: OrganizationAirline | undefined;
} = {
  meta: {
    page: 1,
    pageSize: 30,
    total: 0
  },
  results: [],
  selected: undefined,
  selectedAirline: undefined,
  airlines: [],
  encodeSystems: []
};

export const organizationsSlice = createSlice({
  name: 'organizationsSlice',
  initialState,
  reducers: {
    setOrganizations: (state, action) => {
      return {
        ...state,
        ...action.payload
      };
    },
    removeOrganization: (state, action) => {
      return {
        ...state,
        meta: {
          ...state.meta,
          total: state.meta?.total - 1
        },
        results: state.results.filter((item: Organization) => item.orgId !== action.payload)
      };
    },
    setSelected: (state, action) => {
      state.selected = action.payload;
      if (state.selected) {
        state.airlines = action.payload.airlines;
      }
    },
    setSelectedAirline: (state, action) => {
      state.selectedAirline = action.payload;
      if (state.selectedAirline) {
        state.encodeSystems = action.payload.encodeSystems;
      }
    },
    setEncodeSystems: (state, action) => {
      return {
        ...state,
        encodeSystems: action.payload
      };
    },
    setAirlines: (state, action) => {
      return {
        ...state,
        airlines: action.payload
      };
    },
    addAirline: (state, action) => {
      return {
        ...state,
        airlines: [...state.airlines, action.payload]
      };
    },
    updateAirline: (state, action) => {
      return {
        ...state,
        airlines: state.airlines.map((item: OrganizationAirline) => {
          if (item.iataCode === action.payload.iataCode) {
            return action.payload;
          } else {
            return item;
          }
        })
      };
    },
    deleteAirline: (state, action) => {
      return {
        ...state,
        airlines: state.airlines.filter(
          (item: OrganizationAirline) => item.iataCode !== action.payload
        )
      };
    },
    addEncodeSystem: (state, action) => {
      return {
        ...state,
        encodeSystems: [...(state.encodeSystems || []), action.payload]
      };
    },
    deleteEncodeSystem: (state, action) => {
      return {
        ...state,
        encodeSystems: state.encodeSystems.filter(
          (item: EncodeSystem) => item.ifeSystem !== action.payload
        )
      };
    },
    clearEncodeSystems: (state) => {
      return {
        ...state,
        encodeSystems: []
      };
    }
  }
});

export const {
  setOrganizations,
  removeOrganization,
  setSelected,
  setAirlines,
  addAirline,
  deleteAirline,
  addEncodeSystem,
  deleteEncodeSystem,
  clearEncodeSystems,
  updateAirline,
  setSelectedAirline,
  setEncodeSystems
} = organizationsSlice.actions;

export default organizationsSlice;
