import React, { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

/*

How to use it:
<Card>Testing1</Card>
<Card
  header={<h2 className="border-b border-gray-200 p-2">Header</h2>}
  footer={<h2 className="border-t border-gray-200 p-2">Footer</h2>}>
  Testing1
</Card>
*/

export interface CardProps {
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  children?: ReactNode;
}

const Card = ({ header, children, footer, className }: CardProps) => {
  return (
    <div
      className={twMerge(
        'border border-gray-300 hover:border-gray-400 rounded shadow-btn bg-white flex flex-col',
        className
      )}>
      {header}
      <div className="p-4 text-sm text-gray grow">{children}</div>
      {footer}
    </div>
  );
};
export default Card;
