import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { useState } from 'react';
import { useFormik } from 'formik';
import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Alert from '../../components/ui/Alert';
import Logo from '../../assets/above-logo.svg?react';
import ArrowLeftICon from '../../assets/icons/arrow-left.svg?react';

export default function ForgotPasswordPage() {
  const [success, setSuccess] = useState<string | undefined>();
  const [isInitiated, setIsInitiated] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [submitting, setSubmitting] = useState<boolean>(false);

  const forgotPasswordSchema = Yup.object().shape({
    username: Yup.string().required('Username field is required!'),
    newpassword: Yup.string()
      .optional()
      .min(8, 'Password too short')
      .matches(/^(?=.*[a-z])/, 'Must contain at least one lowercase character')
      .matches(/^(?=.*[A-Z])/, 'Must contain at least one uppercase character')
      .matches(/^(?=.*[0-9])/, 'Must contain at least one number')
      .matches(/^(?=.*[!@#%&])/, 'Must contain at least one special character'),
    otp: Yup.string().optional().length(6, 'Verification code should be 6 characters')
  });

  interface FormProps {
    username?: string;
    newpassword?: string;
    otp?: string;
  }

  const formik = useFormik({
    initialValues: {
      username: '',
      newpassword: '',
      otp: ''
    },
    onSubmit: (values: FormProps) => {
      handleResetPassword(values);
    },
    validationSchema: forgotPasswordSchema
  });

  const handleResetPassword = async (values: FormProps) => {
    setSubmitting(true);
    setError(undefined);
    setSuccess(undefined);
    if (isInitiated && values.username && values.otp && values.newpassword) {
      try {
        await confirmResetPassword({
          username: values.username,
          newPassword: values.newpassword,
          confirmationCode: values.otp
        });
        setSuccess('Password resetted successfully.');
        setIsFinished(true);
      } catch (error) {
        console.error('Error password reset:', error);
        setError('Oooops, an error occur while sending your request!');
      }
    } else if (!isInitiated && values.username) {
      try {
        await resetPassword({ username: values.username });
        setSuccess(
          'Password reset initiated successfully, please check your email for verification code.'
        );
        setIsInitiated(true);
      } catch (error) {
        console.error('Error initiating password reset:', error);
        setError('Oooops, an error occur while sending your request!');
      }
    }
    setSubmitting(false);
  };

  return (
    <div className="bg-primary-5 h-screen w-screen flex items-center justify-center relative bg-login-bg bg-center bg-no-repeat bg-cover">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-3 w-5/6 md:w-128">
        <div className="bg-white rounded-lg border border-primary-15 shadow-btn-primary p-12 px-16 flex flex-col gap-4">
          <div className="flex flex-col gap-4 items-center">
            <Logo className="w-52" />
            <h2 className="font-semibold text-3xl text-center text-gray-900 leading-tight">
              One-stop-shop for your media &quot;up in the air&quot;
            </h2>
            {!success && (
              <p className="text-gray">No worries, we’ll send you reset instructions.</p>
            )}
          </div>
          {success && (
            <Alert rounded variant={'success'}>
              {success}
            </Alert>
          )}
          {error && (
            <Alert rounded variant={'danger'}>
              {error}
            </Alert>
          )}
          {!isFinished && (
            <div className="flex flex-col gap-6">
              <Input
                rounded
                label="Username"
                placeholder="Enter your username"
                name="username"
                type="text"
                error={formik.errors.username}
                onChange={formik.handleChange}
                value={formik.values.username}
              />
              {isInitiated && [
                <Input
                  rounded
                  label="New Password"
                  placeholder="Enter your new password"
                  name="newpassword"
                  type="password"
                  error={formik.errors.newpassword}
                  onChange={formik.handleChange}
                  value={formik.values.newpassword}
                  key="newpassword"
                />,
                <Input
                  rounded
                  label="Verification Code"
                  placeholder="Enter your verification code"
                  name="otp"
                  type="text"
                  error={formik.errors.otp}
                  onChange={formik.handleChange}
                  value={formik.values.otp}
                  key="otp"
                />
              ]}

              <Button disabled={submitting} rounded type="submit" variant={'primary'}>
                Reset Password
              </Button>
              <Link to="/auth">
                <Button rounded link variant={'primary'} className="text-gray">
                  <ArrowLeftICon className="stroke-gray" /> Back to login
                </Button>
              </Link>
            </div>
          )}
        </div>
        <div className="flex flex-row gap-4 text-sm font-light">
          <p className="flex-none text-primary-700">Above © 2023</p>
          <div className="flex flex-row-reverse gap-4 grow">
            <Link to="/terms-and-conditions" className="text-primary hover:underline">
              Terms & Conditions
            </Link>
            <a href="#" className="text-primary hover:underline">
              Privacy Policy
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}
