import React, { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';

import {
  useDeleteTitleCardMutation,
  usePatchTitleCardMutation
} from '../../features/titleCard/titleCardApi';
import { BaseTitleCard, TitleCard } from '../../features/titleCard/types';
import TitleCardFields from '../titleCard/TitleCardFields';
import Modal from '../ui/Modal';
import Button from '../ui/Button';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/Popover';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import CancelIcon from '../../assets/icons/x-circle.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';

export default function EditDialog(props: {
  titleCard: TitleCard | null;
  setOpen: (value: boolean) => void;
  onClose: () => void;
}) {
  const [patchTitleCard] = usePatchTitleCardMutation();
  const [deleteTitleCard] = useDeleteTitleCardMutation();

  const methods = useForm<TitleCard>();
  const onSubmit = (values: Partial<TitleCard>) => updateTitleCard(values);

  const updateTitleCard = (values: Partial<BaseTitleCard>) => {
    if (props.titleCard) {
      const body: BaseTitleCard = {
        key: props.titleCard.key,
        title: values?.title || '',
        description: values?.description || '',
        studio: values?.studio || ''
      };

      if (values?.expires) body['expires'] = values.expires;

      patchTitleCard(body).then(() => {
        props.onClose();
      });
    } else {
      alert('Could not get title card details');
    }
  };

  useEffect(() => {
    const titleCard = props.titleCard;
    if (titleCard) {
      methods.setValue('title', titleCard.title);
      methods.setValue('description', titleCard.description);
      methods.setValue('studio', titleCard.studio);
      methods.setValue('expires', titleCard.expires);
    } else {
      methods.reset();
    }
  }, [props.titleCard === null]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Modal
          title="Edit title card"
          visible={!!props.titleCard}
          setVisible={props.setOpen}
          onClose={props.onClose}
          footer={
            <div className="flex flex-row gap-2 p-4 border-t border-gray-200">
              <div className="grow">
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="danger" disabled={props.titleCard?.used} rounded>
                      <DeleteIcon className="fill-white w-5 h-5" /> Delete
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    Are you really sure you would like to remove this title card?
                    <div className="flex flex-row gap-2 justify-end items-end border-t border-gray-200 mt-4 pt-4">
                      <Button size="sm" rounded variant="primaryOutlined" onClick={props.onClose}>
                        <CancelIcon className="stroke-primary w-5 h-5" /> Cancel
                      </Button>
                      <Button
                        size="sm"
                        rounded
                        variant="danger"
                        type="submit"
                        onClick={() => {
                          if (props.titleCard?.key) {
                            deleteTitleCard(props.titleCard?.key).then(() => {
                              props.onClose();
                            });
                          } else {
                            alert('Could not delete title card');
                          }
                        }}>
                        <DeleteIcon className="fill-white w-5 h-5" /> Delete
                      </Button>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              <div className="flex flex-row gap-2">
                <Button variant="primaryOutlined" onClick={props.onClose} rounded>
                  <CancelIcon className="stroke-primary w-5 h-5" /> Cancel
                </Button>
                <Button variant="primary" type="submit" rounded>
                  <SaveIcon className="stroke-white w-5 h-5" /> Save
                </Button>
              </div>
            </div>
          }>
          <div className="flex flex-col divide-y divide-gray-100">
            <TitleCardFields />
            <div className="p-4">
              {props.titleCard?.createdAt && (
                <div className="text-gray-500 text-sm">
                  Created: {new Date(props.titleCard?.createdAt).toLocaleString()}
                </div>
              )}
              {props.titleCard?.modifiedAt && (
                <div className="text-gray-500 text-sm">
                  Modified: {new Date(props.titleCard?.modifiedAt).toLocaleString()}
                </div>
              )}
            </div>
          </div>
        </Modal>
      </form>
    </FormProvider>
  );
}
