import React from 'react';
import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group';

/*
Reference:
This component accept the following props:
https://www.radix-ui.com/primitives/docs/components/toggle-group

How to use it:
  <ToggleGroup defaultValue="test1" type="single">
    <ToggleGroupItem value="test">Mezzanine</ToggleGroupItem>
    <ToggleGroupItem value="test1">Encode</ToggleGroupItem>
    <ToggleGroupItem value="test2">Only License</ToggleGroupItem>
  </ToggleGroup>
*/
export const ToggleGroupItem = (props: ToggleGroupPrimitive.ToggleGroupItemProps) => {
  return (
    <ToggleGroupPrimitive.Item
      className="text-sm transition-colors text-gray-800 font-semibold py-2 px-3.5 data-[state=on]:bg-gray-100"
      {...props}
    />
  );
};

export const ToggleGroup = (
  props: ToggleGroupPrimitive.ToggleGroupSingleProps | ToggleGroupPrimitive.ToggleGroupMultipleProps
) => {
  return (
    <ToggleGroupPrimitive.Root
      className="border border-gray-300 rounded-lg divide-x divide-gray-300 overflow-hidden shadow-btn"
      {...props}
    />
  );
};
