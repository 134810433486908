import React, { forwardRef, Ref } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import CheckIcon from '../../assets/icons/check.svg?react';

/*
Reference:
This component accept the following props:
https://www.radix-ui.com/primitives/docs/components/checkbox

How to use it:
<Checkbox>
  <div className="text-sm font-medium text-gray-700">Remember me</div>
  <div className="text-sm text-gray-600">Save my login details for next time.</div>
</Checkbox>
*/

type CheckboxProps = Omit<CheckboxPrimitive.CheckboxProps, 'value'>;

export const Checkbox = forwardRef(
  (
    props: CheckboxProps & {
      value?: CheckboxPrimitive.CheckedState;
      onChange?: (checked: CheckboxPrimitive.CheckedState) => void;
      disabled?: boolean;
    },
    forwardedRef: Ref<HTMLButtonElement>
  ) => {
    const checkboxClasses = clsx(
      'rounded border border-gray-300  w-5 h-5 flex items-center justify-center',
      'hover:bg-primary-50 hover:border-primary-600',
      'focus:bg-white focus:border-primary-600 focus:shadow-checkbox',
      'active:bg-white active:border-primary-600 active:shadow-checkbox',
      'data-[state=checked]:border-primary-600 data-[state=checked]:bg-primary-50',
      {
        'focus:border-0 hover:border-gray-300 hover:bg-transparent active:border-gray-300 active:bg-transparent':
          props.disabled
      },
      props.className
    );
    return (
      <div className="flex flex-row gap-2">
        <CheckboxPrimitive.Root
          className={twMerge(checkboxClasses)}
          disabled={props.disabled}
          checked={props.value}
          onCheckedChange={props.onChange}
          ref={forwardedRef}>
          <CheckboxPrimitive.Indicator className="stroke-transparent data-[state=checked]:stroke-primary">
            <CheckIcon className="w-4 h-4" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
        <div>{props.children}</div>
      </div>
    );
  }
);
Checkbox.displayName = 'Checkbox';
