import { countBy } from 'lodash';

import { IngestionProcessSummary } from '../../features/ingestion/types';
import { FileStatus, FileStatusLabels } from '../../common/types';
import Tag from '../ui/Tag';
import CheckIcon from '../../assets/icons/check.svg?react';
import SnowflakeIcon from '../../assets/icons/snowflake.svg?react';
import ArrowCircleRightIcon from '../../assets/icons/arrow-circle-right.svg?react';
import XCircleIcon from '../../assets/icons/x-circle.svg?react';
import ClockRefresh from '../../assets/icons/clock-refresh.svg?react';
import { Tooltip } from '../ui/Tooltip';
import Tailwind from '../../../tailwind.config';

const StatusColor: Record<string, string> = {
  NOT_STARTED: Tailwind.theme.extend.colors['gray-500'],
  IN_PROGRESS: Tailwind.theme.extend.colors.primary,
  SUCCESS: Tailwind.theme.extend.colors.success,
  FAILED: Tailwind.theme.extend.colors.danger
};
const getStatusColor = (status: FileStatus): string => StatusColor[status];

export const ProcessStatus = (props: { status: string }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="w-2 h-2">
        <svg viewBox="0 0 6 6">
          <circle cx="3" cy="3" r="3" fill={getStatusColor(props.status)} />
        </svg>
      </div>
      <div className="text-sm">{FileStatusLabels[props.status]}</div>
    </div>
  );
};

export function IngestionStatusWithFileCount(props: { process: IngestionProcessSummary }) {
  const filesWithRestoreStatus = props.process.files.filter(
    (file) => file.restoreStatus && file.archived
  );

  const countRestoreFiles: Partial<Record<FileStatus, number>> = countBy(
    filesWithRestoreStatus,
    ({ restoreStatus }) => restoreStatus
  );

  const filesWithMoveStatus = props.process.files.filter(
    (file) => file.moveStatus && !file.archived
  );

  const countMoveFiles: Partial<Record<FileStatus, number>> = countBy(
    filesWithMoveStatus,
    ({ moveStatus }) => moveStatus
  );

  return (
    <div className="flex items-center gap-2">
      {props.process.processStatus && <ProcessStatus status={props.process.processStatus} />}
      {countRestoreFiles.IN_PROGRESS && (
        <Tooltip
          content={`${countRestoreFiles.IN_PROGRESS} file(s) is being restored`}
          className="bg-black text-white"
          arrowClassName="fill-black">
          <Tag variant="default" size="xs" className="w-12 text-primary-600">
            <SnowflakeIcon stroke={getStatusColor('IN_PROGRESS')} className={`w-4 h-4`} />
            {countRestoreFiles.IN_PROGRESS}
          </Tag>
        </Tooltip>
      )}
      {countMoveFiles.NOT_STARTED && (
        <Tooltip
          content={`${countMoveFiles.NOT_STARTED} file(s) has not started`}
          className="bg-black text-white"
          arrowClassName="fill-black">
          <Tag variant="info" size="xs" className="w-12 text-gray-500">
            <ClockRefresh stroke={getStatusColor('NOT_STARTED')} className="w-4 h-4" />
            {countMoveFiles.NOT_STARTED}
          </Tag>
        </Tooltip>
      )}
      {countMoveFiles.IN_PROGRESS && (
        <Tooltip
          content={`${countMoveFiles.IN_PROGRESS} file(s) is in progress`}
          className="bg-black text-white"
          arrowClassName="fill-black">
          <Tag variant="default" size="xs" className="w-12 text-gray-500">
            <ArrowCircleRightIcon stroke={getStatusColor('IN_PROGRESS')} className={`w-4 h-4`} />
            {countMoveFiles.IN_PROGRESS}
          </Tag>
        </Tooltip>
      )}
      {countMoveFiles.SUCCESS && (
        <Tooltip
          content={`${countMoveFiles.SUCCESS} file(s) has succeeded`}
          className="bg-black text-white"
          arrowClassName="fill-black">
          <Tag variant="success" size="xs" className="w-12">
            <CheckIcon className="w-4 h-4 stroke-success" />
            {countMoveFiles.SUCCESS}
          </Tag>
        </Tooltip>
      )}
      {countMoveFiles.FAILED && (
        <Tooltip
          content={`${countMoveFiles.FAILED} file(s) has failed`}
          className="bg-black text-white"
          arrowClassName="fill-black">
          <Tag variant="error" size="xs" className="w-12 text-rose-500">
            <XCircleIcon stroke={getStatusColor('FAILED')} className="w-4 h-4" />
            {countMoveFiles.FAILED}
          </Tag>
        </Tooltip>
      )}
    </div>
  );
}
