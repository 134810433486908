import { graphql } from 'gql.tada';

export const getTitles = graphql(`
  query getTitles(
    $page: Int = 1
    $pageSize: Int = 30
    $search: String
    $sortOrder: SortOrder
    $sortField: CatalogSortField = id
    $titleType: CatalogItemType = null
    $seriesId: ID = null
    $seasonNumber: Int = null
    $source: SourceCatalog!
    $episodeNumber: Int = null
  ) {
    searchCatalog(
      source: $source
      search: $search
      titleType: $titleType
      seriesId: $seriesId
      seasonNumber: $seasonNumber
      episodeNumber: $episodeNumber
      page: $page
      pageSize: $pageSize
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      results {
        _id
        createdAt
        type
        edits {
          type
          dubbings {
            language
            status
          }
          subtitles {
            language
            status
          }
        }
        series {
          _id
          eidrId
          mpmNumber
          name
          wbtvdId
        }
        name
        sourceCatalog
        studio
        seasonNumbers
        seasonNumber
        eidrId
        seriesId
        episodeNumber
      }
      meta {
        all
        documentaries
        episodes
        lastPage
        movies
        page
        pageSize
        series
        shorts
        specials
        total
      }
    }
  }
`);

export const listCatalogItemsByIds = graphql(`
  query listCatalogItemsByIds(
    $source: SourceCatalog!
    $ids: [ID]!
    $sortField: CatalogSortField = null
    $sortOrder: SortOrder = null
  ) {
    listCatalogItemsByIds(
      ids: $ids
      source: $source
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      eidrId
      wbtvdId
      mpmNumber
      episodeNumber
      seasonNumber
      seriesId
      _id
      createdAt
      description
      edits {
        id
        dubbings {
          language
          status
        }
        subtitles {
          language
          status
        }
        type
      }
      name
      releaseDate
      seasonNumbers
      series {
        _id
        eidrId
        mpmNumber
        name
        wbtvdId
      }
      shortDescription
      sourceCatalog
      studio
      type
    }
  }
`);
