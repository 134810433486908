import * as queries from '../../graphql/queries/catalog';
import { GetCatalogItemsResponse, GetTitlesResponse, SourceCatalog, Title, Meta } from './types';
import { generateClient, type GraphQLResult } from '@aws-amplify/api';
import { print } from 'graphql';

const client = generateClient();

export const searchCatalog = async (
  params: {
    page?: number;
    pageSize?: number;
    search?: string;
    titleType?: string;
    sortOrder?: string;
    sortField?: string;
    seasonNumber?: number;
    seriesId?: string;
    source?: SourceCatalog;
  },
  cb?: (searchCatalog: { results: Title[] | null; meta: Meta | null }) => void
): Promise<void> => {
  if (!params.source) {
    params.source = 'ABOVE' as SourceCatalog;
  }
  const result = (await client.graphql({
    query: print(queries.getTitles),
    variables: params
  })) as GraphQLResult<GetTitlesResponse>;
  cb && result.data && cb(result.data?.searchCatalog);
};

export const getCatalogItems = async (
  ids: string[],
  source: SourceCatalog,
  cb?: (result: Title[]) => void,
  sortField: string = 'episodeNumber',
  sortOrder: string = 'ASC'
): Promise<void> => {
  const result = (await client.graphql({
    query: print(queries.listCatalogItemsByIds),
    variables: {
      ids,
      source,
      sortField,
      sortOrder
    }
  })) as GraphQLResult<GetCatalogItemsResponse>;
  cb && result.data && cb(result.data.listCatalogItemsByIds);
};
