import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../components/ui/Button';
import { RootState } from '../../app/store';
import ArrowRightIcon from '../../assets/icons/arrow-right.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import { OrganizationAirline } from '../../features/organizations/types';
import {
  setAirlines,
  setEncodeSystems,
  setSelected,
  setSelectedAirline
} from '../../features/organizations/organizationsSlice';
import Select from '../../components/ui/Select';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import FormItem from '../../components/ui/FormItem';
import Input from '../../components/ui/Input';
import { ALL_SYSTEMS } from '../../common/target_systems';
import PlusIcon from '../../assets/icons/plus.svg?react';
import {
  addOrganization,
  getOrganization,
  updateOrganization
} from '../../features/organizations/organizationsApi';
import { pick } from 'lodash';
import OrganizationAirlineList from '../../components/organizations/OrganizationAirlinesList';
import { Link, useNavigate, useParams } from 'react-router-dom';
import OrganizationAirlineForm from '../../components/organizations/OrganizationAirlineForm';
import Modal from '../../components/ui/Modal';
import Loader from '../../components/ui/Loader';
import { Helmet } from 'react-helmet-async';

interface OrganizationFormProps {
  name: string;
  orgId: string;
  orderTypes: string[];
  sourceCatalogs: string[];
  airlines: OrganizationAirline[];
  mezzaninePoDestinations: string[];
}

export default function OrganizationPage({
  getData
}: {
  loading: { users: boolean; organizations: boolean };
  page: number;
  setPage: (value: number) => void;
  getData: () => void;
}) {
  const params = useParams();
  const navigate = useNavigate();
  const [createMode, setCreateMode] = useState(true);
  const [airlineModal, setAirlineModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { airlines, selected } = useSelector((state: RootState) => state.organizations);

  const orderTypes = [
    {
      label: 'Mezzanine',
      value: 'MEZZANINE'
    },
    {
      label: 'Encode',
      value: 'ENCODE'
    },
    {
      label: 'License',
      value: 'LICENSE'
    }
  ];
  const sourceCatalogs = [
    {
      label: 'Above',
      value: 'ABOVE'
    },
    {
      label: 'Warner Media',
      value: 'WBTVD'
    },
    {
      label: 'Disney',
      value: 'DISNEY'
    }
  ];

  const aboveMezzanine = ALL_SYSTEMS.find((s) => s.item_id === 'above_mezzanine');
  const mezzaninePoDestinations =
    aboveMezzanine?.formats[0].po_destinations.map((poDestination) => ({
      label: poDestination.display_name,
      value: poDestination.destination_id
    })) || [];

  const { register, handleSubmit, formState, reset, control } = useForm<OrganizationFormProps>({
    defaultValues: {
      name: '',
      orgId: '',
      orderTypes: [],
      sourceCatalogs: [],
      airlines: []
    }
  });

  const onFormSubmit: SubmitHandler<OrganizationFormProps> = (values) => {
    values.airlines = airlines.map((airline) =>
      pick(airline, ['name', 'iataCode', 'territory', 'encodeSystems'])
    );
    setSubmitting(true);
    if (createMode) {
      addOrganization(
        pick(values, [
          'name',
          'orgId',
          'orderTypes',
          'sourceCatalogs',
          'airlines',
          'mezzaninePoDestinations'
        ]),
        () => {
          setSubmitting(false);
          dispatch(setSelected({}));
          dispatch(setAirlines([]));
          toast.success('Your organization was successfully created!');
          navigate('/settings/organizations/');
        }
      );
    } else {
      updateOrganization(
        pick(values, [
          'name',
          'orgId',
          'orderTypes',
          'sourceCatalogs',
          'airlines',
          'mezzaninePoDestinations'
        ]),
        () => {
          setSubmitting(false);
          toast.success('Your organization was successfully updated!');
          navigate('/settings/organizations/');
        }
      );
    }
  };
  useEffect(() => {
    if (params.orgId && params.orgId !== 'create') {
      setLoading(true);
      getOrganization(params.orgId, (data) => {
        dispatch(setSelected(data));
        setLoading(false);
      });
      setCreateMode(false);
    } else {
      dispatch(setSelected(undefined));
      reset();
      setCreateMode(true);
    }
  }, [params.orgId]);

  useEffect(() => {
    if (selected) {
      reset({
        name: selected.name,
        orgId: selected.orgId,
        orderTypes: selected.orderTypes,
        sourceCatalogs: selected.sourceCatalogs,
        airlines: selected.airlines,
        mezzaninePoDestinations: selected.mezzaninePoDestinations || []
      });
    }
  }, [selected]);

  return (
    <div className="m-4 mt-0 border border-gray-200 rounded">
      <Helmet>
        <title>Above Media | Settings | Organizations | Organization Details</title>
      </Helmet>
      <div className="flex flex-row items-center border-b-2 border-gray-100">
        <div className="p-4 flex flex-col gap-2 grow">
          <div className="flex flex-row gap-2 items-center">
            <h3 className="text-gray-900 text-md font-semibold flex flex-row items-center gap-1 ">
              <Link to="/settings/organizations" className="text-primary underline ">
                Organizations
              </Link>
              <ArrowRightIcon className="w-4 h-4 mt-0.5 stroke-gray-400" />
              {params.orgId}
            </h3>
          </div>
          <p className="text-xs text-gray">Manage organization data here.</p>
        </div>
        <div className="p-4 flex flex-row gap-4"></div>
      </div>
      <div className="relative">
        {loading && (
          <>
            <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-[999] flex items-center justify-center">
              <Loader />
            </div>
          </>
        )}

        <div className="">
          <form>
            <div className="divide-y divide-gray-100">
              <FormItem help="Fill out organization name" label="Organization Name">
                <Input
                  rounded
                  className="grow"
                  defaultValue={selected?.name}
                  placeholder="Enter organization name"
                  error={formState.errors.name?.message}
                  {...register('name', {
                    required: { value: true, message: 'Organization name is missing!' }
                  })}
                />
              </FormItem>
              <FormItem help="Spaces are not allowed" label="OrgId">
                <Input
                  rounded
                  className="grow"
                  defaultValue={selected?.orgId}
                  placeholder="Enter organization orgId "
                  error={formState.errors.orgId?.message}
                  {...register('orgId', {
                    required: { value: true, message: 'Organization orgId is missing!' }
                  })}
                />
              </FormItem>
              <FormItem label="Order Types">
                <Controller
                  name="orderTypes"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="w-full"
                      options={orderTypes}
                      isMulti={true}
                      placeholder="Choose order types for the organization"
                      onChange={(options: any) =>
                        field.onChange(options.map((option: { value: string }) => option.value))
                      }
                      defaultValue={orderTypes.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                      value={orderTypes.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                    />
                  )}
                />
              </FormItem>
              <FormItem label="Source Catalogs">
                <Controller
                  name="sourceCatalogs"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="w-full"
                      options={sourceCatalogs}
                      isMulti={true}
                      placeholder="Choose the assigned source catalogs"
                      onChange={(options: any) =>
                        field.onChange(options.map((option: { value: string }) => option.value))
                      }
                      defaultValue={sourceCatalogs.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                      value={sourceCatalogs.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                    />
                  )}
                />
              </FormItem>
              <FormItem label="Mezzanine PO Destinations">
                <Controller
                  name="mezzaninePoDestinations"
                  control={control}
                  render={({ field }) => (
                    <Select
                      className="w-full"
                      options={mezzaninePoDestinations}
                      isMulti={true}
                      placeholder="Choose mezzanine PO destination"
                      onChange={(options: any) =>
                        field.onChange(options.map((option: { value: string }) => option.value))
                      }
                      defaultValue={mezzaninePoDestinations.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                      value={mezzaninePoDestinations.filter((option) =>
                        field.value ? field.value?.indexOf(option.value) !== -1 : field.value === 0
                      )}
                    />
                  )}
                />
              </FormItem>
              <div className="p-4 font-semibold border-t border-gray-500 flex flex-row items-center">
                <div className="grow">Organization Airlines</div>
                <Button
                  rounded
                  variant="primary"
                  type="button"
                  isRouterLink={false}
                  onClick={() => {
                    dispatch(setSelectedAirline(undefined));
                    dispatch(setEncodeSystems([]));
                    setAirlineModal(true);
                  }}
                  size="sm">
                  Add a new airline
                </Button>
              </div>
              <OrganizationAirlineList airlines={airlines} setAirlineModal={setAirlineModal} />
            </div>
            <Modal
              title={createMode ? 'Add a new organization' : 'Update Organization Information'}
              visible={airlineModal}
              setVisible={setAirlineModal}
              onClose={() => setAirlineModal(false)}>
              <OrganizationAirlineForm createMode={createMode} setAirlineModal={setAirlineModal} />
            </Modal>
            <div className="p-4 flex flex-row-reverse">
              {createMode && (
                <Button
                  variant="primary"
                  rounded
                  type="button"
                  onClick={() => handleSubmit(onFormSubmit)()}
                  disabled={!formState.isValid || submitting}>
                  <PlusIcon className="w-5 h-5 stroke-white" /> Add Organization
                </Button>
              )}
              {!createMode && (
                <Button
                  variant="primary"
                  rounded
                  type="button"
                  onClick={() => handleSubmit(onFormSubmit)()}
                  disabled={!formState.isValid || submitting}>
                  <SaveIcon className="w-5 h-5 stroke-white" /> Update Organization
                </Button>
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
