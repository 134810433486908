import React, { ReactNode } from 'react';
import clsx from 'clsx';
import CheckIcon from '../../assets/icons/check.svg?react';
import Card from './Card';

/*
How to use it:
const listItems = [
    {
      icon: <FileCheckIcon className="w-5 h-5 stroke-gray-400" />,
      title: 'Process ID',
      description: '5a60752c02f44030b8e682b4cba77ac1'
    },
    {
      icon: <FileCheckIcon className="w-5 h-5 stroke-gray-400" />,
      title: 'WBTVD title data MPM number',
      description: 'pzykowski'
    }
  ];
<List
  layout="horizontal"
  className="flex-1"
  header={
    <div className="mx-4 mt-4 flex flex-row gap-2 text-gray-900">
      <div className="flex flex-col grow">
        <h2 className="font-bold grow text-sm">List of fields</h2>
        <p className="font-light text-sm">This is list description</p>
      </div>
      <VDotsIcon />
    </div>
  }
  footer={
    <div className="mx-4 mb-4 grid grid-flow-col-dense gap-2">
      <Button size="sm" rounded block variant="primary">
        Create new item
      </Button>
      <Button size="sm" rounded block variant="primaryOutlined">
        Cancel
      </Button>
      <Button size="sm" rounded block variant="primaryOutlined">
        do something else
      </Button>
    </div>
  }
  items={listItems}
/>
<List
  className="flex-1"
  header={
    <div className="mx-4 mt-4 flex flex-row gap-2 text-gray-900">
      <div className="flex flex-col grow">
        <h2 className="font-bold grow text-sm">List of fields</h2>
        <p className="font-light text-sm">This is list description</p>
      </div>
      <VDotsIcon />
    </div>
  }
  items={listItems}
/>
*/

export interface ListItemProps {
  title?: string | ReactNode;
  description?: string | number | ReactNode;
  icon?: ReactNode;
  layout?: 'vertical' | 'horizontal';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  descriptionClassName?: string;
  selected?: boolean;
}

export const ListItem = ({
  title,
  description,
  icon,
  onClick,
  layout = 'vertical',
  disabled,
  className,
  descriptionClassName,
  selected = false
}: ListItemProps) => {
  return (
    <div
      key={title as string}
      onClick={onClick}
      className={clsx(
        'flex flex-row gap-2 p-2 border-b border-gray-200 last:border-0 relative',
        onClick && 'cursor-pointer',
        selected && 'bg-warning-100',
        className
      )}>
      {disabled && (
        <div className="absolute left-0 right-0 top-0 bottom-0 bg-white bg-opacity-50 cursor-not-allowed"></div>
      )}
      {icon && <div className="mt-0.5">{icon}</div>}
      <div
        className={clsx('grow gap-0.5 flex', {
          'flex-col': layout === 'vertical',
          'flex-row w-full': layout === 'horizontal'
        })}>
        <h3
          className={clsx('font-semibold text-sm text-gray-700', {
            grow: layout === 'horizontal'
          })}>
          {title}
        </h3>
        <div className={clsx('font-light', descriptionClassName)}>{description}</div>
        {selected && (
          <div className="absolute right-2 top-2">
            <CheckIcon className="w-5 h-5 stroke-success" />
          </div>
        )}
      </div>
    </div>
  );
};

export interface ListProps {
  header?: ReactNode;
  footer?: ReactNode;
  className?: string;
  children?: ReactNode[];
}

const List = ({ header, children, footer, className }: ListProps) => {
  const renderHeader = (): ReactNode => {
    return (
      header && (
        <h3 className="font-semibold text-gray-900 p-4 border-b border-gray-200">{header}</h3>
      )
    );
  };

  const renderFooter = (): ReactNode => {
    return (
      footer && (
        <div className="p-4 border-t flex flex-row justify-center border-gray-200">{footer}</div>
      )
    );
  };

  return (
    <Card header={renderHeader()} footer={renderFooter()} className={className}>
      <div className="text-sm text-gray grow flex flex-col my-[-4px]">{children}</div>
    </Card>
  );
};

export default List;
