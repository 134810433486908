import { EidrRecord } from '../features/eidr/types';

export const EMPTY_CELL_TEXT = '—';

export const getOptions = (labels: { [key: string]: string }) =>
  Object.entries(labels).map(([key, label]) => ({ value: key, label: label }));

export const eidrRecordMpmNumber = (eidrRecord: EidrRecord | undefined) =>
  (eidrRecord &&
    eidrRecord?.AlternateID.find((a) => a?._domain?.toLowerCase() === 'warnerbros.com/mpm')
      ?.value) ||
  '';

export const downloadFile = (content: string, filename: string) => {
  const anchor = document.createElement('a');
  anchor.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(content));
  anchor.setAttribute('download', filename);
  anchor.click();
  anchor.remove();
};

export const diffValues = (updated: any, reference: any) => {
  const diff = { ...updated };
  Object.keys(diff).map((key: string) => {
    if (diff[key].toString() === reference[key].toString()) delete diff[key];
  });
  return diff;
};
