import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/above-logo.svg?react';

export default function SignedInRedirectedPage() {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 2000);
  }, [navigate]);

  return (
    <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center justify-center">
      <Logo className="w-40" />
      <span className="text-gray font-thin">Signed out</span>
    </div>
  );
}
