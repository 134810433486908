import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function MyDetailsPage() {
  return (
    <div className="m-4 mt-0 border border-gray-200 rounded">
      <Helmet>
        <title>Above Media | Settings | My Details</title>
      </Helmet>
      <div className="flex flex-row items-center">
        <div className="p-4 flex flex-col gap-2 grow">
          <div className="flex flex-row gap-2 items-center">
            <h3 className="text-gray-900 text-lg font-semibold">My Details</h3>
          </div>
          <p className="text-xs text-gray">Manage your details here.</p>
          <div className="my-2">
            <p className="text-xs text-gray">
              Your profile image can be changed on{' '}
              <a className="text-primary" href="http://gravatar.com">
                gravatar.com
              </a>
            </p>
          </div>
        </div>
        <div className="p-4 flex flex-row gap-4"></div>
      </div>
    </div>
  );
}
