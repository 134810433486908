import { useEffect, useState } from 'react';
import Select from '../ui/Select';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import Button from '../ui/Button';
import { System } from '../../features/catalog/types';
import clsx from 'clsx';

export interface SystemPickerProps {
  systems?: System[];
  selectedSystems?: System[];
  onSystemChange?: (selectedSystems: System[]) => void;
  readOnly?: boolean;
  isInvalid?: boolean;
  orderType: string;
  organization: string;
  orderGroupId: string;
}

const SystemPicker = (props: SystemPickerProps) => {
  const [selectedSystems, setSelectedSystems] = useState(props.selectedSystems || []);
  const [selectedOption, setSelectedOption] = useState(null);
  const systems = props.systems;
  const onSystemDelete = (system: System) => {
    const updatedSystems = selectedSystems.filter((obj) => obj.id !== system.id);
    setSelectedSystems(updatedSystems);
    props.onSystemChange && props.onSystemChange(updatedSystems);
  };

  const onSystemSelect = (value: string | unknown) => {
    const system = systems?.find((item) => item.id.toString() === value);
    if (system) {
      const updatedSystems = [...selectedSystems.filter((obj) => obj.id !== system.id), system];
      setSelectedSystems(updatedSystems);
      props.onSystemChange && props.onSystemChange(updatedSystems);
    }
  };

  useEffect(() => {
    setSelectedSystems(props.selectedSystems as System[]);
  }, [props.selectedSystems]);

  useEffect(() => {
    setSelectedOption(null);
  }, [props.orderType, props.orderGroupId, props.organization]);

  return (
    <div className="flex flex-col w-full">
      {!props.readOnly && (
        <Select
          isInvalid={props.isInvalid}
          className="w-3/6"
          placeholder="Select output format"
          onChange={(option: any) => {
            onSystemSelect(option.value);
            setSelectedOption(option);
          }}
          value={selectedOption}
          options={systems
            ?.filter((item) => selectedSystems?.map((item) => item.id).indexOf(item.id) === -1)
            .map(({ poDestination, id, ifeSystem, ifeFormat }) => ({
              label: `${poDestination.charAt(0).toUpperCase() + poDestination.slice(1)} - ${ifeSystem} (${ifeFormat})`,
              value: id.toString()
            }))}
        />
      )}
      {selectedSystems && selectedSystems.length > 0 && (
        <div className="overflow-hidden flex flex-col  divide-y divide-gray-200 bg-white border rounded-lg border-gray-200 mt-2">
          {selectedSystems.map((system) => {
            const destination =
              system.poDestination.charAt(0).toUpperCase() + system.poDestination.slice(1);
            const label = `${destination} - ${system.ifeSystem} (${system.ifeFormat})`;
            return (
              <div
                key={system.id}
                className={clsx(
                  'group flex flex-row gap-4 p-1 even:bg-gray-50 relative items-center',
                  {
                    'p-2': props.readOnly,
                    'pl-3': !props.readOnly
                  }
                )}>
                <div className="grow flex flex-row gap-4">
                  <div className="font-medium text-sm">
                    <span className="">{label}</span>
                  </div>
                </div>
                {!props.readOnly && (
                  <div>
                    <Button link onClick={() => onSystemDelete(system)}>
                      <DeleteIcon />
                    </Button>
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default SystemPicker;
