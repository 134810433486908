export default function Skeleton() {
  return (
    <div role="status" className="p-8 animate-pulse flex flex-col gap-2">
      <div className="h-16 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
      <div className="text-md font-light text-gray-300 dark:text-gray-800 left-0 right-0 items-center flex flex-col justify-center">
        Loading...
      </div>
      <div className="h-7 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
      <div className="h-7 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
      <div className="h-7 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
      <div className="h-7 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
      <div className="h-7 bg-gray-100  dark:bg-gray-300 max-w-full"></div>
    </div>
  );
}
