import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { toast, Toaster, ToastBar } from 'react-hot-toast';
import App from './App';
import Store from './common/providers/Store';
import { UserProvider } from './common/providers/UserProvider';
import { HelmetProvider } from 'react-helmet-async';
import './index.css';
import { NODE_ENV } from './common/constants';

function main() {
  const content = (
    <HelmetProvider>
      <UserProvider>
        <Store>
          <BrowserRouter>
            <Toaster
              toastOptions={{
                duration: 5000,
                success: {
                  className: 'bg-primary text-sm text-white'
                },
                error: {
                  className: 'bg-danger text-sm text-white'
                }
              }}>
              {(t) => (
                <ToastBar toast={t}>
                  {({ icon, message }) => (
                    <>
                      {icon}
                      {message}
                      {t.type !== 'loading' && (
                        <button onClick={() => toast.dismiss(t.id)}>X</button>
                      )}
                    </>
                  )}
                </ToastBar>
              )}
            </Toaster>
            <App />
          </BrowserRouter>
        </Store>
      </UserProvider>
    </HelmetProvider>
  );

  if (NODE_ENV === 'dev') {
    ReactDOM.createRoot(document.getElementById('root')!).render(
      <React.StrictMode>{content}</React.StrictMode>
    );
  } else {
    ReactDOM.createRoot(document.getElementById('root')!).render(content);
  }
}
main();
