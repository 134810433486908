import * as React from "react";
const SvgCartoonNetworkLogo = (props) => /* @__PURE__ */ React.createElement("svg", { viewBox: "0 0 1275.2 763.196", xmlns: "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("g", { id: "g561" }, /* @__PURE__ */ React.createElement("path", { id: "rectW", d: "m637.6 0h637.6l-0 637.441h-637.6z", fill: "#ffffff", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "rectB", d: "m0 0h637.6v637.441h-637.6z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "bigC", d: "m584.886 177.689-158.359 90.5934c-23.2039-37.2454-56.2395-51.4974-87.0196-51.7556-56.0546-0.46964-104.396 44.5316-104.366 101.936-0.0277 59.0513 48.4672 101.885 101.515 102.786 31.7094 0.53425 70.2922-17.1275 89.8952-52.1477l158.35 90.8261c-57.5641 99.2249-156.249 144.026-248.457 143.866-146.216-0.24006-283.764-117.14-283.748-285.087-5e-3 -167.067 138.104-285.995 284.306-285.055 94.8143 0.617149 191.471 45.2234 247.899 144.026z", fill: "#ffffff", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "bigN", d: "m683.266 45.5648h182.083l182.274 227.862v-227.862h181.939l0.01 546.312h-181.94l-182.274-227.48v227.48h-182.13z", style: {
  strokeWidth: 1
} })), /* @__PURE__ */ React.createElement("g", { id: "Cartoon_Network" }, /* @__PURE__ */ React.createElement("path", { id: "C", d: "m70.7604 683.676c10.0156 2.81596 18.4362 9.82605 23.8797 18.6036l-20.9659 11.992c-3.65488-5.09283-9.67367-8.74755-16.1345-7.77888-6.38094 0.61913-11.973 5.47332-13.6504 11.645-2.58642 10.9548 5.25255 20.0509 14.8387 20.5212 5.36238 0.15976 11.0818-2.2069 14.9457-7.91883l20.9659 12.1031c-6.92019 11.4438-19.1742 19.2029-32.5367 20.3315-24.7755 0.76287-46.1351-18.2114-42.7192-47.1027 4.60283-25.0497 27.9093-38.9032 51.3762-32.3949z", strokeWidth: 1 }), /* @__PURE__ */ React.createElement("path", { id: "O1", d: "m397.95 682.177c-7.81793-0.55057-15.859 1.1326-23.0859 5.32364-19.2715 11.1755-25.936 36.2364-14.7616 55.5079 11.1746 19.2731 36.238 25.9392 55.5095 14.7654 18.4378-10.6889 25.1517-34.0383 15.9336-52.9208a12.9766 12.9766 0 0 0-1.16045-2.57096c-3e-3 -6e-3 -5e-3 -0.0112-8e-3 -0.0175a12.9766 12.9766 0 0 0-5e-3 -5e-3 12.9766 12.9766 0 0 0-1.69968-2.34637c-7.18193-10.5379-18.6163-16.8836-30.7244-17.7364zm-1.68008 25.7558c4.67457 0.29934 9.06955 2.88148 11.6572 7.34356a12.9766 12.9766 0 0 0 2e-3 2e-3 12.9766 12.9766 0 0 0 2e-3 5e-3c4.13643 7.13952 1.80361 15.9014-5.33527 20.0398-7.13985 4.13962-15.9043 1.8084-20.0446-5.33145-4.13994-7.13952-1.80808-15.9019 5.33145-20.0414v-2e-3c2.67728-1.55264 5.58219-2.19383 8.38683-2.01418z", color: "#000000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: {
  isolation: "auto",
  mixBlendMode: "normal",
  strokeWidth: 1.594,
  textDecorationColor: "#000000",
  textDecorationLine: "none",
  textDecorationStyle: "solid",
  textIndent: 0,
  textTransform: "none",
  whiteSpace: "normal"
} }), /* @__PURE__ */ React.createElement("path", { id: "O3", d: "m1049.31 682.177c-7.8181-0.55057-15.8591 1.1326-23.0859 5.32364-19.2715 11.1755-25.936 36.2364-14.7616 55.5079 11.1746 19.2731 36.238 25.9392 55.5095 14.7654 18.4394-10.6905 25.1549-34.0462 15.9317-52.9288a12.9766 12.9766 0 0 0-1.1565-2.56315c0-6e-3 0-0.0112-0.01-0.0175a12.9766 12.9766 0 0 0 0-5e-3 12.9766 12.9766 0 0 0-1.7016-2.34828c-7.1819-10.5368-18.6179-16.8821-30.7243-17.7348zm-1.6801 25.7558c4.6744 0.29934 9.0695 2.88148 11.6572 7.34356a12.9766 12.9766 0 0 0 0 2e-3 12.9766 12.9766 0 0 0 0 5e-3c4.1365 7.13952 1.8055 15.9014-5.3333 20.0398-7.1399 4.13962-15.9023 1.8084-20.0414-5.33145-4.1399-7.13952-1.81-15.9019 5.3294-20.0414v-2e-3c2.6774-1.55264 5.5822-2.19383 8.3868-2.01418z", color: "#000000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: {
  isolation: "auto",
  mixBlendMode: "normal",
  strokeWidth: 1.594,
  textDecorationColor: "#000000",
  textDecorationLine: "none",
  textDecorationStyle: "solid",
  textIndent: 0,
  textTransform: "none",
  whiteSpace: "normal"
} }), /* @__PURE__ */ React.createElement("path", { id: "A", d: "m159.503 683.708h-38.8824l-24.3499 77.843h27.3674l3.99998-13.0553h24.9318l3.9249 13.0553h27.3594zm-19.4356 21.476 6.95366 24.1555h-13.9234z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "R1", d: "m191.702 683.708v77.843h25.783v-22.7575h8.40213l11.5549 22.7575h28.6792l-15.0278-25.5327c11.5739-7.42039 15.5369-16.6159 15.0998-25.8563 0.01-13.9536-12.5591-26.0635-26.3026-26.454zm42.936 22.7033c3.32158 0.0437 5.74542 3.27519 5.89143 6.03903 0.085 3.95344-2.92435 6.91812-5.91645 6.98921l-17.1291 0.025v-13.0533z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "T1", d: "m273.315 706.422v-22.7272h77.9992v22.7272h-24.4057v55.1157h-29.1718v-55.1157z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "N1", d: "m531.686 683.708h25.7288l26.3568 32.3694v-32.3694h25.7463v77.843h-25.7463l-26.3568-32.4395v32.4395h-25.7288z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "N2", d: "m662.171 683.708h25.7272l26.3568 32.3694v-32.3694h25.7447v77.843h-25.7447l-26.3568-32.4395v32.4395h-25.7272z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "E", d: "m751.196 683.708h67.9777l-3.8e-4 22.7097h-42.2298v7.97h35.559v18.1844h-35.559v8.19157h42.2298l-4e-4 20.7874h-67.9777z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "T2", d: "m827.772 706.422v-22.7272h77.9992v22.7272h-24.4057v55.1157h-29.1718v-55.1157z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "W", d: "m912.286 683.708h26.1671s6.54385 33.992 6.57159 34.1307l12.8951-31.346 13.1314 31.2759 6.57158-34.0606h25.9264l-14.8237 77.843h-21.5748l-9.23229-21.0137-9.34227 21.0137h-21.2146s-14.7926-76.0864-15.0738-77.843z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "R2", d: "m1094.72 683.708v77.843h25.7814l0.05-22.7575h8.3521l11.5528 22.7575h28.6825l-15.0287-25.5327c11.5738-7.42039 15.5408-16.6159 15.1038-25.8563 0.01-13.9536-12.5632-26.0635-26.3074-26.454zm42.936 22.7033c3.3216 0.0437 5.7429 3.27519 5.889 6.03903 0.085 3.95344-2.9218 6.91812-5.914 6.98921l-17.1291 0.025v-13.0533z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "K", d: "m1177.53 761.543v-77.843h26.0364v25.2187l19.4149-25.2187h33.1983l-30.1282 37.1115 30.1282 40.7315h-33.1983l-19.4149-28.9694v28.9694z", style: {
  strokeWidth: 1
} }), /* @__PURE__ */ React.createElement("path", { id: "O2", d: "m485.938 682.177c-7.81793-0.55057-15.859 1.1326-23.0859 5.32364-19.2715 11.1755-25.936 36.2364-14.7616 55.5079 11.1746 19.2731 36.2348 25.9392 55.5079 14.7654 18.4378-10.6889 25.1533-34.0383 15.9355-52.9208a12.9766 12.9766 0 0 0-1.16043-2.57096c-3e-3 -6e-3 -5e-3 -0.0112-8e-3 -0.0175a12.9766 12.9766 0 0 0-5e-3 -5e-3 12.9766 12.9766 0 0 0-1.7016-2.34829c-7.18208-10.5368-18.6163-16.882-30.7228-17.7348zm-1.68008 25.7558c4.67457 0.29934 9.06747 2.88148 11.6553 7.34356a12.9766 12.9766 0 0 0 2e-3 2e-3 12.9766 12.9766 0 0 0 2e-3 5e-3c4.13643 7.13952 1.80552 15.9014-5.33336 20.0398-7.13985 4.13962-15.9043 1.8084-20.0446-5.33145-4.13994-7.13952-1.80999-15.9019 5.32937-20.0414v-2e-3c2.67729-1.55264 5.58411-2.19383 8.38875-2.01418z", color: "#000000", colorRendering: "auto", imageRendering: "auto", shapeRendering: "auto", style: {
  isolation: "auto",
  mixBlendMode: "normal",
  strokeWidth: 1.594,
  textDecorationColor: "#000000",
  textDecorationLine: "none",
  textDecorationStyle: "solid",
  textIndent: 0,
  textTransform: "none",
  whiteSpace: "normal"
} })));
export default SvgCartoonNetworkLogo;
