import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import OrdersPage from './OrdersPage';
import Header from '../../components/shared/Header';
import OrderDetailPage from './OrderDetailPage';

export default function OrdersRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<OrdersPage />} />
        <Route path=":orderId" element={<OrderDetailPage />} />
        <Route path="*" element={<Navigate to="/orders" replace />} />
      </Routes>
    </>
  );
}
