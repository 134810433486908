import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useLazyFetchTitleCardsQuery } from '../../features/titleCard/titleCardApi';
import EditDialog from '../../components/titleCard/EditDialog';
import UploadDialog from '../../components/titleCard/UploadDialog';
import { TitleCard } from '../../features/titleCard/types';
import Skeleton from '../../components/ui/Skeleton';
import Button from '../../components/ui/Button';
import Card from '../../components/ui/Card';
import PlusIcon from '../../assets/icons/plus.svg?react';
import ShareIcon from '../../assets/icons/share.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import Switch from '../../components/ui/Switch';
import { Helmet } from 'react-helmet-async';

export default function TitleCardPage() {
  const [excludeExpired, setExcludeExpired] = React.useState(true);
  const [titleCards, setTitleCards] = React.useState<TitleCard[]>([]);
  const [fetchTitleCards, { isFetching }] = useLazyFetchTitleCardsQuery();
  const [openUploadTitleCard, setOpenUploadTitleCard] = React.useState(false);
  const [editTitleCard, setEditTitleCard] = React.useState<TitleCard | null>(null);

  useEffect(() => {
    fetchTitleCards(true).then((response) => {
      if (response.data) {
        setTitleCards(response.data.Items);
      }
    });
  }, []);

  const handleExcludeExpired = (checked: boolean) => {
    fetchTitleCards(!checked).then((response) => {
      if (response.data) {
        setTitleCards(response.data.Items);
      }
    });
    setExcludeExpired(!checked);
  };

  const reload = () => {
    fetchTitleCards(excludeExpired).then((response) => {
      if (response.data) {
        setTitleCards(response.data.Items);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>Above Media | Title Cards</title>
      </Helmet>
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8 divide-y divide-gray-100">
        <div className="p-5 flex flex-row gap-4 items-center justify-center">
          <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
            Title cards
          </div>
          <div className="flex-none flex flex-row gap-4 items-center">
            <div className={clsx('flex flex-row gap-4 items-center')}>
              Include expired{' '}
              <Switch checked={!excludeExpired} onCheckedChange={handleExcludeExpired} />
              <Button
                onClick={() => {
                  setOpenUploadTitleCard(true);
                }}
                rounded
                variant="primary"
                className="flex flex-row gap-1">
                <PlusIcon className="stroke-white fill-white" /> Upload new title card
              </Button>
            </div>
            {/* <Button
              rounded
              className="px-1 py-3.5 bg-transparent border-0 focus:shadow-none active:shadow-none">
              <VDotsIcon />
            </Button> */}
          </div>
        </div>
        <div className="relative">
          <UploadDialog
            open={openUploadTitleCard}
            setOpen={setOpenUploadTitleCard}
            onClose={() => {
              setOpenUploadTitleCard(false);
              reload();
            }}
          />
          <EditDialog
            titleCard={editTitleCard}
            setOpen={() => setEditTitleCard(null)}
            onClose={() => {
              setEditTitleCard(null);
              reload();
            }}
          />
          {isFetching && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}
          {!isFetching && (
            <div className="grid grid-cols-4 gap-4 p-4">
              {titleCards.map((titleCard) => (
                <Card
                  key={titleCard.title}
                  header={
                    <h3 className="text-gray-700 font-medium p-2 border-b border-gray-100">
                      {titleCard.title}
                    </h3>
                  }
                  footer={
                    <div className="border-t border-gray-100 flex flex-row">
                      <Button
                        target="_blank"
                        href={titleCard.s3Url}
                        link
                        className="bg-white rounded-bl border-t-0 flex-1 border-r border-gray-100 hover:bg-gray-50">
                        <ShareIcon className="stroke-gray-400 w-5 h-5" /> FullScreen
                      </Button>
                      <Button
                        onClick={() => setEditTitleCard(titleCard)}
                        className="bg-white border-none rounded-br flex-1 hover:bg-gray-50">
                        <EditIcon className="stroke-gray-400 w-5 h-5" /> Detail
                      </Button>
                    </div>
                  }>
                  <img src={titleCard.s3Url} alt={titleCard.title} />
                </Card>
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
