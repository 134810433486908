import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { generateClient } from '@aws-amplify/api';
import { print } from 'graphql';

import { getAccessToken } from '../../common/auth';
import { API_GATEWAY_URL } from '../../common/constants';
import * as mutations from '../../graphql/mutations/auth';

const gqlClient = generateClient();

export const commonApi = createApi({
  reducerPath: 'commonApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_GATEWAY_URL,
    prepareHeaders: async (headers) => {
      const accessToken = await getAccessToken();
      if (accessToken) headers.set('authorization', accessToken);
      return headers;
    }
  }),
  tagTypes: ['IngestionProcess', 'TitleCard'],
  endpoints: () => ({})
});

export const storeRefreshToken = async (refreshToken: string): Promise<void> => {
  await gqlClient.graphql({
    query: print(mutations.storeRefreshToken),
    variables: {
      refreshToken: refreshToken
    }
  });
};
