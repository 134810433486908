import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import RequireUserGroups from '../../common/providers/RequireUserGroups';
import IngestConfirmationPage from './IngestConfirmationPage';
import IngestContentMetadataPage from './IngestContentMetadataPage';
import IngestFileLocationsPage from './IngestFileLocationsPage';
import ProcessDetailsPage from './ProcessDetailsPage';
import ProcessesPage from './ProcessesPage';
import Header from '../../components/shared/Header';

export default function IngestionRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route
          path=""
          element={
            <RequireUserGroups groups={['basic']}>
              <ProcessesPage />
            </RequireUserGroups>
          }
        />
        <Route
          path="process/:processId"
          element={
            <RequireUserGroups groups={['basic']}>
              <ProcessDetailsPage />
            </RequireUserGroups>
          }
        />
        <Route
          path="new/*"
          element={
            <RequireUserGroups groups={['basic']}>
              <Routes>
                <Route path="file-locations" element={<IngestFileLocationsPage />} />
                <Route path="content-metadata" element={<IngestContentMetadataPage />} />
                <Route path="confirmation" element={<IngestConfirmationPage />} />
              </Routes>
            </RequireUserGroups>
          }
        />
        <Route path="*" element={<Navigate to="/ingestion" replace />} />
      </Routes>
    </>
  );
}
