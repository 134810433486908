import React, { forwardRef, Ref } from 'react';
import * as SwitchPrimitive from '@radix-ui/react-switch';
import clsx from 'clsx';

/*
Reference:
This component accept the following props:
https://www.radix-ui.com/primitives/docs/components/switch

How to use it:
  <Switch />
*/
const Switch = forwardRef(
  ({ disabled, ...props }: SwitchPrimitive.SwitchProps, forwardRef: Ref<HTMLButtonElement>) => {
    return (
      <SwitchPrimitive.Root
        className={clsx(
          'w-11 h-6 bg-gray-200 rounded-full border border-gray-200 aria-[checked=true]:bg-primary data-[state=checked]:bg-primary',
          { 'cursor-not-allowed opacity-30': disabled }
        )}
        disabled={disabled}
        {...props}
        ref={forwardRef}>
        <SwitchPrimitive.Thumb className="h-5 w-5 rounded-full bg-white block data-[state=checked]:translate-x-5 transition-all">
          {props.children}
        </SwitchPrimitive.Thumb>
      </SwitchPrimitive.Root>
    );
  }
);
Switch.displayName = 'Switch';

export default Switch;
