import React, { useEffect } from 'react';
import { useLazyFetchProcessesQuery } from '../../features/ingestion/ingestionApi';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { IngestionProcessSummary, LastEvaluatedKeyType } from '../../features/ingestion/types';
import Table from '../../components/ui/Table';
import Button from '../../components/ui/Button';
import Skeleton from '../../components/ui/Skeleton';
import IngestionSearch from '../../components/ingestion/Search';
import ApiErrorAlert from '../../components/shared/ApiErrorAlert';
import PlusIcon from '../../assets/icons/plus.svg?react';
import SearchIcon from '../../assets/icons/search.svg?react';
import RefreshIcon from '../../assets/icons/refresh.svg?react';
import { IngestionStatusWithFileCount } from '../../components/ingestion/IngestionStatus';
import { formatReferentType } from '../../components/shared/Formatting';
import { Helmet } from 'react-helmet-async';

let lastEvaluatedKeyList: LastEvaluatedKeyType[] = [];

export default function ProcessesPage() {
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [totalCount, setTotalCount] = React.useState(-1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [query, setQuery] = React.useState<string | undefined>('');
  const [processesTrigger, { data, error, isFetching, isError }] = useLazyFetchProcessesQuery();
  const ROW_PER_PAGE = 25;

  const clearPages = () => {
    lastEvaluatedKeyList = [];
    setTotalCount(-1);
  };

  useEffect(() => {
    const state = location.state as { refetch: boolean };
    if (state && state.refetch === true) {
      clearPages();
      getPage({ preferCacheValue: false });
    }
  }, [location.state]);

  useEffect(() => {
    const q = searchParams.get('q') || '';
    setQuery(q);
    getPage({ preferCacheValue: true, q });
  }, [location]);

  const onSearch = (query?: string) => {
    setSearchParams(query ? { q: query } : {});
    clearPages();
    getPage({});
  };

  function getPage({ preferCacheValue, q }: { preferCacheValue?: boolean; q?: string }) {
    const lastEvaluatedKey = {
      key_id: searchParams.get('key_id') || '',
      key_at: searchParams.get('key_at') || ''
    };
    const page = Number(searchParams.get('page')) || 1;
    processesTrigger(
      {
        q: q || '',
        ...lastEvaluatedKey
      },
      preferCacheValue
    ).then((result) => {
      const lastEvaluatedKey = result.data?.LastEvaluatedKey;
      if (lastEvaluatedKey) {
        lastEvaluatedKeyList[page] = lastEvaluatedKey;
      } else {
        const count = result.data?.Count;
        if (count) {
          setTotalCount(lastEvaluatedKeyList.length * ROW_PER_PAGE + count);
        }
      }
      setCurrentPage(page);
    });
  }

  const handleChangePage = (page: number) => {
    doChangePage(page);
  };

  function doChangePage(page: number) {
    const value = lastEvaluatedKeyList[page - 1];

    if (!value) page = 1;

    const key_id = value?.id || '';
    const key_at = value?.createdAt || '';

    searchParams.set('page', page.toString());
    searchParams.set('key_id', key_id);
    searchParams.set('key_at', key_at);
    setCurrentPage(page);
    setSearchParams(searchParams);
  }

  const handleRefresh = () => {
    clearPages();
    getPage({ q: query });
  };

  const columns = [
    {
      key: 'createdAt',
      title: 'Created',
      render: (process: IngestionProcessSummary) => new Date(process.createdAt).toLocaleString()
    },
    {
      key: 'title',
      title: 'Title',
      render: (process: IngestionProcessSummary) => (
        <div className="flex flex-row items-center gap-2 text-gray">
          <Link to={`/ingestion/process/${process.id}`} className="text-primary hover:underline">
            <IngestionTitle process={process} />
          </Link>
        </div>
      )
    },
    {
      key: 'type',
      title: 'Type',
      render: (process: IngestionProcessSummary) =>
        process.isEpisode ? 'Episode' : formatReferentType(process.referentType)
    },
    {
      key: 'editVersion',
      title: 'Version'
    },
    {
      key: 'status',
      title: 'Status',
      render: (process: IngestionProcessSummary) => (
        <div className="flex flex-col gap-1">
          <IngestionStatusWithFileCount process={process} />
        </div>
      )
    }
  ];

  return (
    <>
      <Helmet>
        <title>Above Media | Ingestion</title>
      </Helmet>
      <IngestionSearch onSearch={onSearch} query={query} setQuery={setQuery} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8">
        <div className="p-5 flex flex-row gap-4 items-center justify-center">
          <div className="grow gap-2 flex flex-row items-center font-semibold text-lg text-gray-900">
            Ingestion
          </div>
          <div className="flex-none flex flex-row gap-4 items-center">
            <Button
              onClick={handleRefresh}
              rounded
              variant="primaryOutlined"
              className="flex flex-row gap-1">
              <RefreshIcon className="stroke-primary w-5 h-5" /> Refresh
            </Button>
            <Link to="/ingestion/new/file-locations">
              <Button rounded variant="primary" className="flex flex-row gap-1">
                <PlusIcon className="stroke-white fill-white" /> New ingestion
              </Button>
            </Link>
            {/* <Button
              rounded
              className="px-1 py-3.5 bg-transparent border-0 focus:shadow-none active:shadow-none">
              <VDotsIcon />
            </Button> */}
          </div>
        </div>
        <div className="relative">
          {isError && (
            <div className="p-4">
              <ApiErrorAlert error={error} />
            </div>
          )}
          {isFetching && (
            <>
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white bg-opacity-50 z-10"></div>
              <div className="border-t border-gray-100">
                <Skeleton />
              </div>
            </>
          )}
          {!isFetching && (
            <Table
              data={data?.Items || []}
              columns={columns}
              multipleSelection={false}
              rowId="id"
              disabledRows={[]}
              pagination={{
                totalItems: totalCount || 0,
                itemsPerPage: ROW_PER_PAGE,
                currentPage,
                openEndedMode: true,
                labelDisplayedRows: `${(currentPage - 1) * ROW_PER_PAGE + 1} - ${
                  currentPage * ROW_PER_PAGE
                } of many`,
                onPageChange: (page) => handleChangePage(page)
              }}
              noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
              noDataLabel="No ingestion processes were found!"
              noDataDescription="Your search did not match any ingestion process. Please try again later."
            />
          )}
        </div>
      </div>
    </>
  );
}

function IngestionTitle(props: { process: IngestionProcessSummary }) {
  const p = props.process;

  const zeropad = (num: number | string | undefined) => num?.toString().padStart(2, '0') ?? '';

  if (p.isEpisode) {
    return (
      <>
        {p.seriesName}&nbsp;(S{zeropad(p.seasonNumber)}&nbsp;E{zeropad(p.episodeNumber)}):&nbsp;
        {p.titleName}
      </>
    );
  }

  if (!p.titleName) return <i>Missing title</i>;

  return <>{p.titleName}</>;
}
