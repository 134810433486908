import React, { forwardRef, ReactNode, Ref } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { VariantProps, cva } from 'class-variance-authority';

/*
How to use it:
  <Textarea
    rounded
    label="Enter your username?"
    placeholder="Username"
    hint="This is a hint text to help user."
    error="Please enter username"
    suffixNode={<CheckIcon className="w-4 h-4 stroke-black" />}
    prefixNode={<CheckIcon className="w-4 h-4 stroke-black" />}
  ></Textarea>
*/
const inputStyle = cva(
  ['flex flex-row gap-1 items-center justify-center border w-full text-gray-900'],
  {
    variants: {
      variant: {
        default: ['border-gray-300', 'focus:shadow-input focus:border-primary-300']
      },
      inputSize: {
        md: ['text-md py-2.5 px-3']
      }
    },
    defaultVariants: {
      variant: 'default',
      inputSize: 'md'
    }
  }
);

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    VariantProps<typeof inputStyle> {
  rounded?: boolean;
}

const Textarea = forwardRef(
  (
    {
      className,
      variant,
      inputSize,
      disabled,
      rounded,
      prefixNode,
      suffixNode,
      error,
      label,
      hint,
      ...props
    }: TextareaProps & {
      prefixNode?: ReactNode;
      suffixNode?: ReactNode;
      error?: string | ReactNode;
      label?: string | ReactNode;
      hint?: string | ReactNode;
    },
    forwardedRef: Ref<HTMLTextAreaElement>
  ) => {
    const inputClasses = clsx(
      'outline-0 transition',
      {
        'cursor-pointer opacity-100': typeof disabled === 'undefined' || disabled === false,
        'cursor-not-allowed opacity-30': disabled,
        'rounded-lg': rounded,
        'pl-10': prefixNode,
        'pr-10': suffixNode,
        'shadow-input-danger border-danger-500 focus:shadow-input-danger focus:border-danger-500':
          error
      },
      className
    );
    return (
      <div className="w-full">
        {label && <div className="text-gray-700 mb-1.5 text-sm font-medium">{label}</div>}
        <div className="relative">
          {prefixNode && (
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center">{prefixNode}</div>
          )}
          <textarea
            className={twMerge(
              inputStyle({
                variant,
                inputSize,
                className: inputClasses
              })
            )}
            disabled={disabled}
            {...props}
            ref={forwardedRef}
          />
          {suffixNode && (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center">{suffixNode}</div>
          )}
        </div>
        {error && <div className="text-danger-500 mt-1.5 text-sm">{error}</div>}
        {hint && <div className="text-gray-600 mt-1.5 text-sm">{hint}</div>}
      </div>
    );
  }
);
Textarea.displayName = 'Textarea';

export default Textarea;
