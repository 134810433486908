import { commonApi } from '../common/commonApi';
import { PushSubscriptionResponse } from '../titleCard/types';

export const webpushApi = commonApi.injectEndpoints({
  endpoints(builder) {
    return {
      postWebPushSubscribe: builder.mutation<PushSubscriptionResponse, PushSubscription>({
        query: (subscription) => ({
          url: `/notifications/webpush/subscribe`,
          method: 'POST',
          body: subscription
        })
      }),
      postWebPushUnsubscribe: builder.mutation<PushSubscriptionResponse, PushSubscription>({
        query: (subscription) => ({
          url: `/notifications/webpush/unsubscribe`,
          method: 'POST',
          body: subscription
        })
      })
    };
  }
});

export const { usePostWebPushSubscribeMutation, usePostWebPushUnsubscribeMutation } = webpushApi;
