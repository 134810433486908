import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import CatalogPage from './CatalogPage';
import OrderPage from './OrderPage';

export default function CatalogRouter() {
  return (
    <Routes>
      <Route path="" element={<CatalogPage />} />
      <Route path="order/:ids" element={<OrderPage />} />
      <Route path="*" element={<Navigate to="/catalog" replace />} />
    </Routes>
  );
}
