import { EditVersion, FileStatus, ReferentType, Studio } from '../../common/types';
import { AddEidrEpisode, AddEidrSeason } from '../eidr/types';

export interface Tracks {
  duration: string;
  edit_id: number;
  file_location: string;
  language: string;
  track_index: number;
  track_type: string;
}

export enum SourceCatalog {
  WBTVD = 'WBTVD',
  EIDR = 'EIDR',
  MANUAL = 'MANUAL'
}
export interface Title {
  sourceCatalog: SourceCatalog | '';
  mpmNumber: string;
  eidrId: string;
  titleName: string;
  referentType: ReferentType | '';
  studio: Studio | '';
  editVersion: EditVersion | '';
  isEpisode: boolean;
  seriesAboveId?: number | '';
  seriesFolderLocation?: string;
  seriesMpmNumber?: string;
  seriesEidrId?: string;
  seriesName?: string;
  seriesReleaseDate?: string;
  seasonNumber?: number | '';
  episodeNumber?: number | '';
}

// deprecated
export interface WbtvdTitleData {
  mpmNumber: string;
  name: string;
  seasonNumber: number;
  episodeNumber: number;
  topParentName: string;
}

// deprecated
export interface EidrTitleData {
  eidr?: string;
  name?: string;
  referentType?: ReferentType | '';
  seasonNumber?: number;
  episodeNumber?: number;
  mpm: string;
  topParent: {
    eidr: string;
    name: string;
    referentType: ReferentType | '';
    releaseDate: string;
    mpm: string;
  };
}

// deprecated
export interface AboveTitleData {
  id: number;
  eidr: string;
  name: string;
  referentType: ReferentType;
  structuralType: string;
  seasonNumber: number;
  episodeNumber: number;
  version: string;
}

// deprecated
export interface AboveEditData {
  id: number;
  name: string;
  parent_id: number;
  referentType: ReferentType;
  structuralType: string;
  studio: Studio;
  tracks: Tracks[];
  usage: EditVersion;
}

// deprecated
export interface AboveTopParentData {
  id: number;
  eidr: string;
  name: string;
  studio: Studio | '';
  folderLocation: string;
  referentType: ReferentType | '';
}

export interface TitleCandidate extends Title {
  // deprecated
  aboveTopParentData?: AboveTopParentData;
  // deprecated
  eidrTitleData?: EidrTitleData;
  // deprecated
  wbtvdTitleData?: WbtvdTitleData;
}

export interface ContentPortalFile {
  archived?: boolean;
  location: string;
  language?: string;
  targetLocation?: string;
  restoreJobId?: string;
  restoreStatus?: FileStatus;
  moveJobId?: string;
  moveStatus?: FileStatus;
}

export interface AnalyzeFile {
  location: string;
  fileName: string;
  language?: string;
  size?: number;
  included: boolean;
}

export interface User {
  email: string;
  username: string;
  groups: string;
  userPool: string;
}

export interface IngestionProcess extends Title {
  id: string;
  sourceCatalog: SourceCatalog;
  titleAboveId: number | '';
  editAboveId: number | '';
  userInput: string[];
  files: ContentPortalFile[];
  user: User;
  createdAt: string;
  modifiedAt: string;
  processStatus: FileStatus;

  // deprecated legacy attributes
  titleCandidates: TitleCandidate[];
  wbtvdTitleData: WbtvdTitleData;
  eidrTitleData: EidrTitleData;
  aboveTitleData: AboveTitleData;
  aboveEditData?: AboveEditData;
  aboveTopParentData?: AboveTopParentData;
}

export interface LastEvaluatedKeyType {
  id: string;
  createdAt: string;
  label: string;
}

export interface IngestionProcessSummary {
  id: string;
  createdAt: string;
  editVersion: EditVersion;
  titleName: string;
  referentType: ReferentType | '';
  isEpisode: boolean;
  seriesName: string;
  seasonNumber: number | '';
  episodeNumber: number | '';
  files: ContentPortalFile[];
  processStatus: FileStatus;

  // legacy attributes
  aboveTitleData?: AboveTitleData;
  aboveTopParentData?: AboveTopParentData;
}

export interface IngestionProcessesResponse {
  Count: number;
  Items: IngestionProcessSummary[];
  LastEvaluatedKey?: LastEvaluatedKeyType;
  ScannedCount: number;
}

export interface AnalyzeFilesResponse {
  files: AnalyzeFile[];
  titleCandidates: TitleCandidate[];
}

export interface IngestionStart extends Title {
  userInput: string[];
  files: ContentPortalFile[];
  missingEidrRecords?: { season?: AddEidrSeason; episode?: AddEidrEpisode };
}

export interface IngestionStartResponse {
  ingestionProcessId: string;
}

export interface MetadataForm {
  mpmNumber: string;
  eidrId: string;
  titleName: string;
  referentType: ReferentType | '';
  studio: Studio;
  editVersion: EditVersion;
  isEpisode: boolean;
  seriesEidrId: string;
  seriesName: string;
  seriesFolderLocation: string;
  seasonNumber: number | '';
  episodeNumber: number | '';
  files: AnalyzeFile[];
}

export interface EidrFlowMetadataForm {
  // season
  seasonNumber: string;
  seasonEndDate: string;
  seasonReleaseDate: string;

  // episode
  episodeNumber: string;
  episodeTitleName: string;
  episodeOriginalLanguage: string;
  episodeReleaseDate: string;
  episodeCountryOfOrigin: string;
  episodeApproximateLengthHours: string;
  episodeApproximateLengthMinutes: string;
  episodeApproximateLengthSeconds: string;

  // general
  studio: Studio;
  editVersion: EditVersion;
  files: AnalyzeFile[];
}

export interface EidrMissingEpisodeForm {
  episodeNumber: string;
  episodeTitleName: string;
  episodeOriginalLanguage: string;
  episodeReleaseDate: string;
  episodeCountryOfOrigin: string;
  episodeApproximateLengthHours: string;
  episodeApproximateLengthMinutes: string;
  episodeApproximateLengthSeconds: string;
}

export interface EidrMissingSeasonForm {
  seasonNumber: string;
  seasonEndDate: string;
  seasonReleaseDate: string;
}

export type EidrMissingSeasonEpisodeForm = EidrMissingEpisodeForm & EidrMissingSeasonForm;
