import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { fetchAuthSession } from 'aws-amplify/auth';

interface UserGroupsState {
  groups: string[];
}

const initialState: UserGroupsState = {
  groups: []
};

export const fetchUserGroups = createAsyncThunk('userGroups/fetchUserGroups', async () => {
  const user = await fetchAuthSession();
  return (user.tokens?.accessToken.payload['cognito:groups'] as Array<string>) || [];
});

export default createSlice({
  name: 'userGroups',
  initialState,
  extraReducers(builder) {
    builder.addCase(fetchUserGroups.fulfilled, (state, { payload }) => {
      state.groups = payload;
      return state;
    });
  },
  reducers: {}
});

export const selectUserGroups = (state: RootState) => state.userGroups.groups;
