import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import QualityControlPage from './QualityControlPage';
import Header from '../../components/shared/Header';

export default function RepositoryRouter() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="" element={<QualityControlPage />} />
        <Route path="*" element={<Navigate to="/repository" replace />} />
      </Routes>
    </>
  );
}
