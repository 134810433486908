import { useState } from 'react';
import VDotsIcon from '../../assets/icons/v-dots.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import XCircleIcon from '../../assets/icons/x-circle.svg?react';
import SaveIcon from '../../assets/icons/save.svg?react';
import {
  cancelOrderTitleDelivery,
  updateOrderTitleDelivery
} from '../../features/orders/ordersApi';
import {
  OrderTitleDelivery,
  Order,
  UpdateOrderTitleDeliveryInput
} from '../../features/orders/types';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { Popover, PopoverDropdownContent, PopoverTrigger } from '../../components/ui/Popover';
import { audioLanguages, subtitleLanguages } from '../../common/languages';
import FormItem from '../../components/ui/FormItem';
import LanguagePicker, { LanguageOption } from '../../components/order-creation/LanguagePicker';
import { useAppSelector } from '../../app/hooks';
import { selectUserGroups } from '../../features/userGroups/userGroupsSlice';
import Button from '../ui/Button';
import Input from '../ui/Input';
import { diffValues } from '../../common/utils';

export const TitleDeliveryActionSet = (props: { order: Order; delivery: OrderTitleDelivery }) => {
  if (!props.order || !props.delivery) {
    return <></>;
  }
  const currentUserGroups = useAppSelector(selectUserGroups);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dotMenuOpen, setDotMenuOpen] = useState(false);
  const [showEdit, setShowEdit] = useState<boolean>(false);
  const { register, handleSubmit, control, formState, reset } =
    useForm<UpdateOrderTitleDeliveryInput>({
      defaultValues: {
        subtitleLanguages: props.delivery.subtitleLanguages || [],
        audioLanguages: props.delivery.audioLanguages || [],
        deliveryFileName: props.delivery.deliveryFileName || ''
      }
    });

  const onCancelDelivery = (deliveryId: string) => {
    setIsSubmitting(true);
    if (props.order) {
      cancelOrderTitleDelivery(props.order._id, deliveryId, () => {
        setIsSubmitting(false);
      });
    }
  };

  const onSubmit: SubmitHandler<UpdateOrderTitleDeliveryInput> = (values) => {
    updateOrderTitleDelivery(
      props.order._id,
      props.delivery.deliveryId,
      diffValues(values, props.delivery),
      () => {
        setIsSubmitting(false);
        setShowEdit(false);
      }
    );
  };

  return (
    <div>
      <Popover open={dotMenuOpen} onOpenChange={(open) => setDotMenuOpen(open)}>
        <PopoverTrigger asChild>
          <Button variant="default" link rounded>
            <VDotsIcon className="stroke-gray w-5 h-5" />
          </Button>
        </PopoverTrigger>
        <PopoverDropdownContent align="end">
          <div className="divide-y divide-gray-100 min-w-max flex flex-col justify-start">
            <Button
              variant="default"
              size="sm"
              block
              disabled={isSubmitting}
              onClick={() => {
                setShowEdit(true);
                setDotMenuOpen(false);
              }}
              className="border-0 bg-white justify-start outline-none">
              <EditIcon className="w-5 h-5 stroke-gray-500" /> Edit
            </Button>
            {currentUserGroups &&
              currentUserGroups.some((group) =>
                ['above_admin', 'above_ops', 'csp_user', 'csp_admin'].includes(group)
              ) &&
              props.delivery.status !== 'CANCELLED' && (
                <Button
                  variant="default"
                  size="sm"
                  block
                  disabled={isSubmitting}
                  onClick={() => {
                    onCancelDelivery(props.delivery.deliveryId);
                    setDotMenuOpen(false);
                  }}
                  className="border-0 bg-white justify-start outline-none">
                  <XCircleIcon className="w-5 h-5 stroke-danger" /> Cancel
                </Button>
              )}
          </div>
        </PopoverDropdownContent>
      </Popover>
      {showEdit && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Button
              onClick={() => {
                setShowEdit(false);
                reset();
              }}
              className="absolute right-2 top-[-30px] z-50 bg-gray-400 rounded-t px-4 py-1 text-white cursor-pointer text-base hover:bg-gray-500">
              Close
            </Button>
          </div>
          <div className="bg-gray bg-opacity-70 absolute left-0 right-0 top-0 min-h-full z-50 border-gray-400 border rounded-b">
            <div className="p-0">
              <div className="bg-gray-200 border-b border-gray-300 text-gray-900 p-4 text-left font-medium text-lg flex flex-row items-center gap-2 w-full">
                {props.delivery.deliveryId || ''}
              </div>
              <div className="my-0 bg-gray-25 divide-y divide-gray-200">
                <FormItem label="Delivery Filename">
                  <Input
                    rounded
                    className="w-3/6"
                    placeholder="Enter delivery filename"
                    error={(formState.errors.deliveryFileName as FieldError)?.message}
                    {...register('deliveryFileName')}
                  />
                </FormItem>
                <FormItem
                  label="Audio Languages"
                  className="items-start"
                  help="Select & order the languages that you want, check badge indicates that this language is available ">
                  {audioLanguages && (
                    <Controller
                      name="audioLanguages"
                      rules={{
                        required: {
                          value: true,
                          message: 'Audio languages are required!'
                        }
                      }}
                      control={control}
                      render={({ field }) => (
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex flex-row gap-4">
                            <LanguagePicker
                              isInvalid={
                                Object.keys(formState.errors).indexOf('audioLanguages') !== -1
                              }
                              readOnly={false}
                              languages={audioLanguages}
                              selectedLanguages={
                                field.value
                                  ? field.value.map((lang) => {
                                      const targetLang = audioLanguages.find(
                                        (item) => item.value === lang
                                      );
                                      if (targetLang) {
                                        return targetLang;
                                      } else {
                                        return audioLanguages[0];
                                      }
                                    })
                                  : []
                              }
                              onLanguageChange={(languages: LanguageOption[]) =>
                                field.onChange(languages.map((lang) => lang.value))
                              }
                            />
                          </div>
                          {formState.errors.audioLanguages && (
                            <div className="text-danger-500 mt-1.5 text-sm">
                              {(formState.errors.audioLanguages as FieldError).message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  )}
                </FormItem>
                <FormItem
                  label="Subtitle Languages"
                  className="items-start"
                  help="Select & order the languages that you want, check badge indicates that this language is available ">
                  {subtitleLanguages && (
                    <Controller
                      name="subtitleLanguages"
                      control={control}
                      render={({ field }) => (
                        <div className="flex flex-col gap-2 w-full">
                          <div className="flex flex-row gap-4">
                            <LanguagePicker
                              isInvalid={
                                Object.keys(formState.errors).indexOf('subtitleLanguages') !== -1
                              }
                              readOnly={false}
                              languages={subtitleLanguages}
                              selectedLanguages={
                                field.value
                                  ? field.value.map((lang) => {
                                      const targetLang = subtitleLanguages.find(
                                        (item) => item.value === lang
                                      );
                                      if (targetLang) {
                                        return targetLang;
                                      } else {
                                        return subtitleLanguages[0];
                                      }
                                    })
                                  : []
                              }
                              onLanguageChange={(languages: LanguageOption[]) => {
                                field.onChange(languages.map((lang) => lang.value));
                              }}
                            />
                          </div>
                          {formState.errors.subtitleLanguages && (
                            <div className="text-danger-500 mt-1.5 text-sm">
                              {(formState.errors.subtitleLanguages as FieldError).message}
                            </div>
                          )}
                        </div>
                      )}
                    />
                  )}
                </FormItem>
                <div className="flex flex-row-reverse p-4 gap-2">
                  <Button
                    disabled={!formState.dirtyFields || !formState.isValid || isSubmitting}
                    type="submit"
                    rounded
                    variant="primary"
                    className="flex flex-row gap-1">
                    <SaveIcon className="stroke-white" /> Save
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
