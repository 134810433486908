export class RequestQueue {
  private queue: (() => Promise<void>)[] = [];
  private isProcessing: boolean = false;

  async addRequest(request: () => Promise<void>): Promise<void> {
    this.queue.push(request);
    if (!this.isProcessing) {
      await this.processQueue();
    }
  }

  private async processQueue(): Promise<void> {
    this.isProcessing = true;
    while (this.queue.length > 0) {
      const request = this.queue.shift();
      if (request) {
        try {
          await request();
        } catch (error) {
          console.error('Error processing request:', error);
        }
      }
    }
    this.isProcessing = false;
  }
}
