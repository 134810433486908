import React from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
export interface FormItemProps {
  label?: string;
  help?: string;
  children?: ReactNode;
  layout?: 'vertical' | 'horizontal';
  className?: string;
}

const FormItem = ({ label, help, children, layout = 'horizontal', className }: FormItemProps) => {
  return (
    <div
      className={twMerge(
        clsx('flex gap-4 p-4 items-center', {
          'flex-row': layout === 'horizontal',
          'flex-col': layout === 'vertical'
        }),
        className
      )}>
      {label && (
        <div
          className={clsx({
            'w-full': layout === 'vertical',
            'w-2/6': layout === 'horizontal'
          })}>
          <h3 className="text-gray-600 font-semibold text-base">{label}</h3>
          <div className="text-gray-500 text-sm">{help}</div>
        </div>
      )}
      <div
        className={clsx('w-4/6', {
          'w-full': layout === 'vertical'
        })}>
        <div className="flex flex-row gap-4">{children}</div>
      </div>
    </div>
  );
};
export default FormItem;
