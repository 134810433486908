import { ReactNode } from 'react';
import { EidrSearchResult, EidrRecord, EidrReferentType } from '../../features/eidr/types';
import List, { ListItem } from '../ui/List';
import MovieIcon from '../../assets/icons/movie.svg?react';
import SeriesIcon from '../../assets/icons/series.svg?react';
import YoutubeIcon from '../../assets/icons/youtube.svg?react';
import EpisodeIcon from '../../assets/icons/episode.svg?react';
import ListIcon from '../../assets/icons/list.svg?react';

export function ListEidr(props: {
  header?: ReactNode;
  footer?: ReactNode;
  data: EidrSearchResult;
  selectedEidr: EidrRecord | undefined;
  handleEidrClick: (eidr: EidrRecord) => void;
}) {
  const sortOrder = (a: EidrRecord, b: EidrRecord) => {
    if (a.ReferentType === EidrReferentType.SEASON && b.ReferentType === EidrReferentType.SEASON)
      return (
        parseInt(a.ExtraObjectMetadata?.SeasonInfo?.SequenceNumber) -
        parseInt(b.ExtraObjectMetadata?.SeasonInfo?.SequenceNumber)
      );

    if (a.ReferentType === EidrReferentType.TV && b.ReferentType === EidrReferentType.TV)
      return (
        parseInt(a.ExtraObjectMetadata?.EpisodeInfo?.SequenceInfo?.DistributionNumber?.value) -
        parseInt(b.ExtraObjectMetadata?.EpisodeInfo?.SequenceInfo?.DistributionNumber?.value)
      );

    return 0;
  };

  const getTitle = (eidr: EidrRecord) => `${eidr.ResourceName.value} (${eidr.ID})`;

  const getSecondary = (eidr: EidrRecord) => {
    let result = `${eidr.ReferentType}, Release date: ${eidr.ReleaseDate}`;
    const mpm = eidr.AlternateID?.find((id) => id._domain?.toLowerCase() === 'warnerbros.com/mpm');
    if (mpm) {
      result += `, MPM: ${mpm.value}`;
    }
    const seasonNumber = eidr.ExtraObjectMetadata?.SeasonInfo?.SequenceNumber;
    if (seasonNumber) {
      result += `, Season: ${seasonNumber}`;
    }
    const episodeNumber =
      eidr.ExtraObjectMetadata?.EpisodeInfo?.SequenceInfo?.DistributionNumber?.value;
    if (episodeNumber) {
      result += `, Episode: ${episodeNumber}`;
    }
    return result;
  };

  const getEidrIcon = (referentType: EidrReferentType): ReactNode => {
    switch (referentType) {
      case EidrReferentType.SERIES:
        return <SeriesIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.SEASON:
        return <ListIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.TV:
        return <EpisodeIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.MOVIE:
        return <MovieIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.SHORT:
        return <EpisodeIcon className="w-6 h-6 stroke-gray-300" />;
      case EidrReferentType.WEB:
        return <YoutubeIcon className="w-6 h-6 stroke-gray-300" />;
    }
  };

  return (
    <div className="px-4">
      <List header={props.header} footer={props.footer}>
        {props.data?.results
          .filter((eidr) => !props.selectedEidr || eidr.ID === props.selectedEidr?.ID)
          .sort(sortOrder)
          .map((eidr, index) => (
            <ListItem
              key={`${eidr.ID}-${index}`}
              onClick={() => props.handleEidrClick(eidr)}
              title={getTitle(eidr)}
              description={getSecondary(eidr)}
              icon={getEidrIcon(eidr.ReferentType)}
            />
          ))}
      </List>
    </div>
  );
}
