import Button from '../../components/ui/Button';
import Input from '../../components/ui/Input';
import Logo from '../../assets/above-logo.svg?react';
import { Link } from 'react-router-dom';

export default function RegistrationPage() {
  return (
    <div className="bg-primary-5 h-screen w-screen flex items-center justify-center relative bg-login-bg bg-center bg-no-repeat bg-cover">
      <div className="flex flex-col gap-3 w-5/6 md:w-128">
        <div className="bg-white rounded-lg border border-primary-15 shadow-btn-primary p-12 px-16 flex flex-col gap-4">
          <div className="flex flex-col gap-4 items-center">
            <Logo className="w-52" />
            <h2 className="font-semibold text-3xl text-center text-gray-900 leading-tight">
              One-stop-shop for your media &quot;up in the air&quot;
            </h2>
            <p className="text-gray">No worries, we’ll send you reset instructions.</p>
          </div>
          <div className="flex flex-col gap-6">
            <Input rounded label="Name" placeholder="Enter your name" />
            <Input rounded label="Username" placeholder="Enter your username" />
            <Input rounded label="Email" placeholder="Enter your email" />
            <Input
              rounded
              label="Password"
              type="password"
              placeholder="Enter your password"
              hint={
                <div className="px-1 text-sm">
                  <div>Must be at least 8 characters.</div>
                  <div>Must contain at least one special character.</div>
                  <div>Must contain at least one uppercase character.</div>
                </div>
              }
            />
            <Input
              rounded
              label="Confirm Password"
              type="password"
              placeholder="Please confirm your password"
            />
            <Button rounded variant={'primary'}>
              Get started
            </Button>
            <Link to="/auth" className="flex flex-row gap-1 justify-center">
              Already have an account?
              <Button rounded link className="text-primary text-sm font-semibold p-0 ">
                Login
              </Button>
            </Link>
          </div>
        </div>
        <div className="flex flex-row gap-4 text-sm font-light">
          <p className="flex-none text-primary-700">Above © 2023</p>
          <div className="flex flex-row-reverse gap-4 grow">
            <Link to="/terms-and-conditions" className="text-primary hover:underline">
              Terms & Conditions
            </Link>
            <a href="#" className="text-primary hover:underline">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
