import { ReactNode, useEffect } from 'react';
import CloseIcon from '../../assets/icons/close.svg?react';

export interface ModalProps {
  title?: ReactNode;
  footer?: ReactNode;
  onClose?: () => void;
  visible: boolean;
  setVisible: (value: boolean) => void;
  children: ReactNode;
}

const Modal = ({ title, footer, onClose, visible, setVisible, children }: ModalProps) => {
  // Use useEffect to add an event listener to the document
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === 'Enter') {
        // Close the modal when the Escape key is pressed
        onClose && onClose();
      }
    }

    // Prevent scolling
    document.body.style.overflow = 'hidden';
    document.addEventListener('keydown', onKeyDown);

    // Clear things up when unmounting this component
    return () => {
      document.body.style.overflow = 'visible';
      document.removeEventListener('keydown', onKeyDown);
    };
  });
  if (visible) {
    return (
      <div className="fixed overflow-auto left-0 bottom-0 top-0 right-0 z-10 bg-black bg-opacity-70">
        <div className="bg-white rounded max-w-full mx-auto my-14 w-4/5 lg:w-2/5 relative">
          <div className="flex flex-row border-b border-gray-100 p-4">
            <div className="grow font-semibold text-gray-900">{title}</div>
            <div className="flex-none cursor-pointer" onClick={() => setVisible(false)}>
              <CloseIcon className="h-6 w-6 stroke-gray-400" />
              <span className="sr-only">Close</span>
            </div>
          </div>
          {children}
          {footer}
        </div>
      </div>
    );
  }

  return null;
};

export default Modal;
