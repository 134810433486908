import { DerivedOrderDeliveryStatus } from '../../common/types';
import { Order, UpdateOrderStatusOutput } from './types';

export const getDerivedOrderDeliveryStatus = (order: Order): DerivedOrderDeliveryStatus => {
  let deliveryStatus: DerivedOrderDeliveryStatus =
    order.deliveryStatus as DerivedOrderDeliveryStatus;
  order.titles.some((title) =>
    title.deliveries.some((delivery) => {
      if (delivery.status === 'MISSING_CONTENT') {
        deliveryStatus = 'MISSING_CONTENT';
        return true;
      }
      if (['ENCODE_FAILED', 'DELIVERY_FAILED'].includes(delivery.status)) {
        deliveryStatus = 'FAILED';
        return true;
      }
    })
  );
  return deliveryStatus;
};

export const createUpdatedOrder = (
  changedOrderDiff: UpdateOrderStatusOutput,
  currentOrder: Order
) => ({
  ...currentOrder,
  licenseStatus: changedOrderDiff?.licenseStatus || currentOrder.licenseStatus,
  deliveryStatus: changedOrderDiff?.deliveryStatus || currentOrder.deliveryStatus,
  externalId: changedOrderDiff?.externalId || currentOrder.externalId,
  brand: changedOrderDiff?.brand || currentOrder.brand,
  titles: changedOrderDiff.titles.map((t, i) => {
    const deliveries = t.deliveries.map((d, j) => ({
      ...currentOrder.titles[i].deliveries[j],
      status: d?.status || currentOrder.titles[i].deliveries[j]?.status,
      audioLanguages: d?.audioLanguages || currentOrder.titles[i].deliveries[j]?.audioLanguages,
      subtitleLanguages:
        d?.subtitleLanguages || currentOrder.titles[i].deliveries[j]?.subtitleLanguages,
      deliveryFileName:
        d?.deliveryFileName || currentOrder.titles[i].deliveries[j]?.deliveryFileName
    }));
    return {
      ...currentOrder.titles[i],
      deliveries: deliveries.length > 0 ? deliveries : currentOrder.titles[i].deliveries
    };
  })
});
