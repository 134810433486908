import { ReactNode, useEffect, useState } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { getCurrentUser } from 'aws-amplify/auth';
import { GraphqlSubscriptionMessage } from '@aws-amplify/api-graphql';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { addOrderMessage, onAddOrderMessageSubscription } from '../../features/orders/ordersApi';
import { History, Order } from '../../features/orders/types';
import Input from '../ui/Input';
import Button from '../ui/Button';
import SendIcon from '../../assets/icons/send.svg?react';

export const OrderHistory = (props: { currentOrder: Order | undefined }) => {
  const { orderId } = useParams();
  const [orderHistory, setOrderHistory] = useState<History[]>([]);
  const [username, setUsername] = useState<string>('');
  const { register, handleSubmit, formState, reset } = useForm<{ message: string }>({
    defaultValues: {
      message: ''
    }
  });

  useEffect(() => {
    let orderMessageSubscription: { unsubscribe: () => void };
    if (orderId) {
      getCurrentUser().then((user) => {
        setUsername(user.username);
      });
      if (props.currentOrder?.history) setOrderHistory(props.currentOrder.history);
      // receive updates to further messages for this order
      orderMessageSubscription = onAddOrderMessageSubscription(
        orderId,
        (result: GraphqlSubscriptionMessage<{ onAddOrderMessage: History }>) => {
          const newMessage = result.data?.onAddOrderMessage;
          if (newMessage) {
            // make sure the message is not already in the list
            if (orderHistory.findIndex((m) => m.timestamp === newMessage.timestamp) === -1) {
              setOrderHistory((prev) => [...prev, newMessage]);
            }
          }
        }
      );
    }

    return () => {
      // unsubscribe from updates to further messages for this order
      if (orderMessageSubscription) {
        orderMessageSubscription.unsubscribe();
      }
    };
  }, [orderId]);

  const onSubmit: SubmitHandler<{ message: string }> = (values) => {
    if (orderId) {
      addOrderMessage(orderId, values.message);
      reset();
    }
  };

  return (
    <div className="relative bg-gray-25 border-l border-gray-200 p-8 pb-0 col-span-2">
      <div className="flex flex-col gap-4 col-span-3 ">
        {orderHistory.map((item, index) => (
          <Message
            key={index}
            author={
              item.action === 'NEW_USER_MESSAGE'
                ? `${item.userFullName || item.user} (${item.userOrganizationName || item.userOrganization})`
                : `SYSTEM (${item.userFullName || item.user})`
            }
            variant={
              item.action === 'NEW_USER_MESSAGE'
                ? item.user === username
                  ? 'user'
                  : 'default'
                : 'system'
            }
            timestamps={`${formatDistanceToNow(item.timestamp)} ago`}
            timestampsRaw={item.timestamp}
            message={item.message}
          />
        ))}
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="bottom-0 left-0 right-0 py-4">
          <div className="border-t border-gray-200 py-4 flex flex-row gap-2">
            <Input
              rounded
              className="grow"
              {...register('message', { required: true })}
              error={formState.errors.message?.message}
            />
            <Button variant="primary" type="submit" rounded>
              <SendIcon className="h-5 w-5 stroke-white" />
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

const Message = ({
  author,
  message,
  timestamps,
  timestampsRaw,
  variant = 'default'
}: {
  author?: string;
  message?: string | ReactNode;
  timestamps?: string;
  timestampsRaw: string;
  variant?: 'default' | 'system' | 'user';
}) => {
  const dateObject = new Date(timestampsRaw);
  return (
    <div className={clsx('flex flex-col gap-1 w-11/12', variant === 'user' && 'self-end')}>
      <div className="flex flex-row text-xs">
        <span className="grow text-gray-800">{author}</span>
        <span className="text-gray cursor-pointer" title={dateObject.toString()}>
          {timestamps}
        </span>
      </div>
      <div
        className={clsx('text-sm rounded-tl-lg rounded-bl-lg rounded-br-lg p-4', {
          'bg-gray-100 text-gray-900 rounded-tr-lg': variant === 'default',
          'bg-success-10 text-success-900 rounded-tr-lg': variant === 'system',
          'bg-primary text-white rounded-tr-lg': variant === 'user'
        })}>
        {message}
      </div>
    </div>
  );
};
