import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import Logo from '../../assets/above-logo.svg?react';
import { useNavigate } from 'react-router-dom';
import { storeRefreshToken } from '../../features/common/commonApi';
import { getRefreshToken } from '../../common/auth';

export default function SignedInRedirectedPage() {
  const [initialized, setInitialized] = useState(false);
  const navigate = useNavigate();

  const storeUserRefreshToken = async (): Promise<void> => {
    const refreshToken = await getRefreshToken();

    if (refreshToken) {
      try {
        await storeRefreshToken(refreshToken);
        setInitialized(true);
      } catch (error) {
        toast.error('Authentication failed');
        throw error;
      }
    } else {
      toast.error('Authentication failed');
      throw new Error('Invalid refresh token');
    }
  };

  useEffect(() => {
    storeUserRefreshToken().then(() => navigate('/'));
  }, [navigate]);

  if (!initialized) {
    return (
      <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center justify-center">
        <Logo className="w-40" />
        <span className="text-gray font-thin">Initializing...</span>
      </div>
    );
  }

  return (
    <div className="absolute left-0 top-0 right-0 bottom-0 flex flex-col items-center justify-center">
      <Logo className="w-40" />
      <span className="text-gray font-thin">Signed in</span>
    </div>
  );
}
