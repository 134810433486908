import { useEffect, useState } from 'react';
import { mapValues, omit, pick } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearAll, selectIngest } from '../../features/ingestion/ingestSlice';
import ApiErrorAlert from '../../components/shared/ApiErrorAlert';
import { usePostStartIngestionMutation } from '../../features/ingestion/ingestionApi';
import toast from 'react-hot-toast';
import { formatReferentType } from '../../components/shared/Formatting';
import { EditVersion, EditVersionLabels, StudioLabels } from '../../common/types';
import { AnalyzeFile, IngestionStart } from '../../features/ingestion/types';
import Breadcrumbs, { BreadcrumbsItem } from '../../components/ui/Breadcrumbs';
import Button from '../../components/ui/Button';
import Loader from '../../components/ui/Loader';
import HomeIcon from '../../assets/icons/home.svg?react';
import CheckIcon from '../../assets/icons/check.svg?react';
import FileLocationsIcon from '../../assets/icons/file-locations.svg?react';
import FindTitleIcon from '../../assets/icons/find-title.svg?react';
import ConfigureAssetsIcon from '../../assets/icons/configure-assets.svg?react';
import { Helmet } from 'react-helmet-async';
import { Collapse, CollapseSection } from '../../components/ui/Collapse';
import FormItem from '../../components/ui/FormItem';
import FileList from '../../components/ingestion/FileList';
import { Controller, FieldError, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import Select from '../../components/ui/Select';
import { getOptions } from '../../common/utils';
import { isEidrId } from '../../common/validation';

export default function IngestConfirmationPage() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ingest = useAppSelector(selectIngest);
  const [waiting, setWaiting] = useState<boolean>(false);

  useEffect(() => {
    // go back to step one if the redux state is empty
    if (ingest.paths.length === 0) navigate('/ingestion/new/file-locations', { replace: true });
  }, []);

  const candidate = ingest.selectedCandidate;

  const [startIngestionTrigger, { isError: isIngestionError, error: ingestionError }] =
    usePostStartIngestionMutation();

  const startIngestionProcess = (values: {
    files: AnalyzeFile[];
    editVersion: string;
    studio: string;
  }) => {
    const filesData = [];
    for (const index in ingest.detectedFiles) {
      const file = ingest.detectedFiles[index];
      if (filesField[index] && filesField[index].included !== false) {
        if (filesField[index] && filesField[index].language) {
          filesData.push({
            location: file.location,
            language: filesField[index].language
          });
        } else if (file.language) {
          filesData.push({
            location: file.location,
            language: file.language
          });
        } else {
          filesData.push({
            location: file.location,
            language: ''
          });
        }
      }
    }

    const body: IngestionStart = {
      ...candidate,
      sourceCatalog: candidate.sourceCatalog || ingest.sourceCatalog, // 'WBTVD' || 'EIDR' || 'MANUAL',
      studio: values.studio || ingest.studio || candidate.studio,
      editVersion: (values.editVersion || ingest.editVersion) as EditVersion,
      userInput: ingest.paths,
      files: filesData
    };

    if (ingest.addEidrEpisode) {
      body.missingEidrRecords = { episode: ingest.addEidrEpisode };
      if (ingest.addEidrSeason) {
        body.missingEidrRecords.season = ingest.addEidrSeason;
      }
    }

    let cleanedBody = pick(body, [
      'sourceCatalog',
      'mpmNumber',
      'eidrId',
      'titleName',
      'referentType',
      'studio',
      'editVersion',
      'isEpisode',
      'seriesAboveId',
      'seriesFolderLocation',
      'seriesMpmNumber',
      'seriesEidrId',
      'seriesName',
      'seriesReleaseDate',
      'seasonNumber',
      'episodeNumber',
      'userInput',
      'files',
      'missingEidrRecords'
    ]);

    if (!body.isEpisode) {
      cleanedBody = omit(cleanedBody, [
        'seriesAboveId',
        'seriesFolderLocation',
        'seriesMpmNumber',
        'seriesEidrId',
        'seriesName',
        'seriesReleaseDate',
        'seasonNumber',
        'episodeNumber'
      ]);
      if (!isEidrId(cleanedBody?.eidrId?.toString() || '')) {
        toast.error('EIDR ID is required');
        return;
      }
    }
    const nullifiedBody = mapValues(cleanedBody, (v) => (v === '' ? null : v));

    if (body.isEpisode && !isEidrId(nullifiedBody?.seriesEidrId?.toString() || '')) {
      toast.error('Series EIDR ID is required');
      return;
    }

    setWaiting(true);
    startIngestionTrigger(nullifiedBody as IngestionStart).then((response) => {
      if ('error' in response) {
        setWaiting(false);
        return;
      }
      dispatch(clearAll());
      setTimeout(() => {
        toast.success('Successfully created an ingestion process');
        navigate('/ingestion', { state: { refetch: true } });
        setWaiting(false);
      }, 1000);
    });
  };

  const breadcrumbItems: BreadcrumbsItem[] = [
    {
      link: '/',
      icon: <HomeIcon />
    },
    {
      title: 'Ingestion',
      link: '/ingestion'
    },
    {
      title: 'New'
    }
  ];

  const { handleSubmit, formState, control, setValue } = useForm({
    defaultValues: {
      files: [],
      editVersion: candidate.editVersion,
      studio: candidate.studio
    }
  });

  const filesField: AnalyzeFile[] = useWatch({ name: 'files', control });

  const onSubmit: SubmitHandler<{ files: AnalyzeFile[]; editVersion: string; studio: string }> = (
    values
  ) => {
    startIngestionProcess(values);
  };

  const editVersionOptions = getOptions(EditVersionLabels);
  const studioOptions = getOptions(StudioLabels);

  useEffect(() => {
    if (candidate) {
      setValue('studio', candidate.studio);
      setValue('editVersion', candidate.editVersion);
    }
  }, [candidate]);

  return (
    <>
      <Helmet>
        <title>Above Media | Ingestion | Confirmation</title>
      </Helmet>
      <Breadcrumbs items={breadcrumbItems} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl mb-8">
        <div className="px-5 py-5 flex flex-row gap-4 items-center justify-center border-b border-gray-200">
          <div className="grow gap-1 flex flex-col font-semibold text-lg text-gray-900">
            New Ingestion Process
            <p className="text-gray font-normal text-sm">Fill out your ingestion details here.</p>
          </div>
        </div>
        <div className="relative flex flex-col divide-y divide-gray-100">
          <Collapse activeSection="confirmation" urlMode={true}>
            <CollapseSection
              icon={<FileLocationsIcon className="w-10 h-10" />}
              id="file-locations"
              title="Validate File Locations">
              <span />
            </CollapseSection>
            <CollapseSection
              icon={<FindTitleIcon className="w-10 h-10" />}
              id="content-metadata"
              title="Find Title"
              active={true}>
              <span />
            </CollapseSection>
            <CollapseSection
              icon={<ConfigureAssetsIcon className="w-10 h-10" />}
              id="confirmation"
              title="Configure Assets">
              {isIngestionError && (
                <div className="p-4">
                  <ApiErrorAlert error={ingestionError} />
                </div>
              )}
              {waiting && (
                <div className="absolute z-50 bg-white bg-opacity-60 top-0 bottom-0 left-0 right-0 flex items-center justify-center">
                  <Loader />
                </div>
              )}
              <div className="grid grid-cols-1">
                <div className="flex flex-col gap-4">
                  <form className="flex-col divide-y divide-gray-100 border-b border-gray-100">
                    {candidate.mpmNumber && (
                      <FormItem label="MPM number">
                        <span className="text-gray-500 font-light">{candidate.mpmNumber}</span>
                      </FormItem>
                    )}
                    <FormItem label={formatReferentType(candidate.referentType || '') + ' EIDR'}>
                      <span className="text-gray-500 font-light">{candidate.eidrId}</span>
                    </FormItem>
                    {candidate.isEpisode && (
                      <div className="flex-col divide-y divide-gray-100">
                        <FormItem label="Series name">
                          <span className="text-gray-500 font-light">{candidate.seriesName}</span>
                        </FormItem>
                        <FormItem label="Series EIDR">
                          <span className="text-gray-500 font-light">{candidate.seriesEidrId}</span>
                        </FormItem>
                        <FormItem label="Series MPM">
                          <span className="text-gray-500 font-light">
                            {candidate.seriesMpmNumber}
                          </span>
                        </FormItem>
                        <FormItem label="Series release date">
                          <span className="text-gray-500 font-light">
                            {candidate.seriesReleaseDate}
                          </span>
                        </FormItem>
                        <FormItem label="Series folder location">
                          <span className="text-gray-500 font-light">
                            {candidate.seriesFolderLocation}
                          </span>
                        </FormItem>
                        <FormItem label="Season">
                          <span className="text-gray-500 font-light">{candidate.seasonNumber}</span>
                        </FormItem>
                        <FormItem label="Episode">
                          <span className="text-gray-500 font-light">
                            {candidate.episodeNumber}
                          </span>
                        </FormItem>
                      </div>
                    )}
                    <FormItem label="Title name">
                      <span className="text-gray-500 font-light">{candidate.titleName}</span>
                    </FormItem>
                    <FormItem label="Source catalog">
                      <span className="text-gray-500 font-light">{candidate.sourceCatalog}</span>
                    </FormItem>
                    <FormItem label="Referent type">
                      <span className="text-gray-500 font-light">
                        {formatReferentType(candidate.referentType)}
                      </span>
                    </FormItem>
                    <FormItem label="Studio">
                      <Controller
                        name="studio"
                        rules={{
                          required: {
                            value: true,
                            message: 'Studio is required!'
                          }
                        }}
                        control={control}
                        render={({ field }) => (
                          <div className="flex flex-col gap-2 w-full">
                            <div className="flex flex-row gap-4">
                              <Select
                                isInvalid={Object.keys(formState.errors).indexOf('studio') !== -1}
                                className="w-3/6"
                                options={studioOptions}
                                placeholder="Please select studio"
                                onChange={(option: any) => field.onChange(option.value)}
                                value={studioOptions.find((option) => field.value === option.value)}
                                readOnly={false}
                              />
                            </div>
                            {formState.errors.studio && (
                              <div className="text-danger-500 mt-1.5 text-sm">
                                {(formState.errors.studio as FieldError).message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormItem>
                    <FormItem label="Edit Version">
                      <Controller
                        name="editVersion"
                        rules={{
                          required: {
                            value: true,
                            message: 'Edit Version is required!'
                          }
                        }}
                        control={control}
                        render={({ field }) => (
                          <div className="flex flex-col gap-2 w-full">
                            <div className="flex flex-row gap-4">
                              <Select
                                isInvalid={
                                  Object.keys(formState.errors).indexOf('editVersion') !== -1
                                }
                                className="w-3/6"
                                options={editVersionOptions}
                                placeholder="Please select edit version"
                                onChange={(option: any) => field.onChange(option.value)}
                                value={editVersionOptions.find(
                                  (option) => field.value === option.value
                                )}
                                readOnly={false}
                              />
                            </div>
                            {formState.errors.editVersion && (
                              <div className="text-danger-500 mt-1.5 text-sm">
                                {(formState.errors.editVersion as FieldError).message}
                              </div>
                            )}
                          </div>
                        )}
                      />
                    </FormItem>
                  </form>
                </div>
              </div>
              <FileList control={control} formState={formState} />
              <div className="flex flex-row-reverse p-5 gap-2">
                <Button
                  variant="primary"
                  size="lg"
                  rounded
                  onClick={() => handleSubmit(onSubmit)()}>
                  <CheckIcon className="w-6 h-6 stroke-white" /> Ingest
                </Button>
              </div>
            </CollapseSection>
          </Collapse>
        </div>
      </div>
    </>
  );
}
