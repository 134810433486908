import { Studio } from '../../common/types';

export enum EidrStructuralType {
  ABSTRACTION = 'Abstraction',
  PERFORMANCE = 'Performance',
  DIGITAL = 'Digital'
}

export enum EidrReferentType {
  SERIES = 'Series',
  SEASON = 'Season',
  TV = 'TV',
  MOVIE = 'Movie',
  WEB = 'Web',
  SHORT = 'Short',
  COMPILATION = 'Compilation',
  SUPPLEMENTAL = 'Supplemental'
}

export enum EidrRecordMode {
  AUDIO_VISUAL = 'AudioVisual',
  VISUAL = 'Visual',
  AUDIO = 'Audio',
  OTHER = 'Other'
}

export enum TitleClass {
  RELEASE = 'release',
  ABBREVIATED = 'abbreviated',
  WORKING = 'working',
  ACRONYM = 'acronym',
  FAN = 'fan-based',
  INTERNAL = 'internal',
  SERIES_NUMERIC = 'series numeric',
  SERIES_DATE = 'series date',
  REGIONAL = 'regional',
  BROADCAST = 'broadcast',
  AKA = 'AKA',
  FKA = 'FKA',
  TRANSLITERATED = 'transliterated',
  OTHER = 'other'
}

export interface AlternateResourceName {
  value: string;
  _lang: string;
}

export enum OriginalLanguageType {
  PRIMARY = 'primary',
  NARRATION = 'narration',
  DIALOGCENTRIC = 'dialogcentric',
  COMMENTARY = 'commentary',
  NORMAL = 'normal',
  SDH = 'SDH',
  LARGE = 'large',
  FORCED = 'forced',
  EASYREADER = 'easyreader',
  OTHER = 'other'
}

export enum OriginalLanguageMode {
  AUDIO = 'Audio',
  VISUAL = 'Visual'
}

export interface OriginalLanguage {
  value: string;
  _mode: OriginalLanguageMode;
}

export interface AssociatedOrg {
  _idType: string;
  _organizationID: string;
  _role: string;
  DisplayName: string;
  AlternateName: string[];
}

export enum AlternateIdRelation {
  IsSameAs = 'IsSameAs',
  IsEntirelyContainedBy = 'IsEntirelyContainedBy',
  ContainsAllOf = 'ContainsAllOf',
  IsPartiallyContainedBy = 'IsPartiallyContainedBy',
  ContainsPartOf = 'ContainsPartOf',
  IsDerivedFrom = 'IsDerivedFrom',
  IsSourceOf = 'IsSourceOf',
  HasCueSheet = 'HasCueSheet',
  HasSoundRecording = 'HasSoundRecording',
  DepictsEvent = 'DepictsEvent',
  Duplicate = 'Duplicate',
  Other = 'Other'
}

export enum AlternateIdType {
  AdID = 'Ad-ID',
  AFI = 'AFI',
  AMG = 'AMG',
  Baseline = 'Baseline',
  BFI = 'BFI',
  cIDF = 'cIDF',
  CRID = 'CRID',
  DOI = 'DOI',
  EAN = 'EAN',
  GRid = 'GRid',
  GTIN = 'GTIN',
  IMDB = 'IMDB',
  ISAN = 'ISAN',
  ISRC = 'ISRC',
  ISTC = 'ISTC',
  IVA = 'IVA',
  Lumiere = 'Lumiere',
  MUZE = 'MUZE',
  Proprietary = 'Proprietary',
  X = 'SMPTE - UMID',
  TRIB = 'TRIB',
  TVG = 'TVG',
  UPC = 'UPC',
  URI = 'URI',
  UUID = 'UUID',
  URN = 'URN'
}

export interface AlternateId {
  value: string;
  _domain: string;
  _relation: 'IsSameAs';
  _type: 'Proprietary';
}

export interface EidrRecord {
  AboveRecord: {
    eidr: string;
    folder_location: string;
    id: number;
    referent_type: string;
    studio: Studio;
  };
  ID: string;
  StructuralType: EidrStructuralType;
  Mode: EidrRecordMode;
  ReferentType: EidrReferentType;
  ResourceName: {
    value: string;
    _lang: string;
    _titleClass: TitleClass;
  };
  AlternateResourceName: AlternateResourceName[];
  OriginalLanguage: OriginalLanguage[];
  AssociatedOrg: AssociatedOrg[];
  ReleaseDate: string;
  CountryOfOrigin: string[];
  Status: string;
  ApproximateLength: string;
  AlternateID: AlternateId[];
  Administrators: {
    Registrant: string;
  };
  Credits: {
    Actor: {
      DisplayName: string;
    }[];
  };
  ExtraObjectMetadata: {
    SeriesInfo: {
      NumberRequired: 'true' | 'false';
      DateRequired: 'true' | 'false';
      OriginalTitleRequired: 'true' | 'false';
    };
    SeasonInfo: {
      SequenceNumber: string;
    };
    EpisodeInfo: {
      Parent: string;
      SequenceInfo: {
        DistributionNumber: {
          value: string;
        };
        HouseSequence: {
          value: string;
        };
      };
    };
  };
}

export interface EidrSearchResult {
  currentSize: number;
  pageNumber: number;
  pageSize: number;
  results: EidrRecord[];
  totalMatches: number;
}

export interface AddEidrSeason {
  seriesEidrId: string;
  endDate: string;
  releaseDate: string;
  sequenceNumber: number | '';
}

export interface AddEidrEpisode {
  seasonEidrId?: string;
  title: string;
  originalLanguage: string;
  releaseDate: string;
  countryOfOrigin: string;
  sequenceNumber: number | '';
  approximateLength: string;
}
