import { ReactNode } from 'react';
import ChevronTopIcon from '../../assets/icons/chevron-up.svg?react';
import Button from './Button';

export interface BreadcrumbsItem {
  title?: string;
  link?: string;
  icon?: ReactNode;
}

export interface BreadcrumbsProps {
  items: BreadcrumbsItem[];
}

const Breadcrumbs = ({ items }: BreadcrumbsProps) => {
  const crumbs: BreadcrumbsItem[] = items
    .map((item, index) => [
      item,
      { icon: <ChevronTopIcon key={index} className="stroke-gray-300 w-5 mt-1 h-5 rotate-90" /> }
    ])
    .flat()
    .slice(0, -1);

  return (
    <div className="flex flex-row items-center container mx-auto text-sm">
      {crumbs.map((item: BreadcrumbsItem, index: number) => {
        if (item.link) {
          return (
            <Button className="px-1" link href={item.link} key={index}>
              {item.icon} {item.title}
            </Button>
          );
        } else {
          return (
            <span key={index} className="text-primary font-bold ml-0.5">
              {item.icon} {item.title}
            </span>
          );
        }
      })}
    </div>
  );
};
export default Breadcrumbs;
