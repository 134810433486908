import React from 'react';
import { useFormContext } from 'react-hook-form';

import { TitleCard } from '../../features/titleCard/types';
import FormItem from '../ui/FormItem';
import Input from '../ui/Input';
import Textarea from '../ui/Textarea';
import { Dropdown } from '../ui/Dropdown';
import { getOptions } from '../../common/utils';
import { StudioLabels } from '../../common/types';

export default function TitleCardFields() {
  const { register } = useFormContext<TitleCard>();
  return (
    <>
      <FormItem label="Title">
        <Input {...register('title')} rounded className="w-full" />
      </FormItem>
      <FormItem label="Description (optional)">
        <Textarea {...register('description')} rounded rows={3} className="w-full" />
      </FormItem>
      <FormItem label="Studio (optional)">
        <Dropdown
          {...register('studio')}
          className={'w-full'}
          options={[{ value: '', label: 'None' }].concat(getOptions(StudioLabels))}
        />
      </FormItem>
      <FormItem label="Expires">
        <Input {...register('expires')} type="date" rounded className="w-full" />
      </FormItem>
    </>
  );
}
