import { Navigate, Route, Routes } from 'react-router-dom';
import LoginPage from './LoginPage';
import ForgotPasswordPage from './ForgotPasswordPage';
import RegistrationPage from './RegistrationPage';
import SignedInRedirectedPage from './SignedInRedirectedPage';
import SignedOutRedirectedPage from './SignedOutRedirectedPage';

export default function AuthRouter() {
  return (
    <Routes>
      <Route path="" element={<LoginPage />} />
      <Route path="forgot-password" element={<ForgotPasswordPage />} />
      <Route path="registration" element={<RegistrationPage />} />
      <Route path="signed-in-redirected" element={<SignedInRedirectedPage />} />
      <Route path="signed-out-redirected" element={<SignedOutRedirectedPage />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}
