import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SidebarState {
  open: boolean;
}

const initialState: SidebarState = {
  open: false
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    toggleOpen: (state) => {
      state.open = !state.open;
    },
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload;
    }
  }
});

export const { toggleOpen, setOpen } = sidebarSlice.actions;

export default sidebarSlice;
