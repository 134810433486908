import React, { useEffect } from 'react';
import Button from '../ui/Button';
import PlusIcon from '../../assets/icons/plus.svg?react';
import Modal from '../ui/Modal';
import FormItem from '../ui/FormItem';
import Input from '../ui/Input';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import Select from '../ui/Select';
import { Organization, OrganizationAirline } from '../../features/organizations/types';
import { CreateOrderGroup } from '../../features/orders/types';
import { addOrderGroup } from '../../features/orders/ordersApi';

export interface CycleFormProps {
  airline: string;
  cycle: string;
}

export default function AddCycleFormModal({
  onCycleCreate,
  selectedOrg,
  modalOpen,
  setModalOpen
}: {
  onCycleCreate: (newOrderGroupId: string) => void;
  selectedOrg: Organization | undefined;
  modalOpen: boolean;
  setModalOpen: (value: boolean) => void;
}) {
  const { register, handleSubmit, formState, reset, control } = useForm<CycleFormProps>({
    defaultValues: {
      airline: '',
      cycle: ''
    }
  });

  const airlinesOptions = selectedOrg?.airlines.map((airline: OrganizationAirline) => ({
    value: airline.iataCode,
    label: airline.name
  }));

  const onFormSubmit: SubmitHandler<CycleFormProps> = (values) => {
    const selectedAirline = selectedOrg?.airlines.find(
      (airline) => airline.iataCode === values.airline
    );

    if (selectedAirline && selectedOrg) {
      const newCycle: CreateOrderGroup = {
        orgId: selectedOrg?.orgId,
        title: values.cycle,
        clientId: values.airline,
        clientText: selectedAirline.name
      };

      addOrderGroup(newCycle, (result) => {
        onCycleCreate(result?.createOrderGroup || '');
      });
    }
    setModalOpen(false);
  };

  useEffect(() => {
    reset({
      airline: '',
      cycle: ''
    });
  }, []);

  return (
    <Modal
      title={
        <>
          Create Order Group
          <div className="text-gray text-sm font-light">
            This entity will help grouping your orders. Traditionally this is a cycle.
          </div>
        </>
      }
      visible={modalOpen}
      setVisible={setModalOpen}
      onClose={() => setModalOpen(false)}>
      <form className="divide-y divide-gray-100">
        <FormItem label="Client*" layout="vertical">
          <Controller
            name="airline"
            rules={{
              required: {
                value: true,
                message: 'Client is required!'
              }
            }}
            control={control}
            render={({ field }) => (
              <div className="flex flex-col gap-2 w-full">
                <div className="flex flex-row gap-4">
                  <Select
                    className="w-full"
                    options={airlinesOptions}
                    placeholder="Choose the client"
                    onChange={(option: any) => field.onChange(option.value)}
                    defaultValue={airlinesOptions?.filter((option) => field.value === option.value)}
                    value={airlinesOptions?.filter((option) => field.value === option.value)}
                  />
                </div>
                {formState.errors.airline && (
                  <div className="text-danger-500 mt-1.5 text-sm">
                    {(formState.errors.airline as FieldError).message}
                  </div>
                )}
              </div>
            )}
          />
        </FormItem>
        <FormItem label="Group Title*" layout="vertical">
          <Input
            rounded
            className="grow"
            placeholder="Enter cycle month and year"
            error={formState.errors.cycle?.message}
            {...register('cycle', {
              required: { value: true, message: 'Group title is required!' }
            })}
          />
        </FormItem>
      </form>
      <div className="flex flex-row-reverse p-5 gap-2">
        <Button
          variant="primary"
          rounded
          type="submit"
          onClick={() => handleSubmit(onFormSubmit)()}
          disabled={!formState.isValid}>
          <PlusIcon className="w-5 h-5 stroke-white" /> Add
        </Button>
        <Button variant="defaultOutlined" rounded onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
}
