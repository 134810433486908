import { EMPTY_CELL_TEXT } from './utils';

export const formatDateTime = (value: string | null, defaultValue = EMPTY_CELL_TEXT): string => {
  if (!value) return defaultValue;

  try {
    const date = new Date(value);
    return date.toLocaleString();
  } catch (error) {
    return defaultValue;
  }
};

export const formatDate = (value: string | null, defaultValue = EMPTY_CELL_TEXT): string => {
  if (!value) return defaultValue;

  try {
    const date = new Date(value);
    return date.toLocaleDateString();
  } catch (error) {
    return defaultValue;
  }
};

// localizedDateTimeFormatHelpString() generates help text for date format.
// For example: dd/mm/yyyy, hh:mm:ss for people with a US locale
export const localizedDateTimeFormatHelpString = () => {
  const d = new Date('4444-11-22 00:55:33').toLocaleString();
  const m: Record<string, string> = { '4': 'y', '1': 'm', '2': 'd', '0': 'h', '5': 'm', '3': 's' };
  return d.replaceAll(/\d/g, (c) => m[c]);
};

export const formatDateTimeForPath = (dateTime?: string | undefined): string => {
  if (!dateTime) dateTime = new Date().toISOString();
  const d = new Date(dateTime);
  const date = d.toISOString().split('T')[0];
  const time = d.toTimeString().slice(0, 5).replace(/:/g, '');
  return `${date}-${time}`;
};
