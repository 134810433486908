import { OrderTitleDelivery } from '../features/orders/types';

export const ifeSystemSummaryLabel = (delivery?: OrderTitleDelivery): string => {
  if (!delivery) return '';
  if (!delivery?.ifeSystem) return delivery.poDestination || delivery.deliveryId;
  const system = ALL_SYSTEMS.find((s) => s.item_id === delivery.ifeSystem);
  const systemName = system?.item_text || delivery.ifeSystem || '';
  const format = system?.formats.find((f) => f.item_id === delivery?.ifeFormat);
  const formatName = format?.item_text || delivery?.ifeFormat || '';
  const destination = format?.po_destinations.find(
    (destination) => destination.destination_id === delivery.poDestination
  );
  return (
    (destination?.display_name || delivery.poDestination || '') +
    (systemName ? ` - ${systemName}` : '') +
    (formatName ? ` (${formatName})` : '')
  );
};

export interface IfeSystem {
  item_id: string;
  item_text: string;
  formats: IfeFormat[];
}

export interface IfeFormat {
  item_id: string;
  item_text: string;
  watermark: boolean;
  filename: string;
  metadata_source_type: string | null;
  wrap_up_workflow: string | null;
  require_metadata_reference: boolean;
  vtk_template: string;
  po_destinations: PoDestination[];
  audio_only?: boolean;
  image_required?: boolean;
}

export interface PoDestination {
  display_name: string;
  destination_id: string;
}

export const environment = {
  delta_dfp_destination: 'delta_dfp_aspera'
};

export const PO_DESTINATIONS = {
  aerq: {
    publishable: true
  },
  to_aerq: {
    publishable: true
  },
  panasonic: {
    publishable: true
  },
  manual_distribution: {
    publishable: false
  },
  safran: {
    publishable: true
  },
  lh_expedat_dl: {
    publishable: true
  },
  bluebox: {
    publishable: true
  },
  moment: {
    publishable: true
  },
  av_jet: {
    publishable: true
  },
  aerogroup: {
    publishable: true
  },
  aeroplay_entertainment: {
    publishable: true
  },
  century_carnival: {
    publishable: true
  },
  cinesend: {
    publishable: true
  },
  euro_communications: {
    publishable: true
  },
  france_vision_services: {
    publishable: true
  },
  images_in_motion: {
    publishable: true
  },
  'gee-anuvu': {
    publishable: true
  },
  inflight_dublin: {
    publishable: true
  },
  quivertree: {
    publishable: true
  },
  spafax: {
    publishable: true
  },
  stellar: {
    publishable: true
  },
  'stellar-s3': {
    publishable: true
  },
  tap: {
    publishable: true
  },
  turkish_airlines: {
    publishable: true
  },
  virgin_atlantic: {
    publishable: true
  },
  west_entertainment: {
    publishable: true
  },
  thales: {
    publishable: true
  },
  difes: {
    publishable: true
  },
  delta_dfp_aspera: {
    publishable: true
  },
  demo_aspera: {
    publishable: true
  },
  burrana: {
    publishable: true
  },
  dragonfly: {
    publishable: true
  },
  vod: {
    publishable: true
  }

  // 'delta_intelsat_aspera': {
  //   'publishable': true
  // },
};

const panasonic_m1: IfeFormat = {
  item_id: 'panasonic_m1',
  item_text: 'M1',
  watermark: true,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000m1.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'EX2-M1',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_m2: IfeFormat = {
  item_id: 'panasonic_m2',
  item_text: 'M2',
  watermark: true,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000m2.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'EX2-M2',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};
const panasonic_m4: IfeFormat = {
  item_id: 'panasonic_m4',
  item_text: 'M4',
  watermark: true,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000m4.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'EX2-M4',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_h7: IfeFormat = {
  item_id: 'panasonic_h7',
  item_text: 'H7 (experimental)',
  watermark: true,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000h7.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'panasonic-h7',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_h8: IfeFormat = {
  item_id: 'panasonic_h8',
  item_text: 'H8 (experimental)',
  watermark: true,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000h8.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'panasonic-h8',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_z4: IfeFormat = {
  item_id: 'panasonic_z4',
  item_text: 'Z4',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'EX2-Z4',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_audio_wav: IfeFormat = {
  audio_only: true,
  item_id: 'panasonic_audio_wav',
  item_text: 'AUDIO - WAV (Non-PRAM)',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.wav',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'PANASONIC-AUDIO-WAV',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_audio_wav_pram: IfeFormat = {
  audio_only: true,
  item_id: 'panasonic_audio_wav_pram',
  item_text: 'AUDIO - WAV (PRAM)',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.wav',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'PANASONIC-AUDIO-WAV-PRAM',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_audio_abs: IfeFormat = {
  audio_only: true,
  item_id: 'panasonic_audio_abs',
  item_text: 'AUDIO - ABS',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.abs',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'PANASONIC-AUDIO-ABS',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_audio_mp3: IfeFormat = {
  audio_only: true,
  item_id: 'panasonic_audio_mp3',
  item_text: 'AUDIO - MP3',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.mp3',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'PANASONIC-AUDIO-MP3',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const panasonic_audio_aac: IfeFormat = {
  audio_only: true,
  item_id: 'panasonic_audio_aac',
  item_text: 'AUDIO - AAC',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.aac',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'PANASONIC-AUDIO-AAC',
  po_destinations: [
    { display_name: 'Panasonic via SJ', destination_id: 'panasonic' },
    { display_name: 'TAP via SJ', destination_id: 'tap' }
  ]
};

const thales_mpeg1_ts: IfeFormat = {
  item_id: 'thales-sd-mpeg1-ts',
  item_text: 'MPEG-1, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-sd-mpeg1-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_mpeg2_ts: IfeFormat = {
  item_id: 'thales-sd-mpeg2-ts',
  item_text: 'MPEG-2, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-sd-mpeg2-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_sd_avc_ts: IfeFormat = {
  item_id: 'thales-sd-avc-ts',
  item_text: 'SD, MPEG-4, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-sd-avc-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_720p_constrained_avc_ts: IfeFormat = {
  item_id: 'thales-720p-constrained-avc-ts',
  item_text: '720p, constrained MPEG-4, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-720p-constrained-avc-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_720p_avc_ts: IfeFormat = {
  item_id: 'thales-720p-avc-ts',
  item_text: '720p, MPEG-4, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-720p-avc-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_1080p_avc_ts: IfeFormat = {
  item_id: 'thales-1080p-avc-ts',
  item_text: '1080p, MPEG-4, TS (experimental)',
  watermark: true,
  filename:
    '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}_15M4_FW23_lcaacdc.mpg',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-1080p-avc-ts',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

const thales_avant_up_avod_hevc_540p: IfeFormat = {
  item_id: 'thales-avant-up-avod-hevc-540p',
  item_text: '540p, HEVC, MPEG-DASH (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-avod-hevc-540p',
  po_destinations: [{ display_name: 'Manual distribution', destination_id: 'manual_distribution' }],
  image_required: true
};

const thales_avant_up_avod_hevc_720p: IfeFormat = {
  item_id: 'thales-avant-up-avod-hevc-720p',
  item_text: '720p, HEVC, MPEG-DASH (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-avod-hevc-720p',
  po_destinations: [{ display_name: 'Manual distribution', destination_id: 'manual_distribution' }],
  image_required: true
};

const thales_avant_up_avod_hevc_1080p: IfeFormat = {
  item_id: 'thales-avant-up-avod-hevc-1080p',
  item_text: '1080p, HEVC, MPEG-DASH (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-avod-hevc-1080p',
  po_destinations: [{ display_name: 'Manual distribution', destination_id: 'manual_distribution' }],
  image_required: true
};

// if (!environment.production) {
//   const publish_test = {
//     display_name: 'Test publish to scratch bucket',
//     destination_id: 'scratch',
//   };
//   thales_avant_up_avod_hevc_540p.po_destinations.push({ ...publish_test });
//   thales_avant_up_avod_hevc_720p.po_destinations.push({ ...publish_test });
//   thales_avant_up_avod_hevc_1080p.po_destinations.push({ ...publish_test });
//   PO_DESTINATIONS['scratch'] = { publishable: true };
// }

const thales_avant_up_hevc_480p: IfeFormat = {
  item_id: 'thales-avant-up-hevc-480p',
  item_text: 'SD, HEVC (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-hevc-480p',
  po_destinations: [
    { display_name: 'Manual distribution', destination_id: 'manual_distribution' },
    { display_name: 'Thales via SJ', destination_id: 'thales' }
  ],
  image_required: true
};

const thales_avant_up_hevc_720p: IfeFormat = {
  item_id: 'thales-avant-up-avod-hevc-720p',
  item_text: '720p, HEVC (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-hevc-720p',
  po_destinations: [
    { display_name: 'Manual distribution', destination_id: 'manual_distribution' },
    { display_name: 'Thales via SJ', destination_id: 'thales' }
  ],
  image_required: true
};

const thales_avant_up_hevc_1080p: IfeFormat = {
  item_id: 'thales-avant-up-hevc-1080p',
  item_text: '1080p, HEVC (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-hevc-1080p',
  po_destinations: [
    { display_name: 'Manual distribution', destination_id: 'manual_distribution' },
    { display_name: 'Thales via SJ', destination_id: 'thales' }
  ],
  image_required: true
};

const thales_avant_up_hevc_2160p: IfeFormat = {
  item_id: 'thales-avant-up-hevc-2160p',
  item_text: '4K, HEVC (experimental)',
  watermark: true,
  filename: '${dirname}',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'thales-avant-up-hevc-2160p',
  po_destinations: [
    { display_name: 'Manual distribution', destination_id: 'manual_distribution' },
    { display_name: 'Thales via SJ', destination_id: 'thales' }
  ],
  image_required: true
};

const thales_audio_mp3: IfeFormat = {
  audio_only: true,
  item_id: 'thales-audio-mp3',
  item_text: 'Audio only, MP3',
  watermark: false,
  filename: '${customer.toLowerCase()}00${now.getUTCFullYear().toString().substring(2)}00000z4.mp3',
  metadata_source_type: null,
  wrap_up_workflow: null,
  require_metadata_reference: false,
  vtk_template: 'THALES-AUDIO-MP3',
  po_destinations: [{ display_name: 'Thales via SJ', destination_id: 'thales' }]
};

export const THALES_SYSTEMS: IfeSystem[] = [
  {
    item_id: 'ife_thales_all',
    item_text: 'Thales all',
    formats: [
      thales_mpeg1_ts,
      thales_mpeg2_ts,
      thales_sd_avc_ts,
      thales_720p_constrained_avc_ts,
      thales_720p_avc_ts,
      thales_1080p_avc_ts,
      thales_audio_mp3
    ]
  },
  {
    item_id: 'ife_thales_avantup',
    item_text: 'Thales Avant Up (experimental)',
    formats: [
      thales_avant_up_hevc_480p,
      thales_avant_up_hevc_720p,
      thales_avant_up_hevc_1080p,
      thales_avant_up_hevc_2160p
    ]
  },
  {
    item_id: 'ife_thales_avantup_dash',
    item_text: 'Thales Avant Up MPEG-DASH (experimental)',
    formats: [
      thales_avant_up_avod_hevc_540p,
      thales_avant_up_avod_hevc_720p,
      thales_avant_up_avod_hevc_1080p
    ]
  }
];

export const DELTA_SYSTEMS: IfeSystem[] = [
  {
    item_id: 'ife_delta_all',
    item_text: 'Delta all',
    formats: [
      {
        item_id: 'delta_dfp_vod',
        item_text: 'DFP VoD',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-dfp-vod',
        po_destinations: [
          {
            display_name: 'Delta DFP via Aspera',
            destination_id: environment.delta_dfp_destination
          },
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_dfp_safety',
        item_text: 'DFP Safety',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-dfp-safety',
        po_destinations: [
          {
            display_name: 'Delta DFP via Aspera',
            destination_id: environment.delta_dfp_destination
          },
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_dfp_safety_a321neo',
        item_text: 'DFP Safety (A321 NEO)',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-dfp-safety-a321neo',
        po_destinations: [
          {
            display_name: 'Delta DFP via Aspera',
            destination_id: environment.delta_dfp_destination
          },
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_intelsat_vod',
        item_text: 'intelsat VoD',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-intelsat-vod',
        po_destinations: [
          // {display_name: 'Delta Intelsat via Aspera', destination_id: 'delta_intelsat_aspera'},
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_intelsat_safety',
        item_text: 'intelsat Safety',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-intelsat-safety',
        po_destinations: [
          // {display_name: 'Delta Intelsat via Aspera', destination_id: 'delta_intelsat_aspera'},
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_intelsat_safety_a321neo',
        item_text: 'intelsat Safety (A321 NEO)',
        watermark: true,
        filename: 'movie.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'delta-intelsat-safety-a321neo',
        po_destinations: [
          // {display_name: 'Delta Intelsat via Aspera', destination_id: 'delta_intelsat_aspera'},
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'delta_flytedge',
        item_text: 'Delta FlytEdge',
        watermark: false,
        filename: 'movie.mp4',
        metadata_source_type: 'flytedge',
        wrap_up_workflow: 'flytedge',
        require_metadata_reference: true,
        vtk_template: 'delta-flytedge',
        po_destinations: [
          {
            display_name: 'Delta DFP via Aspera',
            destination_id: environment.delta_dfp_destination
          },
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  }
];

export const ALL_SYSTEMS: IfeSystem[] = [
  {
    item_id: 'to_aerq',
    item_text: 'AERQ',
    formats: [
      {
        item_id: 'to_aerq_mezz',
        item_text: 'Mezzanine (prelim)',
        watermark: false,
        filename: '[aerq_project]/[title]',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'to-aerq-mezzanine',
        po_destinations: [{ display_name: 'Deliver to AERQ', destination_id: 'to_aerq' }]
      }
    ]
  },
  {
    item_id: 'ife_apex_all',
    item_text: 'Apex all',
    formats: [
      {
        item_id: 'apex_ife_hsd',
        item_text: 'IFE-HSD',
        watermark: true,
        filename: 'IFE-HSD.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'apex-ife-hsd',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'apex_ife_hhd10',
        item_text: 'IFE-HHD10',
        watermark: true,
        filename: 'IFE-HHD10.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'apex-ife-hhd10',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'apex_ife_uhd10',
        item_text: 'IFE-UHD10',
        watermark: true,
        filename: 'IFE-UHD10.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'apex-ife-uhd10',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  },
  {
    item_id: 'ife_panasonic_all',
    item_text: 'Panasonic all',
    formats: [
      panasonic_m1,
      panasonic_m2,
      panasonic_m4,
      panasonic_h7,
      panasonic_h8,
      panasonic_z4,
      panasonic_audio_wav,
      panasonic_audio_wav_pram,
      panasonic_audio_abs,
      panasonic_audio_mp3,
      panasonic_audio_aac
    ]
  },
  {
    item_id: 'ife_panasonic_audio_only',
    item_text: 'Panasonic audio only',
    formats: [
      panasonic_audio_wav,
      panasonic_audio_wav_pram,
      panasonic_audio_abs,
      panasonic_audio_mp3,
      panasonic_audio_aac
    ]
  },
  {
    item_id: 'ife_gogo',
    item_text: 'Gogo',
    formats: [
      {
        item_id: 'gogo_generic',
        item_text: 'Generic',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'GOGO',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  },
  {
    item_id: 'ife_lambda_jetpack',
    item_text: 'Lambda JetPack',
    formats: [
      {
        item_id: 'lambda_jetpack',
        item_text: 'Lambda JetPack',
        watermark: true,
        filename: 'XYZ_${dirname}_00${now.getUTCFullYear().toString().substring(2)}_EngCsp.m4v',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'LAMBDA-JETPACK',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  },
  {
    item_id: 'ife_zodiac_rave',
    item_text: 'Zodiac / Safran',
    formats: [
      {
        item_id: 'zodiac_rave_sd_v2',
        item_text: 'RAVE SD v2',
        watermark: true,
        filename:
          'XYZ_00${now.getUTCFullYear().toString().substring(2)}_${dirname}_EngSplEnS_Th.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'RAVE-SD-V2',
        po_destinations: [{ display_name: 'Safran via SJ', destination_id: 'safran' }]
      },
      {
        item_id: 'zodiac_rave_720p',
        item_text: 'RAVE HD 720p',
        watermark: true,
        filename:
          'XYZ_00${now.getUTCFullYear().toString().substring(2)}_${dirname}_EngSplEnS_Th.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'RAVE-720P',
        po_destinations: [{ display_name: 'Safran via SJ', destination_id: 'safran' }]
      },
      {
        item_id: 'zodiac_rave_1080p',
        item_text: 'RAVE HD 1080p',
        watermark: true,
        filename:
          'XYZ_00${now.getUTCFullYear().toString().substring(2)}_${dirname}_EngSplEnS_Th.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'RAVE-1080P',
        po_destinations: [{ display_name: 'Safran via SJ', destination_id: 'safran' }]
      },
      {
        item_id: 'zodiac_rave_sd_va',
        item_text: 'RAVE SD VA',
        watermark: true,
        filename:
          'XYZ_00${now.getUTCFullYear().toString().substring(2)}_${dirname}_EngSplEnS_Th.mpg',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'RAVE-SD-VA',
        po_destinations: [{ display_name: 'Safran via SJ', destination_id: 'safran' }]
      }
    ]
  },
  {
    item_id: 'ife_airfi',
    item_text: 'AirFi',
    formats: [
      {
        item_id: 'airfi_mp4',
        item_text: 'Non-adaptive',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'AIRFI-MP4',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'airfi_mp4_new',
        item_text: 'Non-adaptive, Updated Spec',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'AIRFI-MP4-NEW',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'airfi_drm',
        item_text: 'DRM profile',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'AIRFI-DRM',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  },
  {
    item_id: 'ife_bc_cmaf',
    item_text: 'BoardConnect-CMAF',
    formats: [
      {
        item_id: 'bc_cmaf_p',
        item_text: 'BoardConnect-CMAF',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmaf_p',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      },
      {
        item_id: 'bc_cmafhd_p',
        item_text: 'BoardConnect-CMAF_HD',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmafhd_p',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      },
      {
        item_id: 'bc_cmaf_u',
        item_text: 'BoardConnect-CMAF-Clear',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmaf_u',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      },
      {
        item_id: 'bc_cmafhd_u',
        item_text: 'BoardConnect-CMAF_HD-Clear',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmafhd_u',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      }
    ]
  },
  {
    item_id: 'ife_bc_cmaf_hd',
    item_text: 'BoardConnect-CMAF_HD',
    formats: [
      {
        item_id: 'bc_cmafhd_p',
        item_text: 'BoardConnect-CMAF_HD',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmafhd_p',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      },
      {
        item_id: 'bc_cmafhd_u',
        item_text: 'BoardConnect-CMAF_HD-Clear',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'bc_cmafhd_u',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      }
    ]
  },
  {
    item_id: 'ife_nicemedia',
    item_text: 'Nicemedia',
    formats: [
      {
        item_id: 'nice_media',
        item_text: 'NiceMedia',
        watermark: false,
        filename: '00000000-0000-0000-0000-000000000000_${customer.toUpperCase()}__${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        output_file: '${filename}',
        vtk_template: 'nice_media',
        po_destinations: [{ display_name: 'Expedat download', destination_id: 'lh_expedat_dl' }]
      }
    ]
  },
  {
    item_id: 'ife_bluebox',
    item_text: 'Bluebox',
    formats: [
      {
        item_id: 'bluebox_wow',
        item_text: 'Bluebox Wow',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'BlueboxWow',
        po_destinations: [{ display_name: 'Bluebox via SJ', destination_id: 'bluebox' }]
      },
      {
        item_id: 'bluebox_ai',
        item_text: 'Bluebox Ai',
        watermark: true,
        filename: '${dirname}.m4v',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'BlueboxAi',
        po_destinations: [{ display_name: 'Bluebox via SJ', destination_id: 'bluebox' }]
      }
    ]
  },
  {
    item_id: 'ife_moment',
    item_text: 'Moment',
    formats: [
      {
        item_id: 'moment_720p',
        item_text: 'Moment 720p',
        watermark: true,
        filename: '${dirname}.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'Moment_720p',
        po_destinations: [{ display_name: 'Moment via SJ', destination_id: 'moment' }]
      }
    ]
  },
  {
    item_id: 'ife_everhub',
    item_text: 'Everhub',
    formats: [
      {
        item_id: 'everhub_tv_int_movies_stream1',
        item_text: 'TV & International Movies Stream 1 (1M)',
        watermark: true,
        filename: 'lotm02{now.getUTCFullYear().toString().substring(2)}00000m4_1M.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'Everhub_tv_int_movies_stream1',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      },
      {
        item_id: 'everhub_tv_int_movies_stream2',
        item_text: 'TV & International Movies Stream 2 (512k)',
        watermark: true,
        filename: 'lotm02{now.getUTCFullYear().toString().substring(2)}00000m4_512K.mp4',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'Everhub_tv_int_movies_stream2',
        po_destinations: [
          { display_name: 'Manual distribution', destination_id: 'manual_distribution' }
        ]
      }
    ]
  },
  {
    item_id: 'above_mezzanine',
    item_text: 'Mezzanine Delivery',
    formats: [
      {
        item_id: 'above_mezzanine_default',
        item_text: 'Copy source files as is',
        watermark: false,
        filename: '',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'mezzanine_default',
        po_destinations: [
          { display_name: 'AV jet', destination_id: 'av_jet' },
          { display_name: 'Aerogroup', destination_id: 'aerogroup' },
          { display_name: 'Aeroplay Entertainment (S3)', destination_id: 'aeroplay_entertainment' },
          {
            display_name: 'Aeroplay Entertainment (SJ)',
            destination_id: 'aeroplay_entertainment_sj'
          },
          { display_name: 'Burrana (SJ)', destination_id: 'burrana' },
          { display_name: 'Century Carnival', destination_id: 'century_carnival' },
          { display_name: 'Cinesend', destination_id: 'cinesend' },
          { display_name: 'Euro Communications', destination_id: 'euro_communications' },
          { display_name: 'France Vision Services', destination_id: 'france_vision_services' },
          { display_name: 'GEE-ANUVU', destination_id: 'gee-anuvu' },
          { display_name: 'Images in Motion', destination_id: 'images_in_motion' },
          { display_name: 'Inflight Dublin', destination_id: 'inflight_dublin' },
          { display_name: 'Quivertree', destination_id: 'quivertree' },
          { display_name: 'Spafax', destination_id: 'spafax' },
          { display_name: 'Stellar (SmartJog)', destination_id: 'stellar' },
          { display_name: 'Stellar (S3)', destination_id: 'stellar-s3' },
          { display_name: 'TAP', destination_id: 'tap' },
          { display_name: 'Turkish Airlines', destination_id: 'turkish_airlines' },
          { display_name: 'Virgin Atlantic', destination_id: 'virgin_atlantic' },
          { display_name: 'West Entertainment', destination_id: 'west_entertainment' },
          { display_name: 'Digital IFE Services', destination_id: 'difes' }
        ]
      },
      {
        item_id: 'above_mezzanine_remux',
        item_text: 'Remux to .mov/.aif',
        watermark: false,
        filename: '${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'mezzanine_remux',
        po_destinations: [
          { display_name: 'AV jet', destination_id: 'av_jet' },
          { display_name: 'Aerogroup', destination_id: 'aerogroup' },
          { display_name: 'Aeroplay Entertainment (S3)', destination_id: 'aeroplay_entertainment' },
          {
            display_name: 'Aeroplay Entertainment (SJ)',
            destination_id: 'aeroplay_entertainment_sj'
          },
          { display_name: 'Century Carnival', destination_id: 'century_carnival' },
          { display_name: 'Cinesend', destination_id: 'cinesend' },
          { display_name: 'Euro Communications', destination_id: 'euro_communications' },
          { display_name: 'France Vision Services', destination_id: 'france_vision_services' },
          { display_name: 'GEE-ANUVU', destination_id: 'gee-anuvu' },
          { display_name: 'Images in Motion', destination_id: 'images_in_motion' },
          { display_name: 'Inflight Dublin', destination_id: 'inflight_dublin' },
          { display_name: 'Quivertree', destination_id: 'quivertree' },
          { display_name: 'Spafax', destination_id: 'spafax' },
          { display_name: 'Stellar', destination_id: 'stellar' },
          { display_name: 'TAP', destination_id: 'tap' },
          { display_name: 'Turkish Airlines', destination_id: 'turkish_airlines' },
          { display_name: 'Virgin Atlantic', destination_id: 'virgin_atlantic' },
          { display_name: 'West Entertainment', destination_id: 'west_entertainment' },
          { display_name: 'Digital IFE Services', destination_id: 'difes' }
        ]
      },
      {
        item_id: 'above_transperfect',
        item_text: 'TransPerfect Proxy',
        watermark: false,
        filename: 'OrderID_${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'transperfect',
        po_destinations: [{ display_name: 'TransPerfect (SFTP)', destination_id: 'transperfect' }]
      },
      {
        item_id: 'above_difes',
        item_text: 'Difes Proxy',
        watermark: false,
        filename: '${dirname}',
        metadata_source_type: null,
        wrap_up_workflow: null,
        require_metadata_reference: false,
        vtk_template: 'mezzanine_difes',
        po_destinations: [{ display_name: 'Difes', destination_id: 'difes' }]
      }
    ]
  }
]
  .concat(THALES_SYSTEMS)
  .concat(DELTA_SYSTEMS);
