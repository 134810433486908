import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Table from '../ui/Table';
import { OrganizationAirline } from '../../features/organizations/types';
import SearchIcon from '../../assets/icons/search.svg?react';
import DeleteIcon from '../../assets/icons/delete.svg?react';
import EditIcon from '../../assets/icons/edit.svg?react';
import Tag from '../ui/Tag';
import Button from '../ui/Button';
import { deleteAirline, setSelectedAirline } from '../../features/organizations/organizationsSlice';
import Alert from '../ui/Alert';
import { territories } from '../../common/territories';

export default function OrganizationAirlineList({
  airlines,
  setAirlineModal
}: {
  airlines: OrganizationAirline[];
  setAirlineModal: (value: boolean) => void;
}) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const handleDelete = (item: OrganizationAirline) => {
    dispatch(deleteAirline(item.iataCode));
  };

  const handleEdit = (item: OrganizationAirline) => {
    setAirlineModal(true);
    dispatch(setSelectedAirline(item));
  };

  const columns = [
    {
      key: 'name',
      title: 'Name',
      render: (item: OrganizationAirline) => (
        <h4 className="text-sm text-gray-900 font-medium">{item.name}</h4>
      )
    },
    {
      key: 'iataCode',
      title: 'IATA Code',
      render: (item: OrganizationAirline) => (
        <h4 className="text-sm text-gray-900 font-medium">{item.iataCode}</h4>
      )
    },
    {
      key: 'territory',
      title: 'Territory',
      render: (item: OrganizationAirline) => (
        <h4 className="text-sm text-gray-900 font-medium">
          {territories.find((c) => c.value === item.territory)?.label || ''}
        </h4>
      )
    },
    {
      key: 'encodeSystems',
      title: 'Encode Systems',
      render: (item: OrganizationAirline) => (
        <div className="flex flex-row gap-2">
          {item.encodeSystems.map((system) => (
            <Tag size="xs" key={`${item.iataCode}-${system.ifeFormat}`}>
              {system.ifeFormat}
            </Tag>
          ))}
        </div>
      )
    },
    {
      key: 'actions',
      title: '',
      render: (item: OrganizationAirline) => (
        <div className="flex flex-row">
          <Button link onClick={() => handleEdit(item)}>
            <EditIcon className="w-6 h-6 stroke-gray-500" />
          </Button>
          <Button link onClick={() => handleDelete(item)}>
            <DeleteIcon className="w-6 h-6 stroke-danger" />
          </Button>
        </div>
      )
    }
  ];

  if (!airlines || airlines.length === 0) {
    return <Alert variant="warning">No airlines were attached to this organization</Alert>;
  }

  return (
    <Table
      data={airlines || []}
      columns={columns}
      multipleSelection={false}
      rowId="_id"
      disabledRows={[]}
      pagination={{
        totalItems: airlines?.length || 0,
        itemsPerPage: 30,
        currentPage: page,
        onPageChange: (page) => setPage(page)
      }}
      noDataIcon={<SearchIcon className="w-8 h-8 stroke-gray-400" />}
    />
  );
}
