/* eslint-disable react/no-unescaped-entities */
import Header from '../../components/shared/Header';
export default function TermsAndConditionsPage() {
  return (
    <>
      <Header publicPage={true} />
      <div className="bg-white container mx-auto border-2 border-gray-200 rounded-xl overflow-hidden mb-8 p-36">
        <div className="flex flex-col gap-4 text-center">
          <p className="text-primary-700 font-semibold">Current as of 01 Oct 2023</p>
          <h2 className="text-gray-900 font-semibold text-4xl">Terms & Conditions</h2>
          <p className="text-gray text-lg text-left">
            The costumer should read these terms and conditions (the “Terms and Conditions”)
            carefully before using the Media. This is a legally binding contract. By expressing your
            agreement electronically, or by using the Media, you are accepting all the Terms and
            Conditions (the “Costumer”). If the Costumer does not agree to the Terms and Conditions,
            it must not continue the installation process, must not use the Media, and must delete
            or destroy all copies of the Media in its possession or under its control.
          </p>
        </div>
        <p className="text-gray text-lg py-4">
          These Terms and Conditions may be supplemented by particular conditions, which will set
          forth the particulars relating to the installation and use of the Media.
        </p>
        <p className="text-gray text-lg py-4 font-semibold">
          For the purposes of the Terms and Conditions, “Company” shall mean Above Aero Media
          Services, Lda., a Portuguese company, with registered office at Alameda dos Oceanos, 41-O,
          1st floor, office 17, Parque das Nações, 1990-352 Lisbon (Portugal), having tax
          identification number 515988642, registered with the Commercial Registry Office under the
          same number, with a share capital of € 5,000.
        </p>
        <div className="py-4 flex flex-col gap-4">
          <h3 className="text-gray-900 font-semibold text-2xl">1. Limitations of Liability</h3>
          <p className="text-gray text-lg">
            The Customer is advised that the Company prices are never proportionate to value of the
            Media and other client property entrusted to it. For the purposes of these Terms and
            Conditions, “Media” shall include film, DVD, tape, digital files, or any other media.
            Customer’s property is received, duplicated, and stored, etc. by the Company only at the
            Customer’s risk, and the Company does not accept any responsibility for any loss or
            damage to such material from any cause whatsoever. The Customer should, therefore,
            ensure all products delivered to the Company against all risks. In no event, including
            negligence or gross negligence of the Company, shall the Company be liable for the loss
            or damage of any material delivered to it by or for the Customer for any amount more
            than the replacement value of the blank Media which is of the same type involved.
          </p>
          <p className="text-gray text-lg">
            The Company will exercise reasonable care and will exert its best efforts to produce
            high quality work, but does not make any warranty nor does it assume any responsibility
            as to the character or quality of the material or service to be furnished, and the
            Company shall not be liable for loss of any kind whatsoever due to delays or failure in
            performance caused directly or indirectly by Acts of God, strikes, fire, failure of
            transportation agencies, public enemy, the elements, war insurrection, shortage of labor
            or material, Government regulation, damage or accident to machinery or equipment,
            electric power or other utility failures, injury or damage to, or loss of materials,
            delivered to the Company by the Customer, or any other cause, including caused by
            pandemics, such has COVID 19.
          </p>
          <p className="text-gray text-lg">
            If the Company’s performance of its obligations under these Terms and Conditions is
            prevented or delayed by any act or omission of the Customer, its agents, subcontractors,
            consultants, or employees, then, without prejudice to any other right or remedy it may
            have, the Company shall be allowed an extension of time to perform its obligations equal
            to the delay caused by the Customer.
          </p>
          <p className="text-gray text-lg">
            The Company will not be responsible under these Terms and Conditions (i) if the Media is
            not used in accordance with the documentation delivered or made available by the Company
            regarding thee Media; or (ii) if the defect or liability is caused by the Costumer or
            third-party software; or (iii) if the Media is used in conjunction with any third party
            software for which the Costumer lacks sufficient rights from the third party vendor for
            such use; or (iv) for any Costumer activities not permitted under these Terms and
            Conditions. THE COMPANY WILL NOT BE LIABLE FOR ANY CLAIMS OR DAMAGES ARISING FROM
            INHERENTLY DANGEROUS USE OF THE MEDIA.
          </p>
          <p className="text-gray text-lg">
            ANYTHING TO THE CONTRARY HEREIN NOTWITHSTANDING, EXCEPT FOR DAMAGES RESULTING FROM (a)
            GROSS NEGLIGENCE OR WILFUL MISCONDUCT, AND (b) DEATH OR PERSONAL INJURY ARISING FROM
            EITHER PARTY’S GROSS NEGLIGENCE OR ARISING FROM EITHER PARTY’S WILLFUL MISCONDUCT, UNDER
            NO CIRCUMSTANCES AND REGARDLESS OF THE NATURE OF ANY CLAIM WILL THE COMPANY OR THE
            COSTUMER BE LIABLE TO EACH OTHER OR ANY OTHER PERSON OR ENTITY FOR AN AMOUNT IN EXCESS
            OF THE FEES PAID BY THE COSTUMER TO THE COMPANY FOR THE MEDIA CAUSING THE DAMAGES OR BE
            LIABLE IN ANY AMOUNT FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR INDIRECT DAMAGES, LOSS
            OF GOOD WILL OR PROFITS, WORK STOPPAGE, DATA LOSS, COMPUTER FAILURE OR MALFUNCTION,
            ATTORNEYS’ FEES, COURT COSTS, INTEREST OR EXEMPLARY OR PUNITIVE DAMAGES.
          </p>
          <p className="text-gray text-lg">
            SUBJECT TO THE EXCLUSION OF DAMAGES STATED IN THE PREVIOUS PARAGRAPH AND WITH RESPECT TO
            THIRD PARTY SOFTWARE, UNDER NO CIRCUMSTANCES AND REGARDLESS OF THE NATURE OF ANY CLAIM
            SHALL THE COMPANY BE LIABLE FOR AN AMOUNT IN EXCESS OF THE PAID FEES FOR THE THIRD-PARTY
            SOFTWARE DIRECTLY CAUSING THE DAMAGES.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">2. Laboratory Warranty</h3>
          <p className="text-gray text-lg">
            Should products and/or services provided by the Company be found defective, or labeled
            or shipped in error, the Company will promptly replace or repair such defective products
            and/or services and/or correct an error in shipment at its expense, provided the
            defective product is returned and written notice of such imperfection and/or error in
            labeling or shipment is given the Company within twenty days after the shipment.
            However, in no event shall the Company be liable for any consequential damages. The
            Company’s liability to the Customer or any other person shall be limited to the
            replacement or repair of defective products and/or services and/or the correction of
            such errors in shipment or labeling. A dub check must be ordered by the Customer from
            any duplicate master made. The Customer shall view dubs check prior to making volume
            orders, to verify that the result meets expectations. The Company will replace duplicate
            submasters, etc., if work is found to be defective by way of such check dub.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">3. Limitations of Warranties</h3>
          <p className="text-gray text-lg">
            THE COMPANY DISCLAIM ALL OTHER REPRESENTATIONS, WARRANTIES, CONDITIONS OR GURANTEES WITH
            RESPECT TO THE MEDIA EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY IMPLIED
            WARRANTIES OF MERCHANTABILITY, QUALITY OR FITNESS FOR A PARTICULAR PURPOSE OR OTHERWISE
            WITH RESPECT TO THE VIDEO TRANSFER, ENCODING, TRANSCODING, EDITING, DUPLICATING AND
            OTHER SERVICES OR MATERIAL PROVIDED BY THE COMPANY EXCEPT TO THE EXTENT THAT ANY
            WARRANTIES IMPLIED BY LAW CANNOT BE VALIDLY WAIVED.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">4. Prices</h3>
          <p className="text-gray text-lg">
            All prices are subject to any fluctuation in cost of raw material and labor retroactive
            to effective date thereof and are subject to change without notice.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">5. Storage Charges</h3>
          <p className="text-gray text-lg">
            The Company will store the Customer supplied content and the Media on which it may be
            stored during the time of services are being provided by the Company and for a
            reasonable period thereafter, but in no event exceeding twelve months after last
            services were provided by the Company. The Company shall charge a monthly storage fee
            for all content and other materials left with the Company after the last services are
            provided by the Company for such content and materials. Within thirty days after written
            notice from the Company, the Customer agrees to remove at its own expense, all
            extraneous elements contained in said notice from the Company premises.
          </p>
          <p className="text-gray text-lg">
            In the event the Customer fails to remove all or any part of said elements, the Company
            shall have the right to destroy or otherwise dispose of such elements without liability
            to the Customer or any other person. The Customer shall indemnify and hold the Company
            harmless from all liability arising out of or in connection with the destruction or
            disposition of any such content or elements of same. The failure to pay said incidental
            charge for storage and keeping shall give the Company a lien on the Media for such
            charges and the right to sell the Media to satisfy said lien and costs of sale
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">6. Shipping, Handling Charges</h3>
          <p className="text-gray text-lg">
            All prices are F.O.B. at the offices of the Company and are subject to all applicable
            state and local taxes. The Customer shall pay all shipping costs, if and when
            applicable. In the absence of any insurance and other instructions from the Customer,
            the Company will make shipments via the carrier of its choice subject to that carrier's
            standard insurance rate unless the Customer specifically requests in writing additional
            insurance which in all cases is at the Customer's expense.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">7. Delivery of Media</h3>
          <p className="text-gray text-lg">
            Any shipping dates or delivery dates given to the Customer by the Company are
            approximate and for the Customer's rough guidance only. If any conditions arise which
            prevent compliance with delivery schedule, the Company shall not be liable to the
            Customer, or any other person or entity for any losses (consequential or otherwise),
            damage, liability, or delay in delivery nor for failure to give notice of delay, and
            such delays shall not constitute grounds for cancellation, counterclaim, or the defense
            of set off. If the Company delivers its services in the form of digital media directly
            to a storage device furnished to the Company by the Customer, the Company shall have no
            liability for the performance or failure of such device (and in no event shall the
            Company be required to re-create such materials), and the Customer agrees to play back
            such digital media from such storage device prior to leaving the Company's premises.
          </p>
          <p className="text-gray text-lg">
            The Customer shall be deemed to have verified the correct performance of the storage
            device upon delivery of the storage device to the Customer and the Customer’s exit from
            the Company’s premises. The Customer acknowledges that unless the Company has agreed to
            the same in writing and payment has been made for such services, that the Company shall
            not retain any copies via electronic storage or otherwise of any digital media produced
            for the Customer or delivered by the Customer to the Company.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">8. Customer Liability</h3>
          <p className="text-gray text-lg">
            The Company may refuse to provide any service without incurring any liability to the
            Customer, which Company in its sole discretion deems unlawful, pornographic, degrading
            or which it deems as tending to incite prejudice or passion. Customer shall indemnify
            and hold Company harmless from all liability arising out of or in connection with
            publication, processing, distribution, exhibition or content of Media, or other elements
            delivered to Company, including liability for libel, slander, invasion of privacy,
            defamation of character, violation of any patent, copyright, trademark, or any other
            proprietary right. Customer shall, upon written notice from Company at Customer's sole
            cost and expense, promptly defend any claim, demand, action or proceeding of whatsoever
            nature in any jurisdiction to which Company may be a party or which it may be threatened
            to be made a party which involves or is based all or in any part on any liability which
            Customer has agreed to indemnify and hold Company harmless under any provision of these
            conditions.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">9. Ownership of Media</h3>
          <p className="text-gray text-lg">
            The Customer warrants that it is either the sole owner of or has obtained the rights to
            use all Media and the material contained therein, all of which have been delivered to
            the Company by the Customer or for the Customer’s account for storage or services of any
            kind or for any other purpose. The Customer warrants that it now and always while any
            such element is in possession of the Company will be the sole owner and proprietor of
            all necessary rights in connection with any such element, including without limitation
            the copyright of such Media, the music, motion picture, television, literary and
            dramatic rights. The Customer warrants that the Media delivered to or in possession of
            the Company are not subject to any security interest, assignment, lien or encumbrance of
            any person or company. If the Customer is indebted to the Company or any Media of the
            Customer is in the custody or possession of the Company, the Customer agrees not to
            pledge, hypothecate, assign or in any other manner encumber any rights to said Media
            without the prior written consent of the Company.
          </p>
          <p className="text-gray text-lg">
            In addition to any other liens or remedies given to the Company under applicable law or
            elsewhere in these Terms and Conditions, the Company shall have a security interest in
            and a lien on all original material, Media, dupes, masters, files, dubs and other
            property in custody of the Company but also on any other Media or property of the
            Customer obtained either prior to or subsequent to the Company’s acquisition or
            possession of the property upon which such security interest or lien is being asserted.
            The Customer hereby agrees that the Company shall have all the rights of a secured
            creditor with respect to all Media and other property of the Customer in possession of
            the Company.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">
            10. Company's Use of Media and Client's Name
          </h3>
          <p className="text-gray text-lg">
            Unless the Company receives in writing a notice otherwise, the Customer hereby grants
            the Company the right to use: 1) the Customer's name, including trademarks, on a list of
            the Company's clients and press releases; and 2) excerpts of the Media and the material
            contained therein in connection with demo reels and other promotional materials shown to
            prospective clients or used on the Company’s website.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">11. Arbitration of Disputes</h3>
          <p className="text-gray text-lg">
            These Terms and Conditions and any dispute or claim (including non-contractual disputes
            or claims) arising out of or in connection with it or its subject matter or formation
            shall be governed by and construed in accordance with the Portuguese law. Each party
            irrevocably agrees that the courts of Lisbon (Portugal) shall have exclusive
            jurisdiction to settle any dispute or claim (including non-contractual disputes or
            claims) arising out of or in connection with these Terms and Conditions or its subject
            matter or formation. The Costumer must initiate a cause of action for any claim(s)
            arising out of or relating to these Terms and Conditions and its subject matter within
            one (1) year from the date when the Costumer knew, or should have known after reasonable
            investigation, of the facts giving rise to the claim(s).
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">12. Terms of Payment</h3>
          <p className="text-gray text-lg">
            The Customer agrees to pay the Company for all services performed and materials supplied
            to the Customer, within 30 days after such items are invoiced. The invoice will be
            issued with the delivery of the Media. The Customer shall pay a late fee of $50.00 per
            month for each month in which invoices remain outstanding. In addition, interest at 8
            percent per annum will be charged on all outstanding unpaid amounts. Production projects
            which are submitted to the Customer for approval shall be considered complete if the
            Customer does not respond within 30 days of receipt of said approval copy and any
            balance due shall become immediately payable at that time.
          </p>
          <p className="text-gray text-lg">
            The Customer agrees to pay all costs and expenses incurred by the Company in connection
            with the enforcement of any of the Company’s rights hereunder, including the Company’s
            right to the collection from the Customer of any sums due or to become due at any time
            from the Customer. The term “costs and expenses” shall include actual attorney’s fees
            which the Customer agrees shall be deemed to be fair and reasonable. Payment for
            invoices shall be the joint responsibility of the entity ordering the services and Media
            and the entity on whose behalf the same are ordered.
          </p>
          <p className="text-gray text-lg">
            Any claims which the Customer may have against the Company for adjustment or which in
            any way would affect any invoice must be presented to the Company in writing no later
            than 15 days from the date of the invoice in question. The Customer hereby irrevocably
            waives any claim for any such adjustment or change or modification in any such invoice
            in which such claim is not presented in writing to the Company within said 15-day
            period. If the foregoing is unenforceable and in connection with all other claims, in no
            event shall the Customer commence an action under these Terms and Conditions later than
            one year after the cause of action has accrued. The Company will suspend the delivery of
            the Media if the payments are outstanding.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">13. Attorneys' Fees</h3>
          <p className="text-gray text-lg">
            In the event any dispute arises under these Terms and Conditions, whether arbitrated or
            not, the prevailing party in whose favor final judgment is entered shall be entitled to
            recover from the losing party, all costs and expenses incurred or sustained by such
            prevailing party in connection with such suit or action, including without limitation,
            legal fees, and court costs (whether taxable as such).
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">14. Force Majeure</h3>
          <p className="text-gray text-lg">
            Any delay or nonperformance of any provision of these Terms and Conditions (other than
            for the payment of amounts due hereunder) caused by conditions beyond the reasonable
            control of the performing party shall not constitute a breach of these Terms and
            Conditions, and the time for performance of such provision, if any, shall be deemed to
            be extended for a period equal to the duration of the conditions preventing performance.
          </p>
          <h3 className="text-gray-900 font-semibold text-2xl">15. Assignment</h3>
          <p className="text-gray text-lg">
            The Costumer may not, without the Company's prior written consent, assign, novate,
            delegate, pledge, subcontract or otherwise transfer these Terms and Conditions, or any
            of its rights or obligations under these Terms and Conditions, to any party, whether
            voluntarily or by operation of law, including by way of sale of assets, merger, or
            consolidation.
          </p>
          <p className="text-gray text-lg">
            The Company may assign, novate, delegate, pledge, subcontract or otherwise transfer
            these Terms and Conditions or any of its rights or obligations thereunder (in whole or
            in part) to any member of the Company group. If it is mandatory according to statutory
            provisions of law to transfer these Terms and Conditions in case of a merger or
            acquisition, the Costumer shall immediately, and prior to such transfer, notify the
            Company in writing and in such case the Company shall have the right to terminate these
            Terms and Conditions and its annexes without any indemnification liability. The Company
            and any other member of the Company group may use third parties as sub-contractors for
            fulfilling any of its rights or obligations under these Terms and Conditions. The
            Company will continue to be liable for such obligations.
          </p>
        </div>
      </div>
    </>
  );
}
