export const cognito_groups = [
  { value: 'basic', label: 'basic' },
  { value: 'above_admin', label: 'above_admin' },
  { value: 'above_ops', label: 'above_ops' },
  { value: 'csp_user', label: 'csp_user' },
  { value: 'csp_admin', label: 'csp_admin' },
  { value: 'studio_admin', label: 'studio_admin' },
  { value: 'above', label: 'above' },
  { value: 'touch', label: 'touch' },
  { value: 'castlabs', label: 'castlabs' }
];
