import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ReactNode, ReactElement } from 'react';

import useUserGroups from '../hooks/useUserGroups';

interface RequireUserGroupsProviderProps {
  groups: string[];
  strict?: boolean;
  children: ReactNode;
}

export default function RequireUserGroupsProvider(
  props: RequireUserGroupsProviderProps
): ReactElement {
  const { children, groups, strict = true } = props;
  const { hasUserGroups } = useUserGroups();

  if (hasUserGroups(groups, strict)) return <>{children}</>;

  return (
    <div className="container mx-auto my-10 flex flex-col gap-4 justify-center items-center">
      <h5 className="font-semibold text-lg text-gray-900">
        You do not have permission to view this page.
      </h5>
      <p className="text-primary hover:underline">
        <RouterLink to="/">Go back to the dashboard</RouterLink>.
      </p>
    </div>
  );
}
