import { createSlice } from '@reduxjs/toolkit';
import { keyBy } from 'lodash';
import { Title, System } from './types';

export interface catalogOrderSliceType {
  titles: { [key: string]: Title & { systems: System[]; period?: string; deal_rate?: string } };
  systems: System[];
  bulkFill: boolean;
}

const initialState: catalogOrderSliceType = {
  titles: {},
  systems: [],
  bulkFill: false
};

export const catalogOrderSlice = createSlice({
  name: 'catalogOrderSlice',
  initialState,
  reducers: {
    setTitles: (state: catalogOrderSliceType, action) => {
      const titles = Object.keys(action.payload).map((key: string) => {
        const title = action.payload[key];
        title.systems = [];
        return title;
      });
      state.titles = keyBy(titles, '_id');
    },
    setSystems: (state, action: { type: string; payload: System[] }) => {
      state.systems = action.payload;
      const titles = Object.keys(state.titles).map((key: string) => {
        const title = state.titles[key];
        title.systems = action.payload || [];
        return title;
      });
      state.titles = keyBy(titles, '_id');
    },
    updateTitleSystem: (state, action) => {
      const title = state.titles[action.payload.title_id];
      title.systems = title.systems.map((system: System) => {
        if (system.value === action.payload.value) {
          return action.payload;
        }
        return system;
      });
    },
    updateTitle: (state, action) => {
      if (state.bulkFill) {
        const titles = Object.keys(state.titles).map((key: string) => {
          const { period, deal_rate } = action.payload;
          const title = { ...state.titles[key] };
          if (period) {
            title.period = period;
          }
          if (deal_rate) {
            title.deal_rate = deal_rate;
          }
          return title;
        });
        state.titles = keyBy(titles, '_id');
      } else {
        state.titles[action.payload._id] = {
          ...state.titles[action.payload._id],
          ...action.payload
        };
      }
    },
    updateBulkFill: (state, action) => {
      state.bulkFill = action.payload;
      if (Object.keys(state.titles).length > 0) {
        const firstTitleKey = Object.keys(state.titles)[0];
        const firstTitle = state.titles[firstTitleKey];
        const titles = Object.keys(state.titles).map((key: string) => {
          const { period, deal_rate } = firstTitle;
          const title = { ...state.titles[key] };
          if (period) {
            title.period = period;
          }
          if (deal_rate) {
            title.deal_rate = deal_rate;
          }
          return title;
        });
        state.titles = keyBy(titles, '_id');
      }
    },
    deleteTitle: (state, action) => {
      delete state.titles[action.payload];
    }
  }
});

export const {
  setTitles,
  setSystems,
  updateTitleSystem,
  updateTitle,
  updateBulkFill,
  deleteTitle
} = catalogOrderSlice.actions;

export default catalogOrderSlice;
