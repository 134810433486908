import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { EditVersion, Studio } from '../../common/types';
import { AddEidrEpisode, AddEidrSeason } from '../eidr/types';
import { AnalyzeFile, SourceCatalog, TitleCandidate } from './types';

interface IngestState {
  paths: string[];
  detectedFiles: AnalyzeFile[];
  titleCandidates: TitleCandidate[];
  selectedCandidate: TitleCandidate;
  studio: Studio | '';
  editVersion: EditVersion | '';
  preselectedMpmNumber: string;
  sourceCatalog: SourceCatalog | '';
  addEidrSeason: AddEidrSeason | '';
  addEidrEpisode: AddEidrEpisode | '';
  candidates: TitleCandidate[];
}

export const initialSelectedCandidate: TitleCandidate = {
  sourceCatalog: '',
  mpmNumber: '',
  eidrId: '',
  titleName: '',
  referentType: '',
  seasonNumber: '',
  episodeNumber: '',
  isEpisode: false,
  seriesAboveId: '',
  seriesFolderLocation: '',
  seriesEidrId: '',
  seriesName: '',
  seriesReleaseDate: '',
  studio: '',
  editVersion: ''
};

const initialState: IngestState = {
  paths: [],
  detectedFiles: [],
  titleCandidates: [],
  selectedCandidate: initialSelectedCandidate,
  studio: '',
  editVersion: '',
  preselectedMpmNumber: '',
  sourceCatalog: '',
  addEidrSeason: '',
  addEidrEpisode: '',
  candidates: []
};

export const ingestSlice = createSlice({
  name: 'ingestSlice',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.paths = [];
      state.selectedCandidate = initialSelectedCandidate;
      state.addEidrSeason = '';
      state.addEidrEpisode = '';
    },
    clearContentMetadata: (state) => {
      state.selectedCandidate = initialSelectedCandidate;
      state.addEidrSeason = '';
      state.addEidrEpisode = '';
    },
    setPaths: (state, action: PayloadAction<string[]>) => {
      state.paths = action.payload;
    },
    setDetectedFiles: (state, action: PayloadAction<AnalyzeFile[]>) => {
      state.detectedFiles = action.payload;
    },
    appendTitleCandidate: (state, action: PayloadAction<TitleCandidate>) => {
      if (action.payload.mpmNumber) {
        // first remove any existing candidates with the same MPM number
        state.titleCandidates = state.titleCandidates.filter(
          (c) => c.mpmNumber !== action.payload.mpmNumber
        );
      }
      state.titleCandidates.push(action.payload);
    },
    setTitleCandidates: (state, action: PayloadAction<TitleCandidate[]>) => {
      state.titleCandidates = action.payload;
    },
    setSelectedCandidate: (state, action: PayloadAction<TitleCandidate>) => {
      state.selectedCandidate = action.payload;
    },
    setStudio: (state, action: PayloadAction<Studio>) => {
      state.studio = action.payload;
    },
    setEditVersion: (state, action: PayloadAction<EditVersion>) => {
      state.editVersion = action.payload;
    },
    setPreselectedMpmNumber: (state, action: PayloadAction<string>) => {
      state.preselectedMpmNumber = action.payload;
    },
    setSourceCatalog: (state, action: PayloadAction<SourceCatalog>) => {
      state.sourceCatalog = action.payload;
    },
    setMissingSeason: (state, action: PayloadAction<AddEidrSeason>) => {
      state.addEidrSeason = action.payload;
    },
    setMissingEpisode: (state, action: PayloadAction<AddEidrEpisode>) => {
      state.addEidrEpisode = action.payload;
    },
    setCandidates: (state, action: PayloadAction<TitleCandidate[]>) => {
      state.candidates = action.payload;
    }
  }
});

export const {
  clearAll,
  clearContentMetadata,
  setPaths,
  setDetectedFiles,
  appendTitleCandidate,
  setTitleCandidates,
  setSelectedCandidate,
  setStudio,
  setEditVersion,
  setPreselectedMpmNumber,
  setSourceCatalog,
  setMissingSeason,
  setMissingEpisode,
  setCandidates
} = ingestSlice.actions;

export default ingestSlice;

export const selectIngest = (state: RootState) => state.ingest;
