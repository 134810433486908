export const brandOptions = [
  {
    label: 'HBO',
    value: 'HBO'
  },
  {
    label: 'HBO Max',
    value: 'HBO Max'
  },
  {
    label: 'Warner Media',
    value: 'Warner Media'
  },
  {
    label: 'Turner US',
    value: 'Turner US'
  }
];
