import { graphql } from 'gql.tada';

export const addUser = graphql(`
  mutation addUser(
    $name: String!
    $email: String!
    $enabled: Boolean!
    $organizations: [String]!
    $groups: [String]!
    $username: String!
  ) {
    createUser(
      user_input: {
        email: $email
        enabled: $enabled
        name: $name
        username: $username
        organizations: $organizations
        groups: $groups
      }
    )
  }
`);

export const updateUser = graphql(`
  mutation updateUser(
    $name: String!
    $email: String!
    $enabled: Boolean!
    $organizations: [String]!
    $groups: [String]!
    $username: String!
  ) {
    updateUser(
      user_input: {
        email: $email
        enabled: $enabled
        name: $name
        organizations: $organizations
        groups: $groups
      }
      username: $username
    )
  }
`);

export const deleteUser = graphql(`
  mutation deleteUser($username: String!) {
    deleteUser(username: $username)
  }
`);
