import { ReactNode } from 'react';

export default function Result({
  title,
  description,
  icon
}: {
  title?: string;
  description?: string;
  icon?: ReactNode;
}) {
  return (
    <div className="flex relative flex-col gap-2 justify-center items-center bg-table-empty-bg w-full h-80 bg-white bg-no-repeat bg-top border-t border-gray-100 rounded-xl">
      {icon && <div className="top-12 absolute">{icon}</div>}
      {title && <h3 className="text-lg font-semibold text-gray-900">{title}</h3>}
      {description && <p className="text-gray">{description}</p>}
    </div>
  );
}
